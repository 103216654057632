// import axios from "axios";
// import AuthService from "./../auth/authService";
import i18n from 'i18next';
import he from 'he';
import algoliasearch from 'algoliasearch'
import CompaniesServiceForEvents from "./../../../old/entities/companies/companiesService";
// import { I18n, Trans } from 'react-i18next';
import {DOMAINS} from "./../../../../domains"

var config;
// var language;
var client;
var index;
var filterStructure = {};
var isInitialized = false;


export default class EventsService{
   initialize(c, l){
      return new Promise((resolve, reject) => {
         config = c;
         // language = l;
         client = algoliasearch(DOMAINS[window.location.hostname].algolia.id, DOMAINS[window.location.hostname].algolia.key);
         if(config.algolia_index_events){
            isInitialized = true;
            index = client.initIndex(DOMAINS[window.location.hostname].algolia.index_events);
            filterStructure = config.filter_events;
            resolve(true);
         }else{
            resolve(false);
         }
      });
   }

   getFilterStructure(){
      if(isInitialized){
         return filterStructure;
      }else{
         return {};
      }
   }

   composeFilter(filter){
      let filterstring = "(Dates.Timestamp >= " + Math.round(new Date()/1000) + ")";

      for (var item in filter) {
         if(filterstring !== ""){
            filterstring += " AND ";
         }

         if(item === "Days"){
            for(let i = 0; i < filter[item].length; i++){
               if(i > 0 && filterstring !== ""){
                  filterstring += " AND ";
               }
               filterstring +=  "Days: " + filter[item][i];
            }
         }else{
            for(let i = 0; i < filter[item].length; i++){
               if(i > 0 && filterstring !== ""){
                  filterstring += " AND ";
               }
               filterstring += this.getField(item).replace("LANG", i18n.language)+": \""+filter[item][i]+"\"";
            }
         }
      }
      // console.log(filterstring);
      return filterstring;
   }

   getList(page, search, filter){
      if(isInitialized){
         var that = this;
         return new Promise((resolve, reject) => {
            let filterstring = this.composeFilter(filter);

            index.search(search,{
                  filters: filterstring,
                  hitsPerPage: "20",
                  page: page
               },
               function searchDone(err, content) {
                  if (err) throw err;
                  let elements = [];
                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);
                     elements.push(element);
                  }
                  let state = null;
                  if(content.page === content.nbPages-1){
                     state = 0; //no more results
                  }else if(content.nbPages === 0){
                     state = -1; //no results
                  }else{
                     state = 1; //loading
                  }
                  resolve({"dataResult": elements, "loadingState": state, "nCurrent": content.nbHits});
               }
            );
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   getListByCompany(id){
      if(isInitialized){
         var that = this;
         return new Promise((resolve, reject) => {

            let filterstring = "(Dates.Timestamp >= " + Math.round(new Date()/1000) + ")";
            index.search("", {
                  filters: filterstring + " AND CompanyId:"+id,
                  hitsPerPage: 9999,
                  page: 0
               },
               function searchDone(err, content) {
                  if (err) throw err;
                  let elements = [];
                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);
                     elements.push(element);
                  }
                  resolve(elements);
               }
            );
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }


   /*single*/
   getData(val, by = "id"){
      if(isInitialized){
         var that = this;
         return new Promise((resolve, reject) => {
            let field = "";
            switch(by){
               case "id":
                  field = "objectID";
                  break;
               case "slug":
                  field = "objectID";
                  break;
               default:
                  break;
            }

            index.search("", {
                  filters: field+":"+val,
                  hitsPerPage: 1,
                  page: 0
               },
               function searchDone(err, content) {
                  if (err) throw err;
   // console.log(content);
                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);

                     // let productsServ = new ProductsServiceForCompanies();
                     // productsServ.getListByCompany(content.hits[i].objectID).then(products => {
                     //    element.products = products;
                     //
                        let companiesServ = new CompaniesServiceForEvents();
                        companiesServ.getData(content.hits[i].CompanyId).then(company => {
                           element.company = company;

                           resolve(element);
                        }).catch(err => console.log('There was an error:' + err));
                     // }).catch(err => console.log('There was an error:' + err));

                     // resolve(element);
                  }
               }
            );
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   /*search*/
   getFacets(val, filters, query){
      if(isInitialized){
         val = val.replace("LANG", i18n.language);
         return new Promise((resolve, reject) => {
            let filterstring = this.composeFilter(filters);

            if(val === "Days"){
               index.searchForFacetValues("", {
                     facetName: val,
                     filters: filterstring,
                     maxFacetHits: 100
                  },
                  function searchDone(err, content) {
                     if (err) throw err;
                     let arrFacets = content.facetHits;
                     arrFacets.sort(function(a, b) {
                        if(a.value < b.value) return -1;
                        if(a.value > b.value) return 1;
                        return 0;
                     });
                     resolve(arrFacets);
                  }
               );
            }else{
               index.searchForFacetValues("", {
                     facetName: val,
                     filters: filterstring,
                     maxFacetHits: 100
                  },
                  function searchDone(err, content) {
                     if (err) throw err;
                     let arrFacets = content.facetHits;
                     arrFacets.sort(function(a, b) {
                        if(a.value < b.value) return -1;
                        if(a.value > b.value) return 1;
                        return 0;
                     });
                     resolve(arrFacets);
                  }
               );
            }
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   /*filter*/
   getField(key){
      var ret = null;
      Object.keys(filterStructure).forEach(filter => {
         if(filterStructure[filter].hasOwnProperty(key)){
            ret = filterStructure[filter][key]["field"];
            return;
         }
      })
      return ret;
   }


   formatData(hit){
      let element = {}
      element.highlighted = {};

      /*slug*/
      element.slug = hit.objectID;

      /*type*/
      element.type = "event";

      /*name & pagetitle*/
      if(hit.Title !== undefined &&
         hit.Title[i18n.language] !== undefined &&
         hit.Title[i18n.language] !== null
      ){
         element.name = he.decode(hit.Title[i18n.language]);
         element.pagetitle = element.name;
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.Title !== undefined &&
         hit._highlightResult.Title.value !== undefined &&
         hit._highlightResult.Title[i18n.language].value !== undefined &&
         hit._highlightResult.Title[i18n.language].value !== null
      ){
         element.highlighted.name = he.decode(hit._highlightResult.Title[i18n.language].value);
      }else {
         element.highlighted.name = element.name;
      }

      /*description*/
      if(hit.Description !== undefined &&
         hit.Description[i18n.language] !== undefined &&
         hit.Description[i18n.language] !== null
      ){
         element.description = he.decode(hit.Description[i18n.language]);
      }

      /*typology*/
      if(hit.Typology !== undefined &&
         hit.Typology.Name !== undefined &&
         hit.Typology.Name[i18n.language] !== undefined &&
         hit.Typology.Name[i18n.language] !== null
      ){
         element.typology = hit.Typology.Name[i18n.language];
         element.typologyIcon = hit.UrlCDN + "/icons/" +  hit.Typology.Icon;
      }

      /*access*/
      if(hit.PartecipationMode !== undefined &&
         hit.PartecipationMode.Name !== undefined &&
         hit.PartecipationMode.Name[i18n.language] !== undefined &&
         hit.PartecipationMode.Name[i18n.language] !== null
      ){
         element.access = hit.PartecipationMode.Name[i18n.language];
         element.accessIcon = hit.UrlCDN + "/icons/" +  hit.PartecipationMode.Icon;
      }

      /*country*/
      if(hit.LocationOutVinitaly !== undefined &&
         hit.LocationOutVinitaly.Country !== undefined &&
         hit.LocationOutVinitaly.Country !== null &&
         hit.LocationOutVinitaly.Country[i18n.language] !== undefined &&
         hit.LocationOutVinitaly.Country[i18n.language] !== null
      ){
         element.country = he.decode(hit.LocationOutVinitaly.Country[i18n.language]);
         if(hit.LocationOutVinitaly.CountryId !== undefined &&
            hit.LocationOutVinitaly.CountryId !== null
         ){
            element.countryImage = process.env.PUBLIC_URL + "/assets/maps/countries/" + hit.LocationOutVinitaly.CountryId + ".svg";
         }
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.LocationOutVinitaly !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Country.value !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Country.value !== null &&
         hit._highlightResult.LocationOutVinitaly.Country[i18n.language].value !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Country[i18n.language].value !== null
      ){
         element.highlighted.country = he.decode(hit._highlightResult.LocationOutVinitaly.Country[i18n.language].value);
      }else {
         element.highlighted.country = element.country;
      }

      /*region*/
      if(hit.LocationOutVinitaly !== undefined &&
         hit.LocationOutVinitaly.Region !== undefined &&
         hit.LocationOutVinitaly.Region !== null &&
         hit.LocationOutVinitaly.Region[i18n.language] !== undefined &&
         hit.LocationOutVinitaly.Region[i18n.language] !== null
      ){
         element.region = he.decode(hit.LocationOutVinitaly.Region[i18n.language]);
         if(hit.LocationOutVinitaly.RegionId !== undefined &&
            hit.LocationOutVinitaly.RegionId !== null
         ){
            element.regionImage = process.env.PUBLIC_URL + "/assets/maps/countries/" + hit.LocationOutVinitaly.RegionId + ".svg";
         }
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.LocationOutVinitaly !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Region.value !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Region.value !== null &&
         hit._highlightResult.LocationOutVinitaly.Region[i18n.language].value !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Region[i18n.language].value !== null
      ){
         element.highlighted.region = he.decode(hit._highlightResult.LocationOutVinitaly.Region[i18n.language].value);
      }else {
         element.highlighted.region = element.region;
      }

      /*province*/
      if(hit.LocationOutVinitaly !== undefined &&
         hit.LocationOutVinitaly.Province !== undefined &&
            hit.LocationOutVinitaly.Province !== null &&
         hit.LocationOutVinitaly.Province[i18n.language] !== undefined &&
         hit.LocationOutVinitaly.Province[i18n.language] !== null
      ){
         element.province = he.decode(hit.LocationOutVinitaly.Province[i18n.language]);
         if(hit.LocationOutVinitaly.ProvinceId !== undefined &&
            hit.LocationOutVinitaly.ProvinceId !== null
         ){
            element.regionImage = process.env.PUBLIC_URL + "/assets/maps/countries/" + hit.LocationOutVinitaly.ProvinceId + ".svg";
         }
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.LocationOutVinitaly !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Province.value !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Province.value !== null &&
         hit._highlightResult.LocationOutVinitaly.Province[i18n.language].value !== undefined &&
         hit._highlightResult.LocationOutVinitaly.Province[i18n.language].value !== null
      ){
         element.highlighted.province = he.decode(hit._highlightResult.LocationOutVinitaly.Province[i18n.language].value);
      }else {
         element.highlighted.province = element.region;
      }

      /*city*/
      if(hit.LocationOutVinitaly !== undefined &&
         hit.LocationOutVinitaly.City !== undefined &&
         hit.LocationOutVinitaly.City !== null
      ){
         element.city = he.decode(hit.LocationOutVinitaly.City);
      }

      /*address*/
      if(hit.LocationOutVinitaly !== undefined &&
         hit.LocationOutVinitaly.Address !== undefined &&
         hit.LocationOutVinitaly.Address !== null
      ){
         element.address = he.decode(hit.LocationOutVinitaly.Address);
      }

      /*zip*/
      if(hit.LocationOutVinitaly !== undefined &&
         hit.LocationOutVinitaly.ZipCode !== undefined &&
         hit.LocationOutVinitaly.ZipCode !== null
      ){
         element.zip = he.decode(hit.LocationOutVinitaly.ZipCode);
      }

      /*dates*/
      element.firstDay = null;
      element.dates = [];
      if(hit.Dates !== undefined &&
         hit.Dates !== null &&
         hit.Dates.length > 0
      ){
         // console.log(hit.Dates);
         hit.Dates.forEach(function(objDate) {
            // if(objDate.Timestamp >= Math.round(new Date()/1000)){
               let date = {};

               let string = new Date(objDate.Timestamp * 1000);
               date.day = string.getDate();
               date.fullMonth = i18n.t('fullmonths.'+string.getMonth());
               date.shortMonth = date.fullMonth.substring(0, 3);
               date.year = string.getFullYear();
               date.string = date.day + " " + date.fullMonth + " " + date.year;

               date.timestamp = objDate.Timestamp;
               date.start = objDate.StartTime;
               date.end = objDate.EndTime;


               element.dates.push(date);

               if(element.firstDay === null || element.firstDay.timestamp > date.timestamp){
                  element.firstDay = date;
               }
            // }
         });
         element.dates.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));

      }

      //imageThumb

      // /*cover*/
      // if(hit.Gallery !== undefined &&
      //    hit.Gallery.CoverId !== undefined &&
      //    hit.Gallery.CoverId !== null
      // ){
      //    element.cover = hit.UrlCDN + "/cover/" +  hit.Gallery.CoverId;
      //
      // }

      return element;
   }

}
