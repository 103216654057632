import React, { Component } from 'react';
import Flowershelf from "./../../../old/utils/flowershelf/flowershelf";
import FsLightbox  from 'fslightbox-react';

import { LAYOUT_MOBILE } from "./../../../../settings";
import './sheetGallery.sass';

export default class SheetGallery extends Component {
   constructor(props){
      super(props);
      this.state = {
         title: props.title,
         images: props.images,
         translations: props.translations,
         language: props.language,
         layout: props.layout,
         openLightbox: false,
         indexLightbox: 0
      };

      this.arrFlowershelf = [];
      this.arrLightbox = [];
      this.arrTypes = [];
      this.props.images.forEach((item, index) => {
         let thumb = item.image;
         let zoom = item.image;
         if(item.embed){
            thumb = "https://img.youtube.com/vi/"+item.embed.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/)[1]+"/hqdefault.jpg"; //thumb = "https://img.youtube.com/vi/"+item.embed.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)[1]+"/hqdefault.jpg";
            zoom = item.embed;
            this.arrTypes.push("youtube");
         }else{
            this.arrTypes.push("image");
         }

         this.arrLightbox.push(zoom);

         let perRow = 3;

         if(this.state.layout !== LAYOUT_MOBILE){
            if(!(index === this.props.images.length - 1 && index%perRow === 0) && !this.arrFlowershelf[Math.floor(index/perRow)]){
               this.arrFlowershelf[Math.floor(index/perRow)] = [];
            }
         }
         let obj = {};
         obj.width = 100;
         obj.height = 100;
         obj.content = <div className="gallery-element" onClick={() => this.openLightbox(index)} ><img className="gallery-image" src={thumb} alt={item.description} /><div className="gallery-description">{item.description}</div></div>;

         if(this.state.layout === LAYOUT_MOBILE){
            this.arrFlowershelf[index] = [];
            this.arrFlowershelf[index].push(obj);
         }else{
            if(index === this.props.images.length - 1 && index%perRow === 0){
               this.arrFlowershelf[Math.floor(index/perRow) - 1].push(obj);
            }else{
               this.arrFlowershelf[Math.floor(index/perRow)].push(obj);
            }
         }
      });

   }

   // componentDidMount(){
   //
   //
   // }
   //
   // componentWillReceiveProps(nextProps) {
   //       // console.log(nextProps);
   // //    if(nextProps.data != this.state.element && nextProps.data != null){
   // //
   // //
   // //       this.setState({
   // //          data: nextProps.data
   // //       });
   // //    }
   // }


   openLightbox(i){
      this.setState({
         openLightbox: !this.state.openLightbox,
         indexLightbox: i+1
      });
   }

   render() {
      return (
         <section id="sheetGallery">
            <div className="section-title">
               <div className="bounded">
                  {this.state.title}
               </div>
            </div>
            <div className="section-content">
               <div className="bounded">
                  <Flowershelf
                     elements={this.arrFlowershelf}
                     fullRows={true}
                     gutter={0}
                  />
                  <FsLightbox
                     toggler={this.state.openLightbox}
                     sources={this.arrLightbox}
                     types={this.arrTypes}
                     slide={this.state.indexLightbox}
                  />
               </div>
            </div>
         </section>
      );
   }
}
