import React, { useCallback } from 'react';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useTheme } from './../../themes';

import { setStatus } from '../../store/actions/settings'

import Icon from "./../App/Icon";


const Wrapper  = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const MoreButton = ({
  size,
  color,
  background
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const onClick = useCallback(() => {
    dispatch(setStatus({
      isMoreOpen: true
    }), [dispatch])
  }, [dispatch]);

  return (
    <Wrapper onClick={onClick}>
      <Icon
        name={theme.icons.more}
        size={size}
        color={color}
        background={background}
      />
    </Wrapper>
  )
}

MoreButton.propTypes = {
  size: PropTypes.object,
  color: PropTypes.string,
  background: PropTypes.string
}

export default React.memo(MoreButton)
