import { createSelector } from 'reselect'

export const getUser = createSelector(
  (state) => state.auth.user,
  user => user
)

export const getFavorites = createSelector(
  (state) => state.auth.favorites,
  favorites => favorites
)
