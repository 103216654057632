import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { ConnectedRouter } from 'connected-react-router'
// import { initializeLocales } from "./locales"
import Store from './store/store'
// import AppRouter from './navigation'
import { ThemeProvider, themes } from './themes';

import Catalogue from './components/Layout/Catalogue'

import {DOMAINS} from "./domains"


// initializeLocales()
import './App.css';


function App() {
  console.log("%cWelcome to %c"+window.location.hostname, 'font-size: 18px; color: black', 'font-size: 18px; color: teal');
  console.log("%c This browser feature is for developers only. \n Please do not copy-paste any code or run any scripts here: it may cause your experience to be compromised. ", 'font-size: 16px; color: white; background: maroon;');


  return (
    <Provider store={Store.store}>
      {/*<ConnectedRouter history={Store.history}>*/}
        <PersistGate loading={null} persistor={Store.persistor}>
          {/*<AppRouter />*/}
          <ThemeProvider theme={themes[DOMAINS[window.location.hostname].tenant]}>
            <Catalogue/>
          </ThemeProvider>
        </PersistGate>
      {/*</ConnectedRouter>*/}
    </Provider>
  )
}

export default App
