import { all, fork } from 'redux-saga/effects'

import AuthSaga from './sagas/AuthSaga'
import SettingsSaga from './sagas/SettingsSaga'

export default function* root() {
  yield all([
    fork(AuthSaga),
    fork(SettingsSaga),
  ])
}
