import React from 'react'
import { useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import { getLayout } from './../../store/selectors/settings'

import { LAYOUT_XS, LAYOUT_SM } from './../../settings'

import Logo from './../../components/App/Logo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row" };
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding: 30px;
`;
const Box = styled.div`
  margin-top: 20px;
  background-color: #fff;
  padding: 30px 40px;
  font-size: 15px;
  border-bottom: 10px solid #1f282d;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : "40%" };
`;
const BoxLink = styled.a`
  display: block;
  margin-top: 20px;
  background-color: #fff;
  padding: 30px 40px;
  font-size: 15px;
  border-bottom: 10px solid #1f282d;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : "40%" };
`;
//
// const Text1 = styled.div`
//   font-size: 20px;
//   margin: 5px 0 10px 0;
//   font-weight: 600;
//   line-height: 1.1;
//   color: #6f6f6f;
// `;
// const Text2 = styled.div`
//   font-weight: 400;
//   color: #6f6f6f;
// `;


function FicHomepage() {
  const layout = useSelector(getLayout)
  // const theme = useTheme()
  // let { lang } = useParams()

  return (
    <Wrapper layout={layout}>
      <Box layout={layout}>
        <br/><br/><br/>
        <center>
          <Logo
            variant="default"
            width={{value: "340", unit: "px"}}
          />
        </center>
        <br/><br/><br/>
      </Box>
      <BoxLink layout={layout} href={"http://catalogo.oilnonoil.it"} target="_blank">
        <br/><br/><br/>
        <center>
          <Logo
            variant="other-exhib"
            width={{value: "340", unit: "px"}}
          />
        </center>
        <br/><br/><br/>
      </BoxLink>
      {/*
      <Box>
        {lang == "it" ?
          <>
            <Text1>
              CATALOGO ESPOSITORI | ASPHALTICA 2021
            </Text1>
            <Text2>
              Il Catalogo Espositori fornisce informazioni dettagliate sulle aziende presenti alla Manifestazione.
            </Text2>
          </>
        :
          <>
            <Text1>
              EXHIBITORS CATALOGUE | ASPHALTICA 2021
            </Text1>
            <Text2>
              The Exhibitors Catalogue shows detailed information about companies participating at the Exhibition.
            </Text2>
          </>
        }
      </Box>
      */}
    </Wrapper>
  )
}

export default React.memo(FicHomepage)
