import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './sheetRelations.sass';

export default class SheetRelations extends Component {
   constructor(props){
      super(props);

      let activeTab = "";
      if(props.relations.hasOwnProperty("coexhibs")){
         activeTab = "Coexhibs";
      }else if(props.relations.hasOwnProperty("represents")){
         activeTab = "Represents";
      }else if(props.relations.hasOwnProperty("coexhibsChild")){
         activeTab = "CoexhibsChild";
      }else if(props.relations.hasOwnProperty("representsChild")){
         activeTab = "RepresentsChild";
      }else{
         activeTab = "";
      }

      this.state = {
         relations: props.relations,
         translations: props.translations,
         language: props.language,
         activeTab: activeTab
      };
   }

   changeTab(evt, index){
      this.setState({
         activeTab: index
      });
   }

   render() {
      return (
         <section id="sheetRelations">
            <div className="section-content">
               <div className="bounded">
                  <div className="menu">
                     {( this.state.relations.hasOwnProperty("coexhibs") ?
                        <div className={(this.state.activeTab === "Coexhibs" ? "item active" : "item")} onClick={evt =>this.changeTab(evt, "Coexhibs")}>{this.state.translations["sheet-company"]["_label-coexhibitors"]}</div>
                     : "" )}
                     {( this.state.relations.hasOwnProperty("represents") ?
                        <div className={(this.state.activeTab === "Represents" ? "item active" : "item")} onClick={evt =>this.changeTab(evt, "Represents")}>{this.state.translations["sheet-company"]["_label-representing"]}</div>
                     : "" )}
                     {( this.state.relations.hasOwnProperty("coexhibsChild") ?
                        <div className={(this.state.activeTab === "CoexhibsChild" ? "item active" : "item")} onClick={evt =>this.changeTab(evt, "CoexhibsChild")}>{this.state.translations["sheet-company"]["_label-coexhibitorof"]}</div>
                     : "" )}
                     {( this.state.relations.hasOwnProperty("representsChild") ?
                        <div className={(this.state.activeTab === "RepresentsChild" ? "item active" : "item")} onClick={evt =>this.changeTab(evt, "RepresentsChild")}>{this.state.translations["sheet-company"]["_label-representedby"]}</div>
                     : "" )}
                  </div>

                  {this.state.activeTab === "Coexhibs" && this.state.relations.hasOwnProperty("coexhibs") ?
                     <div className="relation">
                        {this.state.relations.coexhibs.map((item, index) =>(
                           <span key={index}>
                              {(item.slug ?
                                 <Link to={"/" + this.state.language + "/companies/" + item.slug}>{item.name}</Link>
                              :
                                 item.name
                              )}
                           </span>
                        ))}
                     </div>
                  : "" }

                  {this.state.activeTab === "Represents" && this.state.relations.hasOwnProperty("represents") ?
                     <div className="relation">
                        {this.state.relations.represents.map((item, index) =>(
                           <span key={index}>
                              {(item.slug ?
                                 <Link to={"/" + this.state.language + "/companies/"+item.slug}>{item.name}</Link>
                              :
                                 item.name
                              )}
                           </span>
                        ))}
                     </div>
                  : "" }

                  {this.state.activeTab === "CoexhibsChild" && this.state.relations.hasOwnProperty("coexhibsChild") ?
                     <div className="relation">
                        {this.state.relations.coexhibsChild.map((item, index) =>(
                           <span key={index}>
                              {(item.slug ?
                                 <Link to={"/" + this.state.language + "/companies/"+item.slug}>{item.name}</Link>
                              :
                                 item.name
                              )}
                           </span>
                        ))}
                     </div>
                  : "" }

                  {this.state.activeTab === "RepresentsChild" && this.state.relations.hasOwnProperty("representsChild") ?
                     <div className="relation">
                        {this.state.relations.representsChild.map((item, index) =>(
                           <span key={index}>
                              {(item.slug ?
                                 <Link to={"/" + this.state.language + "/companies/"+item.slug}>{item.name}</Link>
                              :
                                 item.name
                              )}
                           </span>
                        ))}
                     </div>
                  : "" }

               </div>
            </div>
         </section>
      );
   }
}
