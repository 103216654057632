import { createTheming, type ThemingType } from '@callstack/react-theme-provider';

import {SAM} from "./tenants/SAM/theme";
import {FIC} from "./tenants/FIC/theme";
import {OVR} from "./tenants/OVR/theme";
import {ASF} from "./tenants/ASF/theme";

export type Theme = {
  primaryColor: string,
  homeBackground: string,
  screenBackground: string,
  interfaceBackground: string
};

export const themes: { [key: string]: Theme } = {
  SAM,
  FIC,
  OVR,
  ASF
};

const { ThemeProvider, useTheme, withTheme }: ThemingType<Theme> = createTheming(
  themes.default
);

export { ThemeProvider, useTheme, withTheme };
