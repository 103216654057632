import { createRoutine } from 'redux-saga-routines'

export const AUTH_LOGIN = "Auth.Login"
export const AUTH_CHECK = "Auth.Check"
export const AUTH_LOGOUT = "Auth.Logout"
export const AUTH_SETFAVORITE = "Auth.SetFavorite"
export const AUTH_GETFAVORITES = "Auth.GetFavorites"

export const login = createRoutine(AUTH_LOGIN)
export const check = createRoutine(AUTH_CHECK)
export const logout = createRoutine(AUTH_LOGOUT)
export const setFavorite = createRoutine(AUTH_SETFAVORITE)
export const getFavorites = createRoutine(AUTH_GETFAVORITES)
