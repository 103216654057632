import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import TranslationService from "./../../utils/translationService";
import CompaniesService from "./../../../old/entities/companies/companiesService";
import ProductsService from "./../../../old/entities/products/productsService";
import EventsService from "./../../../old/entities/events/eventsService";

import VeronafiereClassicCompanySheet from "./../../../old/entities/companies/sub/veronafiere-classic/companySheet";
// import FieraromaNseCompanySheet from "./../../../old/entities/companies/sub/fieraroma-nse/companySheet";
// import DefaultCompanySheet from "./../../old/entities/companies/sub/default/companySheet";

import WineSheet from "./../../../old/entities/products/sub/wine/wineSheet";
import EventSheet from "./../../../old/entities/events/sub/event/eventSheet";


import './page.css';
import './page.sass';

export default class Page extends Component {
   constructor(props){
      super(props);
      this.areaService = null;
      this.lastScroll = 0;

      this.state = {
         config: props.config,
         translations: null,
         language: props.language,
         layout: props.layout,

         slug: props.slug,
         area: props.area,
         data: null,
         showContactform: false
      };
      this.refHeader = React.createRef();
      this.selectService(props.area);
      this.translationService = new TranslationService(this.state.config);


   }

   componentDidMount(){
      this._isMounted = true;
      this.areaService.initialize(this.state.config, this.state.language).then(ret  => {
         if(ret === true && this._isMounted) {
            this.getSheet();
         }
      }).catch(err => console.log('There was an error:' + err));

       this.translationService.getAllTranslations().then(ret  => {
          this.setState({
             translations: ret[this.state.language]
          });
       }).catch(err => console.log('There was an error:' + err));
   }


   componentDidUpdate(prevProps, prevState){
      disableBodyScroll(document.querySelector("#sheet .content"));
      // if(this.props.area !== this.state.area){
      //    sessionStorage.setItem("filter", "{}");
      // }
      if(this.props.area !== this.state.area || this.props.slug !== this.state.slug){
         this.setState({
            area: this.props.area,
            slug: this.props.slug,
            data: null
         }, ()=>{
            this.areaService.initialize(this.state.config, this.state.language).then(ret  => {
               if(ret === true && this._isMounted) {
                  this.getSheet();
               }
            }).catch(err => console.log('There was an error:' + err));
         });
      }
   }

   componentWillUnmount() {
      this._isMounted = false;
      clearAllBodyScrollLocks();
   }



   getSheet(){
      // console.log(this.state.slug);
      this.areaService.getData(this.state.slug, "slug").then(dataSheet => {
         this.setState({
            data: dataSheet
         });
      }).catch(err => console.log('There was an error:' + err));
   }

   handleScroll(e){
      // if(e.target.scrollTop < this.lastScroll){
      //    // console.log(e.target.scrollTop +"<"+ this.refHeader.current.clientHeight);
      //    if(e.target.scrollTop < this.refHeader.current.clientHeight){
      //       this.setState({"compactHeader": false});
      //       this.lastScroll = e.target.scrollTop;
      //    }
      // }else{
      //    this.setState({"compactHeader": true});
      //    this.lastScroll = e.target.scrollTop;
      // }


   }

   selectService(area){
      switch (area) {
         case "companies":
            this.areaService = new CompaniesService();
            break;
         case "products":
            this.areaService = new ProductsService();
            break;
         case "events":
            this.areaService = new EventsService();
            break;
         default:
            break;
      }
   }


   openContactform(){
      this.setState({showContactform: true});
   }
   closeContactform(){
      this.setState({showContactform: false});
   }


   render() {

      var initialSlide = 0;
      if(this.state.data && this.state.data.others){
         let i;
         for(i = 0; i < this.state.data.others.length; i++){
            if(this.state.data.others[i].slug === this.state.data.slug){
               initialSlide = i;
               break;
            }
         }
      }
      const settingsOther = {
         dots: false,
         arrows: false,
         infinite: true,
         speed: 500,
         slidesToShow: 1,
         centerMode: true,
         variableWidth: true,
         initialSlide: initialSlide

      };


     if(this.state.translations){

        return (
           <div id="page">
              {(this.state.data ?
                 <div className="content" onScroll={this.handleScroll.bind(this)}>
                    {(this.state.data.others && this.state.data.others.length > 0 ?
                       <div className="other-sheets">
                          <Slider {...settingsOther}>
                             {this.state.data.others.map((item, index) =>(
                                <div className="other-sheet-cnt" key={index}>
                                   <Link to={"/" + this.state.language + "/" + this.state.area + "/" + item.slug} className={"other-sheet " + (item.slug === this.state.data.slug ? "active" : "")} key={index}>
                                      <div className="image">
                                         <img src={ item.imageThumb } alt="" />
                                      </div>
                                      <div className="text">{ item.name }</div>
                                   </Link>
                                </div>
                             ))}
                          </Slider>
                       </div>


                    :
                       ""
                    )}
                    {(() => {
                       switch (this.state.data.type) {
                          case "company":

                             switch (this.state.config.sheet_company) {
                                case "veronafiere-classic":
                                   return <VeronafiereClassicCompanySheet
                                      data={this.state.data}
                                      layout={this.state.layout}
                                      config={this.state.config}
                                      translations={this.state.translations}
                                      language={this.state.language}
                                      isAuthenticated={this.state.isAuthenticated}
                                      user={this.state.user}
                                   />;
                                 /*            case "fieraroma-nse":
                                   return <FieraromaNseCompanySheet
                                      data={this.state.data}
                                      layout={this.state.layout}
                                      config={this.state.config}
                                      translations={this.state.translations}
                                      language={this.state.language}
                                   />; */
                                default:
                                  return <VeronafiereClassicCompanySheet
                                     data={this.state.data}
                                     layout={this.state.layout}
                                     config={this.state.config}
                                     translations={this.state.translations}
                                     language={this.state.language}
                                     isAuthenticated={this.state.isAuthenticated}
                                     user={this.state.user}
                                  />;
                                   // return <DefaultCompanySheet
                                   //    data={this.state.data}
                                   //    layout={this.state.layout}
                                   //    config={this.state.config}
                                   //    translations={this.state.translations}
                                   //    language={this.state.language}
                                   // />;

                             }

                          case "wine":
                             return <WineSheet
                                data={this.state.data}
                                layout={this.state.layout}
                                config={this.state.config}
                                translations={this.state.translations}
                                language={this.state.language}
                             />;
                          case "event":
                             return <EventSheet
                                data={this.state.data}
                                layout={this.state.layout}
                                config={this.state.config}
                                translations={this.state.translations}
                                language={this.state.language}
                             />;
                          default:
                             break;

                       }
                    })()}
                 </div>
              :
                 ""
              )}
           </div>
        );
     }else{
        return(null);
     }

   }
}
