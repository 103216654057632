import { createSelector } from 'reselect'

export const getConfig = createSelector(
  (state) => state.settings.config,
  config => config
)

export const getLayout = createSelector(
  (state) => state.settings.layout,
  layout => layout
)

export const getStatus = createSelector(
  (state) => state.settings.status,
  status => status
)
