import React, { Component } from 'react';
// import { I18n } from 'react-i18next';

import './filterTag.css';

export default class FilterTag extends Component {
   constructor(props){
      super(props);

      this.state = {
         translations: props.translations,
         area: props.area,
         field: props.field,
         value: props.value,
         extended: props.extended,
         currentFilters: props.currentFilters,
         active: false
      };

   }

   toggleTag(e){
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if(!this.state.active){
         this.setState({
            active: true
         });
      }else{
         this.setState({
            active: false
         });
         let currentFilters = this.state.currentFilters;
         delete currentFilters[this.state.field];


         this.setState({
            currentFilters: currentFilters
         });

         this.props.tagCallback(currentFilters);

      }
   }

   removeTag(e){
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();

      this.setState({
         active: false
      });
      let currentFilters = this.state.currentFilters;
      delete currentFilters[this.state.field];


      this.setState({
         currentFilters: currentFilters
      });

      this.props.tagCallback(currentFilters);


   }



   render() {
      return (

       <div className={"tag " + (this.state.active ? "active" : "")}>{
          (this.state.value !== "true" ?
             (this.state.extended ?
                <div className="text">{this.state.translations["filter-"+this.state.area]["_filter-label-" + this.state.field]}: <span className="value">{this.state.value}</span></div>
             :
                <div className="text"><span className="value">{this.state.value}</span></div>
             )
          :
             <div className="text"><span className="value">{this.state.translations["filter-"+this.state.area]["_filter-label-" + this.state.field]}</span></div>
          )
       }<div className="remove" onClick={e => this.removeTag(e)}></div>
       </div>
      );
   }
}
