import auth0 from 'auth0-js';
import axios from "axios";
// import history from './history';
import {DOMAINS} from "./../../../domains"

var isInitialized = false;
var webAuth;

export default class Auth {

   initialize(l, u){
      if(!isInitialized){
         return new Promise((resolve, reject) => {
            webAuth = new auth0.WebAuth({
               domain: DOMAINS[window.location.hostname].auth0.domain,
               clientID: DOMAINS[window.location.hostname].auth0.clientID,
               redirectUri: window.location.origin+"/authorization",
               responseType: DOMAINS[window.location.hostname].auth0.responseType,
               scope: DOMAINS[window.location.hostname].auth0.scope,
               audience: DOMAINS[window.location.hostname].auth0.audience,
               issuer: DOMAINS[window.location.hostname].auth0.issuer,
               leeway: 5*60
            });
            isInitialized = true;
            resolve(true);
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(true);
         });
      }
   }



   getUser(){
      // const user = useSelector(getUser)
      if(isInitialized){
         var that = this;
         return new Promise((resolve, reject) => {
            webAuth.checkSession({}, function (err, authResult) {
               if(!authResult){
                  webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
                     if (err) {
                        return console.log(err);
                     }
                     if(authResult){
                        webAuth.client.userInfo(authResult.accessToken, function(err, user) {

                          // console.log(authResult,err,"pippo");
          // console.log(user);
                          resolve(that.formatUser(user, authResult.accessToken));
                        });
                     }else{
                        resolve(null);
                     }
                  });
               }else{
                  webAuth.client.userInfo(authResult.accessToken, function(err, user) {
                                            // console.log(authResult, err, "pluto");
            // console.log(user);
                     resolve(that.formatUser(user, authResult.idToken));
                  });
               }

            });

         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }
   getFavorites(){
      if(isInitialized){
         return new Promise((resolve, reject) => {
            webAuth.checkSession({}, function (err, authResult) {
              // console.log(authResult);
               if(!authResult){
                  webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
                     if (err) {
                        return console.log(err);
                     }
                     if(authResult){
                        axios.defaults.headers.common['Authorization'] = "Bearer " + authResult.accessToken;
                        axios.get(DOMAINS[window.location.hostname].favorites.apiUrl, {}).then(function(result){
                           if(result.status === 200){
                             let ret = {};
                             ret.company = Array.from(result.data.content, x => x.objectID)
                             resolve(ret);
                           }else{
                              resolve(null);
                           }
                        }).catch(function (error) {
                          console.log(error);
                           resolve(null);
                        });
                     }else{
                        resolve(null);
                     }
                  });
               }else{
                  webAuth.client.userInfo(authResult.accessToken, function(err, user) {
                    axios.defaults.headers.common['Authorization'] = "Bearer " + authResult.accessToken;
                     axios.get(DOMAINS[window.location.hostname].favorites.apiUrl, {}).then(function(result){
                        if(result.status === 200){
                          let ret = {};
                          ret.company = Array.from(result.data.content, x => x.objectID)
                          resolve(ret);
                        }else{
                           resolve(null);
                        }
                     }).catch(function (error) {
                       console.log(error);
                        resolve(null);
                     });
                  });
               }

            });

         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }
   //
   // getFavorites(){
   //    if(isInitialized){
   //       return new Promise((resolve, reject) => {
   //          webAuth.checkSession({}, function (err, authResult) {
   //            console.log(authResult);
   //             if(!authResult){
   //                webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
   //                   if (err) {
   //                      return console.log(err);
   //                   }
   //                   if(authResult){
   //                      axios.defaults.headers.common['Authorization'] = "Bearer " + authResult.accessToken;
   //                      axios.get(config.api_favorites + "/favorites/", {}).then(function(result){
   //                         if(result.status === 200){
   //                           let ret = {};
   //                           ret.company = Array.from(result.data.content, x => x.objectID)
   //                           resolve(ret);
   //                         }else{
   //                            resolve(null);
   //                         }
   //                      }).catch(function (error) {
   //                        console.log(error);
   //                         resolve(null);
   //                      });
   //                   }else{
   //                      resolve(null);
   //                   }
   //                });
   //             }else{
   //                webAuth.client.userInfo(authResult.accessToken, function(err, user) {
   //                  axios.defaults.headers.common['Authorization'] = "Bearer " + authResult.accessToken;
   //                   axios.get(config.api_favorites + "/favorites/", {}).then(function(result){
   //                      if(result.status === 200){
   //                        let ret = {};
   //                        ret.company = Array.from(result.data.content, x => x.objectID)
   //                        resolve(ret);
   //                      }else{
   //                         resolve(null);
   //                      }
   //                   }).catch(function (error) {
   //                     console.log(error);
   //                      resolve(null);
   //                   });
   //                });
   //             }
   //
   //          });
   //
   //       });
   //    }else{
   //       return new Promise((resolve, reject) => {
   //          resolve(null);
   //       });
   //    }
   // }

   getRecommended(user_id){
      return new Promise((resolve, reject) => {

         axios.request({
            url: "https://apicatalogo.veronafiere.it/next/v1/AGR20/users/"+user_id+"/recommend/?preset=home_catalogo",
            method: "GET",
            headers: {
               "x-api-key": "1p4rM75Q.tsmJVjnNMzLkTrKfhdSuj2cjyBQA1YyM"
            }
         }).then(response => {
            let ret = {};
            response.data.forEach(function(val){
               switch(val.code){
                  case "REC":
                     ret.companies = val.items;
                     break;
                  default:
                     break;
               }
            });
            resolve(ret);
         });

      });
   }

   getMostpopular(){
      return new Promise((resolve, reject) => {

         axios.request({
            url: "https://apicatalogo.veronafiere.it/next/v1/AGR20/users/0/recommend/?preset=home_catalogo",
            method: "GET",
            headers: {
               "x-api-key": "1p4rM75Q.tsmJVjnNMzLkTrKfhdSuj2cjyBQA1YyM"
            }
         }).then(response => {
            let ret = {};
            response.data.forEach(function(val){
               switch(val.code){
                  case "POP":
                     ret.companies = val.items;
                     break;
                  default:
                     break;
               }
            });
            resolve(ret);
         });

      });
   }

   getMostpopularagriculture(){
      return new Promise((resolve, reject) => {

         axios.request({
            url: "https://apicatalogo.veronafiere.it/next/v1/AGR20/users/0/recommend/?preset=home_catalogo",
            method: "GET",
            headers: {
               "x-api-key": "1p4rM75Q.tsmJVjnNMzLkTrKfhdSuj2cjyBQA1YyM"
            }
         }).then(response => {
            let ret = {};
            response.data.forEach(function(val){
               switch(val.code){
                  case "AGR-POP-AGR":
                     ret.companies = val.items;
                     break;
                  default:
                     break;
               }
            });
            resolve(ret);
         });

      });
   }

   getMostpopularlivestock(){
      return new Promise((resolve, reject) => {

         axios.request({
            url: "https://apicatalogo.veronafiere.it/next/v1/AGR20/users/0/recommend/?preset=home_catalogo",
            method: "GET",
            headers: {
               "x-api-key": "1p4rM75Q.tsmJVjnNMzLkTrKfhdSuj2cjyBQA1YyM"
            }
         }).then(response => {
            let ret = {};
            response.data.forEach(function(val){
               switch(val.code){
                  case "AGR-POP-LIV":
                     ret.companies = val.items;
                     break;
                  default:
                     break;
               }
            });
            resolve(ret);
         });

      });
   }


   formatUser(user, token){
      if(user){
         let ret = {};
         ret.id = user["sub"];
         ret.firstname = user["https://veronafiere.it/claims/given_name"];
         ret.lastname = user["https://veronafiere.it/claims/family_name"];
         ret.token = token;
         return ret;
      }else{
         return false;
      }
   }






}
