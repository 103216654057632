import React, { Component } from 'react';
import {InlineShareButtons} from 'sharethis-reactjs';

import {LAYOUT_DESKTOP} from "./../../../../settings";

import './sheetContacts.sass';



import Badge from "./../../../App/Badge";
import Logo from "../../../App/Logo";

export default class SheetContacts extends Component {
   constructor(props){
      super(props);
      this.state = {
         config: props.config,
         translations: props.translations,
         title: props.title,
         address: props.address,
         layout: props.layout,
         geographicRegion: props.geographicRegion,
         geographicMap: props.geographicMap,
         halls: props.halls,
         hallsMaps: props.hallsMaps,
         activity: props.activity,
         emails: props.emails,
         websites: props.websites,
         phones: props.phones,
         faxes: props.faxes,
         social: props.social,
         isGeographicMapOpen: false,
         isHallMapOpen: false,
         tenant: props.tenant
      };
   }

   toggleGeographicMap(){
      if(this.state.isGeographicMapOpen){
         this.setState({
            isGeographicMapOpen: false
         });
      }else{
         this.setState({
            isGeographicMapOpen: true
         });
      }
   }

   toggleHallMap(){
      if(this.state.isHallMapOpen){
         this.setState({
            isHallMapOpen: false
         });
      }else{
         this.setState({
            isHallMapOpen: true
         });
      }
   }


   render() {
      let logoSheet = null;
         switch (this.state.tenant) {
            default:
               logoSheet = "default";
               break;
         }


      return (
         <section id="sheetContacts">
            <div className="section-title">
               <div className="bounded">
                  {this.state.title}
               </div>
            </div>
            <div className="section-content">
               <div className="bounded">
                  <div className="columns">
                     {/*<div className="column column-4">
                        {this.state.config.skin === "modern" ?
                           <div>
                              { this.state.layout !== LAYOUT_DESKTOP ?
                                 <div>
                                    <span className="geographic-map-trigger btn btn-default btn-100" onClick={() => this.toggleGeographicMap()}>{this.state.geographicRegion}</span>
                                    <div className={ (this.state.isGeographicMapOpen ? "visible" : "") + " geographic-map-container" }>
                                       { this.state.geographicMap ?
                                          <img className="geographic-map" src={this.state.geographicMap} alt={ this.state.geographicRegion }/>
                                       : "" }
                                    </div>
                                 </div>
                              : "" }

                              <div className="address">
                                 {this.state.address.map((line, index) =>(
                                    <span key={index}>{line}<br /></span>
                                 ))}
                              </div>

                              { this.state.layout === LAYOUT_DESKTOP ?
                                 <div>
                                    <div className="geographic-region">
                                       {this.state.geographicRegion}
                                    </div>
                                    {this.state.geographicRegion !== null ?
                                       <img className="geographic-map" src={this.state.geographicMap} alt="map" />
                                    : "" }
                                 </div>
                              : "" }
                           </div>
                        :
                           <div>{this.state.activity}</div>
                        }
                     </div>*/}
                     <div className="column column-6">
                        {this.state.config.skin === "classic" ?
                        <div className="refer">
                           <div className="name">{this.state.translations["sheet-company"]["Address"]}</div>
                           <div className="value">
                              {this.state.address.map((line, index) =>(
                                 <span key={index}>{line}<br /></span>
                              ))}
                           </div>
                        </div>
                        : "" }
                        {this.state.emails.length > 0 ?
                              <div className="refer">
                                 <div className="name">{this.state.translations["sheet-company"]["Email"]}</div>
                                 <div className="value">
                                    {this.state.emails.map((item, index) =>(
                                       <div key={index}>
                                          <a href={"mailto:" + (item)} target="_blank" rel="noopener noreferrer">{(item)}</a>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                        : "" }
                        {this.state.websites.length > 0 ?
                              <div className="refer">
                                 <div className="name">{this.state.translations["sheet-company"]["Website"]}</div>
                                 <div className="value">
                                    {this.state.websites.map((item, index) =>(
                                       <div key={index}>
                                          <a href={item} target="_blank" rel="noopener noreferrer">{(item)}</a>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                        : "" }
                        {this.state.phones.length > 0 ?
                              <div className="refer">
                                 <div className="name">{this.state.translations["sheet-company"]["Phone"]}</div>
                                 <div className="value">
                                    {this.state.phones.map((item, index) =>(
                                       <div key={index}>
                                          <a href={"tel:"+(item)} target="_blank" rel="noopener noreferrer">{(item)}</a>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                        : "" }
                        {this.state.faxes.length > 0 ?
                              <div className="refer">
                                 <div className="name">{this.state.translations["sheet-company"]["Fax"]}</div>
                                 <div className="value">
                                    {this.state.faxes.map((item, index) =>(
                                       <div key={index}>
                                          <span target="_blank" rel="noopener noreferrer">{(item)}</span>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                        : "" }
                        {Object.keys(this.state.social).length > 0 ?
                              <div className="refer">
                                 <div className="name">{this.state.translations["sheet-company"]["Social"]}</div>
                                 <div className="value">

                                    { this.state.social.twitter ?
                                       <a className="social-icon" href={this.state.social.twitter} target="_blank" rel="noopener noreferrer">
                                          <img src={process.env.PUBLIC_URL + '/assets/icons/social/twitter.svg'} alt="twitter" />
                                       </a>
                                    : "" }
                                    { this.state.social.facebook ?
                                       <a className="social-icon" href={this.state.social.facebook} target="_blank" rel="noopener noreferrer">
                                          <img src={process.env.PUBLIC_URL + '/assets/icons/social/facebook.svg'} alt="facebook" />
                                       </a>
                                    : "" }
                                    { this.state.social.linkedin ?
                                       <a className="social-icon" href={this.state.social.linkedin} target="_blank" rel="noopener noreferrer">
                                          <img src={process.env.PUBLIC_URL + '/assets/icons/social/linkedin.svg'} alt="linkedin" />
                                       </a>
                                    : "" }
                                    { this.state.social.googleplus ?
                                       <a className="social-icon" href={this.state.social.googleplus} target="_blank" rel="noopener noreferrer">
                                          <img src={process.env.PUBLIC_URL + '/assets/icons/social/googleplus.svg'} alt="googleplus" />
                                       </a>
                                    : "" }
                                    { this.state.social.instagram ?
                                       <a className="social-icon" href={this.state.social.instagram} target="_blank" rel="noopener noreferrer">
                                          <img src={process.env.PUBLIC_URL + '/assets/icons/social/instagram.svg'} alt="instagram" />
                                       </a>
                                    : "" }
                                 </div>
                              </div>
                        : "" }
                        {/*<br /><br />
                        <Logo variant={logoSheet} width={{value: "200", unit: "px"}} />*/}
                        <br /><br />
                     </div>
                     <div className="column column-6">
                        { this.state.config.enable_halls && this.state.halls.length > 0 ?
                           <div>
                              { this.state.layout !== LAYOUT_DESKTOP ?
                                 <div>
                                    <span className="hall-map-trigger btn btn-default btn-100" onClick={() => this.toggleHallMap()}>{this.state.halls}</span>
                                    <div className={ (this.state.isHallMapOpen ? "visible" : "") + " hall-map-container" }>
                                       <img width="100%" src={process.env.PUBLIC_URL + "/assets/maps/halls/"+this.state.tenant+"/void.png"} alt=""/>

                                       {this.state.hallsMaps.map(function(booth, i){
                                         return <img className="level" key={ i } src={ booth } alt="" style={{"opacity": 0.5}}/>;
                                       })}
                                    </div>
                                 </div>
                              :
                                 <div>
                                    <div className="halls">{this.state.halls.map(item =>(
                                        <span>{item}<br /></span>
                                    ))
                                    }</div>
                                    <div className="hall-map-container visible">
                                       <img width="100%" src={process.env.PUBLIC_URL + "/assets/maps/halls/"+this.state.tenant+"/void.png"} alt=""/>
                                       {this.state.hallsMaps.map(function(booth, i){
                                         return <img className="level" key={ i } src={ booth } alt="" style={{"opacity": 0.7}}/>;
                                       })}
                                    </div>
                                 </div>
                              }
                           </div>
                        :
                           <div>
                              <Badge>{this.state.translations["UI"]["_location-online-soon"]}</Badge>
                              <div className="hall-map-container visible">
                                 <img width="100%" src={process.env.PUBLIC_URL + "/assets/maps/halls/"+this.state.tenant+"/void.png"} alt=""/>
                              </div>
                           </div>
                        }
                     </div>
                  </div>
               </div>
               {this.state.config.skin === "classic" ?
                  <div className="sharebuttons">
                     <InlineShareButtons
                      config={{
                        alignment: 'left',  // alignment of buttons (left, center, right)
                        color: 'social',      // set the color of buttons (social, white)
                        enabled: true,        // show/hide buttons (true, false)
                        font_size: 22,        // font size for the buttons
                        labels: 'null',        // button labels (cta, counts, null)
                        language: this.state.language,       // which language to use (see LANGUAGES)
                        networks: this.state.config.share_networks,
                        padding: 9,          // padding within buttons (INTEGER)
                        radius: 0,            // the corner radius on each button (INTEGER)
                        show_total: false,
                        size: 32,             // the size of each button (INTEGER)
                        spacing: false,

                        // OPTIONAL PARAMETERS
                        // url: 'https://www.sharethis.com', // (defaults to current url)
                        // image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                        // description: 'custom text',       // (defaults to og:description or twitter:description)
                        // title: 'custom title',            // (defaults to og:title or twitter:title)
                        // message: 'custom email text',     // (only for email sharing)
                        // subject: 'custom email subject',  // (only for email sharing)
                        // username: 'custom twitter handle' // (only for twitter sharing)
                      }}
                     />
                  </div>
               : "" }
            </div>
         </section>
      );
   }
}
