export const OVR = {
  tenant: "OVR",
  name: "Oil&NonOil 2021",
  analytics: null,
  algolia: {
    id: "T0IU9K346R",
    key: "ffbea16a86e817e3ca6ba78f8d05ced6",
    index_companies: "OILNONOIL_COMPANIES_",
    index_products: null,
    index_events: null,
    index_projects: null,
    index_interface: "OILNONOIL_INTERFACE",
  },
  auth0: {
    domain: "login.veronafiere.it",
    clientID: "QTC7Il6kfZ7nkNQM1TQIHFUHOy5CyBzo",
    responseType: "token id_token",
    scope: 'openid profile email',
    audience: "https://veronafiere.api.it",
    issuer: 'https://login.veronafiere.it/',
  },
  favorites: {
    apiUrl: "https://vrf-samoter-app.appspot.com/favorites",
    queueUrl: "https://sqs.eu-west-1.amazonaws.com/703459561458/VRF-PreferredUpdate",
    region: 'eu-west-1',
    accessKeyId: "AKIAJVNTPFW5X7PHQNDQ",
    secretAccessKey: "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"
  },
  share_networks: ["facebook", "twitter", "pinterest"],
  cookiebot: "de73256b-1917-47ed-8148-211c78109b54",


  enable_halls: true,
  enable_products: false,
  enable_products_area: false,
  enable_events: false,
  enable_events_area: false,
  enable_projects: false,
  enable_projects_area: false,
  enable_login: false,
  enable_favorites: false,

  filter_companies: {
     "premium": {
        "premium" : {
           "default": false,
           "type": "button",
           "field": "BackofficeAnagraphicInfo.Premium"
        }
     },
     "geographic": {
        "countries" : {
           "default": true,
           "type": "list",
           "field": "AnagraphicInfo.Address.Country"
        },
        "regions" : {
           "default": false,
           "type": "list",
           "field": "AnagraphicInfo.Address.Region"
        },
        "provinces" : {
           "default": false,
           "type": "list",
           "field": "AnagraphicInfo.Address.Province"
        }
     },
     "merceologies": {
        "categories" : {
           "default": true,
           "type": "tree",
           "field": "AnagraphicInfo.Categories"
        }
     },
  },
  filter_products: {},
  filter_events: {},
  filter_projects: null,
  max_stand_short: 3,
  max_stand_long: 10,
  skin: "classic",
  sheet_company: "veronafiere-classic",
  sheet_product: null,
  sheet_event: null,
  sheet_project: null

};
