import React, { Component } from 'react';
import Slider from "react-slick";
import EventThumb from "./../../../old/entities/events/sub/event/eventThumb";
import { LAYOUT_MOBILE } from "./../../../../settings";

import './sheetEventsList.sass';

export default class SheetEventsList extends Component {
   constructor(props){
      super(props);

      this.state = {
         title: props.title,
         events: props.events,
         config: props.config,
         translations: props.translations,
         language: props.language,
         layout: props.layout
      };
   }

   render() {
      const settingsSheet = {
         dots: false,
         arrows: true,
         speed: 500,
         centerMode: false,
         slidesToShow: (this.state.layout !== LAYOUT_MOBILE ? 4 : 1),
         slidesToScroll: 1,
         infinite: this.state.events.length > (this.state.layout !== LAYOUT_MOBILE ? 4 : 1)
      };

      return (
         <section id="sheetEventsList">
            <div className="section-title">
               <div className="bounded">
                  {this.state.title}
               </div>
            </div>
            <div className="section-content">
               <div className="slider-cnt">
                  <Slider {...settingsSheet}>
                     {this.state.events.map((item, index) =>(
                        <EventThumb
                           key={index}
                           data={item}
                           config={this.state.config}
                           translations={this.state.translations}
                           language={this.state.language}
                        />
                     ))}
                  </Slider>
               </div>
            </div>
         </section>
      );
   }
}
