import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTheme } from './../../themes'

const Wrapper  = styled.div`
  max-width: 100%;
  position: relative;
  width: ${props => props.w !== null ? props.w.value + props.w.unit : "auto"};
  height: ${props => props.h !== null ? props.h.value + props.h.unit : "auto"};
  text-align: center;
  display: inline-block;
  margin: 0 auto;
`;
const Image  = styled.img`
  max-width: 100%;
  ${props => (props.w !== null && props.h !== null) ? `
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  ` : ((props.w !== null) ? `
    width: ${props.w.value + props.w.unit};
  ` : `
    height: ${props.h.value + props.h.unit};
  `)}
`;

const Logo = ({
    variant = "default",
    width = null,
    height = null
  }) => {
  const theme = useTheme()
  return (
    <Wrapper w={width} h={height}>
      <Image w={width} h={height} src={theme.logo.variant[variant]} alt="Logo" />
    </Wrapper>
  )
}

Logo.propTypes = {
  variant: PropTypes.string, //V: vertical H: horizontal
  width: PropTypes.object,
  height: PropTypes.object
}

export default React.memo(Logo)
