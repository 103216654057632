import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTheme } from './../../themes';

import ToggleFilters from "./../../components/Filters/ToggleFilters"

const Wrapper = styled.div`
  height: 46px;
  margin: 0 auto;
  display: block;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  background-color: ${props => props.background};
`;

const Toggle = styled.div`
   position: absolute;
   top: 5px;
   right: 5px;
   width: 36px;
   height: 36px;
`;

const SearchField = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 46px);
  height: 100%;
  border: 0;
  line-height: 46px;
  padding: 0 20px;
  font-size: 16px;
  background-color: transparent;
  color: ${props => props.textColor}

  &:focus{
    outline: none;
  }

  &:placeholder{
    color: ${props => props.placeholderColor}
  }
`;

function Search({placeholder, onChange, value, isFilterActive}) {
  const theme = useTheme()

  return (
    <Wrapper background={theme.list.search.background}>
      <Toggle>
        <ToggleFilters
          iconColor={theme.list.search.icon.normal.color}
          iconBackground={theme.list.search.icon.normal.background}
          iconActiveColor={theme.list.search.icon.active.color}
          iconActiveBackground={theme.list.search.icon.active.background}
          isActive={isFilterActive}
        />
      </Toggle>

      <SearchField
        type="text"
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        value={value}
        textColor={theme.list.search.textColor}
        placeholderColor={theme.list.search.placeholderColor}
      />
    </Wrapper>
  );
}

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isFilterActive: PropTypes.bool
}

export default React.memo(Search)
