import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Wrapper  = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.size.value+props.size.unit};
  height: ${props => props.size.value+props.size.unit};
  border-radius: 100%;
  background-color: ${props => props.background};
  opacity: ${props => props.opacity};

  &:hover{
    background-color: ${props => props.hoverBackground};
  }
`;
const Image  = styled.div`
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  background-color: ${props => props.color};
  mask: url("/assets/icons/${props => props.name}.svg") no-repeat 50% 50%;
  mask-size: contain;

  &:hover{
    background-color: ${props => props.hoverColor};
  }
`;


const Icon = ({
  name,
  color = "#000000",
  background = "transparent",
  hoverColor = color,
  hoverBackground = background,
  opacity = 1,
  size = {value: 60, unit: "px"}
}) => {

  return (
    <Wrapper size={size} background={background} hoverBackground={hoverBackground} opacity={opacity}>
      <Image name={name} color={color} hoverColor={hoverColor} />
    </Wrapper>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverBackground: PropTypes.string,
  opacity: PropTypes.number,
  size: PropTypes.object
}

export default React.memo(Icon)
