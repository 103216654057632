import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTheme } from './../../themes'

import { getLayout, getStatus } from './../../store/selectors/settings'
import { setStatus } from '../../store/actions/settings'
import { LAYOUT_XS, LAYOUT_SM } from './../../settings'
// import { LAYOUT_DESKTOP } from './../../settings'

import UserBar from "./../User/UserBar"
import LanguageSelector from "./../App/LanguageSelector"
import Icon from "./../App/Icon"
import MenuItem from "./../App/MenuItem"

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  right: ${ props => props.isOpen ? "0px" : "-100%" };
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : (props.isOpen ? "100%" : "380px") };
  height: 100%;
  transition: all 0.25s ease-in-out;
`;
const ShadowArea = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "80px" : "calc(100% - 380px)" };

  height: 100%;
`;
const PanelArea = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "calc(100% - 80px)" : "380px" };
  height: 100%;
  background: ${ props => props.background };
  box-shadow: -5px 0px 12px 0px #00000033;
`;

function MorePanel() {
  const theme = useTheme()
  const dispatch = useDispatch()

  const SECTION_LANGUAGE = 1

  const [section, setSection] = useState(null)

  const layout = useSelector(getLayout)
  const status = useSelector(getStatus)

  const closeMorePanel = useCallback(() => {
    dispatch(setStatus({
      isFilterOpen: false
    }), [dispatch])

  }, [dispatch])

  return (
    <Wrapper layout={layout} isOpen={status.isMoreOpen}>
      <ShadowArea layout={layout} onClick={closeMorePanel} />
      <PanelArea layout={layout} background={theme.morepanel.background}>
        {(() => {
          switch (section) {
            case SECTION_LANGUAGE:
              return <>
                <MenuItem
                  onClick={() => setSection(null)}
                  leftIcon={<Icon
                    name={theme.icons.menuitemPrev}
                    color={theme.morepanel.bar.textColor}
                    wrapped={false}
                  />}
                  variant={theme.morepanel.menuitemVariantBar}
                >Indietro</MenuItem>
                <LanguageSelector/>
              </>;
            default:
              return <>
                <UserBar/>
                <MenuItem
                  onClick={() => setSection(SECTION_LANGUAGE)}
                  leftIcon={<Icon
                    name={theme.icons.language}
                    color={theme.primaryColor}
                    wrapped={false}
                  />}
                  rightIcon={<Icon
                    name={theme.icons.menuitemNext}
                    color={theme.primaryColor}
                    wrapped={false}
                  />}
                  variant={theme.morepanel.menuitemVariantItem}
                >Lingua</MenuItem>
              </>;
          }
        })()}
      </PanelArea>
    </Wrapper>



  );
}

export default React.memo(MorePanel)
