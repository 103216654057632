import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createBrowserHistory } from 'history'
// import { routerMiddleware } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'
import createRootReducer from './reducers'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'

const persistConfig = {
  key: 'data-db',
  storage,
}

const history = createBrowserHistory()
const rootReducer = createRootReducer(history)

const persistedReducer = persistReducer(persistConfig, rootReducer)

const logger = createLogger({ collapsed: true })

const sagaMiddleware = createSagaMiddleware()

let middleware = [ sagaMiddleware, /*routerMiddleware(history), */]

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger]
}

const store = createStore(
  persistedReducer,
  {},
  compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
)

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export default {
  store,
  persistor,
  history
}
