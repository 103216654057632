import React from 'react'
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import styled from 'styled-components'

import Authorization from './../../components/User/Authorization'

import MorePanel from './../../components/More/MorePanel'
import Home from './../../components/Home/Home'
import List from "./../../components/List/List"
import Page from "./../../components/Page/Page"

import { DOMAINS } from "./../../domains"

// const BarWrapper = styled.div`
//   position: absolute;
//   bottom: 0px;
//   left: 0px;
//   width: 100%;
//   height: 60px;
//   background-color: #fff;
//   box-shadow: 0px -2px 2px 1px rgba(0,0,0,0.1);
// `;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${ props => props.withBar ? "calc(100% - 60px)" : "100%" };
`;

function Mobile(route) {
  return (
    <>
      <BrowserRouter>
        <ContentWrapper withBar={ DOMAINS[window.location.hostname].enable_products_area || DOMAINS[window.location.hostname].enable_events_area || DOMAINS[window.location.hostname].enable_projects_area }>
          <Switch>
            <Route exact path='/authorization' render={(route) => <Authorization/>} />
            <Route exact path='/' render={(route) => <Redirect to={"/it"} />} />
            <Route exact path='/:lang' render={(route) => <Home  />} />
            <Route exact path='/:lang/:area' render={(route) => <List />} />
            <Route exact path='/:lang/:area/:slug' render={(route) => <Page />} />
          </Switch>
        </ContentWrapper>
        <Switch>
          <Route exact path='/authorization' render={(route) => <></>} />
          <Route exact path='/:lang/:area?/:slug?' children={<MorePanel />} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default React.memo(Mobile)
