import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components';

import { getUser, getFavorites } from './../../store/selectors/auth'
import { setFavorite } from '../../store/actions/auth'
import { useTheme } from './../../themes';

import Icon from "./../App/Icon";


const Wrapper  = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const ToggleFavoriteButton = ({slug}) => {
  const theme = useTheme()
  let { area } = useParams()

  const user = useSelector(getUser)
  const favorites = useSelector(getFavorites)
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(setFavorite({
      idUser: user.id,
      area: area,
      idElement: slug,
      isFavorite: !favorites[area].includes(slug)
    }), [dispatch])
  }, [dispatch, user, area, slug, favorites]);

  return (
    <Wrapper onClick={onClick}>
      {user ?
        <Icon
          name={theme.icons.favorite}
          size={{value: 30, unit: "px"}}
          color={favorites && area && favorites[area].includes(slug) ? theme.primaryColor : theme.inactiveColor}
          isFav={favorites && area && favorites[area].includes(slug)}
        />
      :
        ""
      }
    </Wrapper>
  )
}

ToggleFavoriteButton.propTypes = {
  slug: PropTypes.string.isRequired
}

export default React.memo(ToggleFavoriteButton)
