import React, { Component } from 'react';
import { i18n } from 'react-i18next';

// import WineryThumb from "./../../../../old/entities/companies/sub/winery/wineryThumb";

import './eventSheet.css';


export default class EventSheet extends Component {
   constructor(props) {
      super(props)

      this.state = {
         config: props.config,
         translations: props.translations,
         language: props.language,
         data: props.data
      };
   }

   componentWillReceiveProps(nextProps) {
      if(nextProps.data !== this.state.data){
         this.setState({data: nextProps.data});
      }
   }

   getEventsDate(dateStart, dateEnd) {

      if(dateEnd === ""){
         dateEnd = dateStart;
      }

      //se le date sono uguali estrae un giorno solo
      //date diverse estrae + giorni

      // var parts;
      // parts = dateStart.split('/');
      // let dataInizio = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
      // parts = dateStart.split('/');
      // let dataFine = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);

      let dataInizio = new Date(dateStart * 1000);
      let dataFine = new Date(dateEnd * 1000);

      // se le date sono uguali ritorno la stringa composta
      if (dataInizio.getFullYear() === dataFine.getFullYear()) {
         if (dataInizio.getMonth() === dataFine.getMonth()) {
            if (dataInizio.getDate() === dataFine.getDate()) {
               return (
                  <i18n ns="translations">
                     {
                        (t, { i18n }) => (
                           <div>{dataInizio.getDate() + " " + t('fullmonths.'+dataInizio.getMonth()) + " " + dataInizio.getFullYear()}</div>
                        )
                     }
                  </i18n>
               );
            }else{
               return (
                  <i18n ns="translations">
                     {
                        (t, { i18n }) => (
                           <div>{dataInizio.getDate() + " / " + dataFine.getDate() + " " + t('fullmonths.'+dataFine.getMonth()) + " " + dataFine.getFullYear()}</div>
                        )
                     }
                  </i18n>
               );
            }
         }else{
            return (
               <i18n ns="translations">
                  {
                     (t, { i18n }) => (
                        <div>{dataInizio.getDate() + " " + t('fullmonths.'+dataInizio.getMonth()) + " / " + dataFine.getDate() + " " + t('fullmonths.'+dataFine.getMonth()) + " " + dataFine.getFullYear()}</div>
                     )
                  }
               </i18n>
            );
         }
      }else{
         return (
            <i18n ns="translations">
               {
                  (t, { i18n }) => (
                     <div>{dataInizio.getDate() + " " + t('fullmonths.'+dataInizio.getMonth()) + " " + dataInizio.getFullYear() + " / " + dataFine.getDate() + " " + t('fullmonths.'+dataFine.getMonth()) + " " + dataFine.getFullYear()}</div>
                  )
               }
            </i18n>
         );
      }

   }

   closeContactform(){
      this.props.closeContactform();
   }


   render() {
      // console.log("Asd");
      // console.log(this.state.data);
      if(this.state.data !== null){
         return (
            <i18n ns="translations">
               {(t, { i18n }) => (
                  <div id="event-sheet">
                     <div className="cover">
                        {/* <img src={this.state.data.UrlCDN + "slug-mancante/" +  "da890c35-f434-5e7c-b0ef-323d5830b225"} alt="Cover" /> */}
                     </div>

                     <div className="icons">
                        <ul>
                           <li className="icon typology"><span className={"icon"} style={{backgroundImage: 'url(' + this.state.data.typologyIcon + ')'}}></span> <span className="text">{this.state.data.typology}</span></li>
                           <li className="icon access"><span className={"icon"} style={{backgroundImage: 'url(' + this.state.data.accessIcon + ')'}}></span> <span className="text">{this.state.data.access}</span></li>
                        </ul>
                     </div>

                     {/* contenuto evento */}
                     <div className="content">
                        <div className="info">
                           {this.state.data.dates.map(function(date, i){
                              return (
                                 <div className="day">
                                    <div className="date">{date.string}</div>
                                    <div className="time">{date.start} - {date.end}</div>
                                 </div>
                              );
                           })}
                           <h3>
                              {
                                 (this.state.data.country !== undefined
                                 ?
                                 this.state.data.address + " " + this.state.data.city + " " + (this.state.data.province ? "("+this.state.data.province+")" : "")
                                 :
                                 "Vinitaly")
                              }
                           </h3>
                        </div>
                        <h1>{this.state.data.name}</h1>
                        {/* <h3>{he.decode(this.state.data.Description[i18n.language])}</h3> */}
                        <div className="description">
                           <p>{this.state.data.description}</p>
                        </div>
                        <div className="web">
                           <a href={this.state.data.Website} target="_blank" rel="noopener noreferrer">{this.state.data.Website}</a>
                        </div>
                        <div className="direct">
                           <span className="btn btn-default btn-100" onClick={this.props.openContactform.bind(this)}>{t('Contact directly')}</span>
                        </div>
                        <div className="winery">
                           {/*<WineryThumb
                              data={this.state.data.company}
                              config={this.state.config}
                              translations={this.state.translations}
                              language={this.state.language}
                           />*/}
                        </div>
                     </div>
                  </div>

               )}
            </i18n>
         );
      }else{
         return (<div className=""></div>);
      }
   }
}
