import { LAYOUT_XS, LAYOUT_SM, LAYOUT_MD, LAYOUT_LG } from './../../settings'

import logoDefault from './assets/logo.svg'
import logoBlack from './assets/logo-black.svg'
import logoWhite from './assets/logo-white.svg'
import logoAsphaltica from './assets/logo-asphaltica.svg'
import logoIccx from './assets/logo-iccx.svg'

export const SAM = {
  logo: {
    variant: {
      "default": logoDefault,
      "black": logoBlack,
      "white": logoWhite,
      "asphaltica": logoAsphaltica,
      "iccx": logoIccx
    }
  },
  background:{
    homepage: "#F1F1F1",
    global: "#F1F1F1"
  },
  icons: {
    openFilters: "filters-1",
    closeFilters: "close-1",
    clearFilters: "trash-1",
    favorite: "favorite-1",
    homepage: "home-2",
    more: "more-1",
    list: "list-1",
    menuitemPrev: "arrow-left-2",
    menuitemNext: "arrow-right-2",
    language: "language-1"
  },
  container: {
    [LAYOUT_XS]: {
      width: {
        value: 100,
        unit: "%"
      }
    },
    [LAYOUT_SM]: {
      width: {
        value: 100,
        unit: "%"
      }
    },
    [LAYOUT_MD]: {
      width: {
        value: 600,
        unit: "px"
      }
    },
    [LAYOUT_LG]: {
      width: {
        value: 800,
        unit: "px"
      }
    }
  },
  morepanel:{
    background: "#f5f5f5",
    bar: {
      height: {
        value: 60,
        unit: "px"
      },
      padding:{
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      },
      background: "#1A1A1A",
      textColor: "#ffffff",
      textFont: null,
      textSize: null,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
      buttonVariant: "default"
    },
    menuitemVariantBar: "inverse",
    menuitemVariantItem: "default"
  },
  topbar:{
    desktop:{
      height: {
        value: 60,
        unit: "px"
      },
      background: "#1A1A1A",
      textColor: "#ffffff",
      badgeVariant: "default",
      enable:{
        website: false,
        homeButton: true,
        updateBadge: true
      },
      icon:{
        normal:{
          color: "#ffd300",
          background: "transparent"
        },
        hover:{
          color: "#ffd300",
          background: "transparent"
        },
        active:{
          color: "#ffd300",
          background: "transparent"
        }
      }
    },
    mobile:{
      height: {
        value: 60,
        unit: "px"
      },
      background: "#1A1A1A",
      textColor: "#ffffff",
      badgeVariant: "default",
      enable:{
        website: false,
        homeButton: true,
        updateBadge: true
      },
      logo:{
        variant: "white",
        height: {
          value: 40,
          unit: "px"
        },
        width: null,
        alignment: "C", // L C R
      },
      icon:{
        normal:{
          color: "#ffd300",
          background: "transparent"
        },
        hover:{
          color: "#ffd300",
          background: "transparent"
        },
        active:{
          color: "#ffd300",
          background: "transparent"
        }
      }
    }
  },
  list:{
    width: {
      value: 380,
      unit: "px"
    },
    background: "#ffffff",
    logo:{
      variant: "default",
      height: {
        value: 40,
        unit: "px"
      },
      width: {
        value: 340,
        unit: "px"
      },
      alignment: "C", // L C R
    },
    search:{
      background: "#EDEDED",
      textColor: "#000000",
      placeholderColor: "#ffffff",
      icon:{
        normal:{
          color: "#ffd300",
          background: "transparent",
        },
        hover:{
          color: "#ffd300",
          background: "transparent",
        },
        active:{
          color: "#000000",
          background: "#ffd300",
        }
      }
    },
    result:{
      padding: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 20,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      },
      borderColor: "#cccccc",
      borderStyle: "solid",
      borderSize: 1,
      normal:{
        background: "transparent"
      },
      hover: {
        background: "#f4f4f4"
      },
      active: {
        background: "#DFE2EB"
      },
      position: {
        padding: null,
        order: 1,
        textFont: null,
        textSize: 11,
        textWeight: 600,
        textLineHeight: null,
        textLetterSpacing: null,
        textTransform: null,
        normal:{
          color: "#333333"
        },
        hover: {
          color: "#333333"
        },
        active: {
          color: "#333333"
        },
      },
      title: {
        padding: null,
        order: 1,
        textFont: null,
        textSize: 18,
        textWeight: 400,
        textLineHeight: null,
        textLetterSpacing: null,
        textTransform: null,
        normal:{
          color: "#333333"
        },
        hover: {
          color: "#333333"
        },
        active: {
          color: "#333333"
        },
      },
      place: {
        padding: null,
        order: 1,
        textFont: null,
        textSize: 12,
        textWeight: 400,
        textLineHeight: 23,
        textLetterSpacing: null,
        textTransform: null,
        normal:{
          color: "#333333"
        },
        hover: {
          color: "#333333"
        },
        active: {
          color: "#333333"
        }
      },
      icon:{
        normal:{
          color: "#ffd300",
          background: "transparent"
        },
        hover:{
          color: "#ffd300",
          background: "transparent"
        },
        active:{
          color: "#ffd300",
          background: "transparent"
        }
      }
    }
  },
  filters:{
    width: {
      value: 380,
      unit: "px"
    },
    background: "#ffd300",
    color: "#1A1A1A",
    buttonVariant: "on-yellow",
    icon:{
      normal:{
        color: "#1A1A1A",
        background: "transparent"
      },
      hover:{
        color: "#1A1A1A",
        background: "transparent"
      },
      active:{
        color: "#1A1A1A",
        background: "transparent"
      }
    }
  },

  typography: {
    text: {
      textColor: "#6F6F6F",
      textFont: '"DIN", sans-serif',
      textSize: 18,
      textWeight: 600,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h1: {
      textColor: "#6F6F6F",
      textFont: null,
      textSize: 50,
      textWeight: 600,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h2: {
      textColor: "#6F6F6F",
      textFont: null,
      textSize: 40,
      textWeight: 600,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h3: {
      textColor: "#6F6F6F",
      textFont: null,
      textSize: 16,
      textWeight: 600,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },

  },
  button:{
    padding: {
      top:{
        value: 8,
        unit: "px"
      },
      left:{
        value: 20,
        unit: "px"
      },
      bottom:{
        value: 8,
        unit: "px"
      },
      right:{
        value: 20,
        unit: "px"
      }
    },
    textFont: null,
    textSize: 18,
    textWeight: 600,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: null,
    borderSize: 2,
    borderStyle: "solid",
    borderRadius: {
      value: 0,
      unit: "px"
    },
    variant:{
      "default": {
        normal:{
          textColor: "#ffffff",
          borderColor: "#ffd300",
          background: "#1A1A1A"
        },
        hover:{
          textColor: "#1A1A1A",
          borderColor: "#ffd300",
          background: "#ffd300"
        }
      },
      "default-inverse": {
        normal:{
          textColor: "#1A1A1A",
          borderColor: "#ffd300",
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#ffd300",
          background: "#ffd300"
        }
      },
      "on-yellow": {
        normal:{
          textColor: "#1A1A1A",
          borderColor: "#1A1A1A",
          background: "#ffd300"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#1A1A1A",
          background: "#1A1A1A"
        }
      },
    },
  },
  badge:{
    padding: {
      top:{
        value: 5,
        unit: "px"
      },
      left:{
        value: 20,
        unit: "px"
      },
      bottom:{
        value: 5,
        unit: "px"
      },
      right:{
        value: 20,
        unit: "px"
      }
    },
    textFont: null,
    textSize: 13,
    textWeight: 900,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: null,
    borderSize: 0,
    borderStyle: "solid",
    borderRadius: {
      value: 0,
      unit: "px"
    },
    variant:{
      "default": {
        textColor: "#1A1A1A",
        borderColor: "transparent",
        background: "#ffd300"
      }
    }
  },

  menuitem:{
    padding: {
      top:{
        value: 15,
        unit: "px"
      },
      left:{
        value: 20,
        unit: "px"
      },
      bottom:{
        value: 15,
        unit: "px"
      },
      right:{
        value: 20,
        unit: "px"
      }
    },
    textFont: null,
    textSize: 11,
    textWeight: 900,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: null,
    borderSize: 0,
    borderStyle: "solid",
    borderColor: "#cccccc",
    height:{
      value: 30,
      unit: "px"
    },
    variant:{
      "default": {
        normal:{
          textColor: "#1A1A1A",
          background: "transparent"
        },
        hover:{
          textColor: "#1A1A1A",
          background: "#ffd300"
        },
        active:{
          textColor: "#1A1A1A",
          background: "#ffd300"
        }
      },
      "inverse": {
        normal:{
          textColor: "#ffffff",
          background: "#1A1A1A"
        },
        hover:{
          textColor: "#ffffff",
          background: "#1A1A1A"
        },
        active:{
          textColor: "#ffffff",
          background: "#1A1A1A"
        }
      }
    }
  }
};
