import React, { Component } from 'react';
import Auth from './../../../utils/auth';

import Result from "./../../../../List/Result";

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import './resultsList.css';


export default class ResultsList extends Component {
   constructor(props){
      super(props);

      this.state = {
         config: props.config,
         translations: props.translations,
         language: props.language,
         results: props.results,
         currentSlug: props.currentSlug,
         favorites: null
      };

      if(this.state.config.enable_login && this.state.config.enable_favorites){
         this.auth = new Auth();
         this.auth.initialize(this.state.config, this.state.language, window.location.origin).then(ret  => {
            if(ret === true) {
               this.auth.getUser().then(ret  => {
                  if(ret != null){
                     this.setState({
                        isAuthenticated: true
                     });
                     this.auth.getFavorites().then(ret  => {
                        this.setState({
                           favorites: ret
                        });
                     }).catch(err => console.log('There was an error:' + err));
                  }else{
                     this.setState({
                        isAuthenticated: false,
                        favorites: null
                     });
                  }
               }).catch(err => console.log('There was an error:' + err));
            }
         }).catch(err => console.log('There was an error:' + err));
      }
      this.refResults = React.createRef();

   }

   componentWillReceiveProps(nextProps) {
      // if (nextProps.results !== this.state.results) {
         this.setState({
            results: nextProps.results,
            currentSlug: nextProps.currentSlug
            // loadingResults: !nextProps.complete
         });
      // }
   }

   componentDidUpdate(){
      disableBodyScroll(document.querySelector("#resultslist"));
   }
   componentWillUnmount() {
      clearAllBodyScrollLocks();
   }


   handleScroll(e){
      if(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight /3*2){
         this.props.loadmoreCallback();
      }
   }

   render(){
      if(this.state.translations){
         return (
            <div id="resultslist" onScroll={(e) => this.handleScroll(e)} ref={this.refResults}>
               <div className="scroller">
                  {this.state.results.map((item, index) =>(
                    <Result
                      slug={item.slug}
                      position={item.halls.join(", ")}
                      title={item.highlighted.name}
                      place={item.highlighted.country + (item.highlighted.region  ? " | " + item.highlighted.region : "" ) + (item.highlighted.province ? " | " + item.highlighted.province : "" ) }
                      key={index}
                      isActive={(item.slug === this.state.currentSlug ? true : undefined)}
                      isFavorite={(this.state.config.enable_login && this.state.config.enable_favorites && this.state.favorites ? (this.state.favorites[item.type].includes(item.id)) : false)}
                    />
                  ))}

                  { this.props.updatingStatus === 1 ?
                     <div className="loading"><i className="fa fa-circle-o-notch fa-spin"></i></div>
                  : this.state.results.length > 0 ?
                     <div className="ended">{this.state.translations["UI"]["_no-more-results"]}</div>
                  :
                     <div className="ended">{this.state.translations["UI"]["_no-results"]}</div>
                  }


               </div>
            </div>
         );
      }else{
         return null;
      }

   }
}
