import React from 'react'
import styled from 'styled-components'
import { useTheme } from './../../themes';

// import { DOMAINS } from "./../../domains"

import HomeButton from "./../../components/Home/HomeButton";
import MoreButton from "./../../components/More/MoreButton";
import UpdateNotification from "./../../components/App/UpdateNotification";
import WebsiteLink from "./../../components/App/WebsiteLink";

const Wrapper = styled.div`
  width: 100%;
  height: ${ props => props.height.value+props.height.unit };
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  ${props => props.background ? "background: "+props.background+";" : ""}
`;
const Item = styled.div`
  margin-left: 10px;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

function Header() {
  const theme = useTheme()

  return (
    <Wrapper background={theme.topbar.desktop.background} height={theme.topbar.desktop.height}>
      <Left>
        {theme.topbar.desktop.enable.updateBadge ?
          <Item>
            <UpdateNotification />
          </Item>
        : "" }
      </Left>
      <Right>
        {theme.topbar.desktop.enable.website ?
          <Item>
            <WebsiteLink />
          </Item>
        : "" }
        {theme.topbar.desktop.enable.homeButton ?
          <Item>
            <HomeButton
              size={{value: 40, unit: "px"}}
              color={theme.topbar.desktop.icon.normal.color}
              background={theme.topbar.desktop.icon.normal.background}
            />
          </Item>
        : "" }
        <Item>
          <MoreButton
            size={{value: 40, unit: "px"}}
            color={theme.topbar.desktop.icon.normal.color}
            background={theme.topbar.desktop.icon.normal.background}
          />
        </Item>
      </Right>
    </Wrapper>
  );
}

export default React.memo(Header)
