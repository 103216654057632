import React, { useCallback } from 'react';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { logout } from '../../store/actions/auth'

import Button from "./../App/Button"


const LogoutButton = ({variant, solid, children}) => {
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(logout(), [dispatch])
  }, [dispatch]);

  return (
      <Button variant={variant} solid={solid} onClick={onClick}>{children}</Button>
  )
}

LogoutButton.propTypes = {
  variant: PropTypes.string,
  solid: PropTypes.bool,
}

export default React.memo(LogoutButton)
