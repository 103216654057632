import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useTheme } from './../../themes';

import { setStatus } from '../../store/actions/settings'

import Icon from "./../App/Icon";


const Wrapper  = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const CloseFilters = () => {
  const theme = useTheme()

  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(setStatus({
      isFilterOpen: false
    }), [dispatch])

  }, [dispatch]);

  return (
    <Wrapper onClick={onClick}>
      <Icon
        name={theme.icons.closeFilters}
        size={{value: 40, unit: "px"}}
        color={theme.filters.icon.normal.color}
      />
    </Wrapper>
  )
}

export default React.memo(CloseFilters)
