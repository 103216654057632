import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom';

import { useTheme } from './../../themes';

import { getConfig, getLayout } from './../../store/selectors/settings'
import { LAYOUT_XS, LAYOUT_SM, LAYOUT_DESKTOP, LAYOUT_MOBILE } from './../../settings'

import MobileTopbar from "./../../components/Layout/MobileTopbar"
import Logo from "./../../components/App/Logo";

/*temp*/
import OldList from './../old/application/list/list'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const FilterWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : props.width.value+props.width.unit };
  height: 100%;
  ${props => props.background ? "background: "+props.background+";" : ""};
`;
const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : props.width.value+props.width.unit };
  height: 100%;
  display: flex;
  flex-direction: column;
  ${props => props.background ? "background: "+props.background+";" : ""}
`;

const TopbarWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
const LogoWrapper = styled.div`
  width: 100%;
  height: ${ props => props.height.value+props.height.unit };
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
`;


function List() {
  const theme = useTheme()

  const config = useSelector(getConfig)
  const layout = useSelector(getLayout)


  let { lang, area, slug } = useParams()

  return (
    <Wrapper>
      <FilterWrapper layout={layout} width={theme.list.width} background={theme.filters.background}>
        {/*<Filter /> */}
      </FilterWrapper>
      <ContentWrapper layout={layout} width={theme.list.width} background={theme.list.background}>
        <TopbarWrapper>
          {layout === LAYOUT_XS || layout === LAYOUT_SM ?
            <MobileTopbar />
          :
            <>
              <Link to={"/"+lang}>
                <LogoWrapper height={theme.topbar.desktop.height}>
                  <Logo
                    variant={theme.list.logo.variant}
                    width={theme.list.logo.width}
                    height={theme.list.logo.height}
                  />
                </LogoWrapper>
              </Link>
            </>
          }
        </TopbarWrapper>
        <ListWrapper>
          <OldList layout={layout === LAYOUT_XS || layout === LAYOUT_SM ? LAYOUT_MOBILE : LAYOUT_DESKTOP} config={config} language={lang} area={area} slug={slug}/>
        </ListWrapper>
      </ContentWrapper>
    </Wrapper>

  );
}

export default React.memo(List)
