import { takeLatest, put, all, call } from 'redux-saga/effects'
// import { push } from 'connected-react-router'
import { login, check, logout, setFavorite, getFavorites } from '../actions/auth'
import auth0 from '../../data/network/auth'
// import i18n from '../../locales'

// import api from '../../data/network'
// import { HOME } from '../../navigation/routes'

function* loginSaga({ payload }) {
  // codice aync
  try {
    // const { config } = payload

    // effettua la chiamata di rete per il login
    const user = yield call([auth0, auth0.login])

    // manda la action success
    yield put(login.success(user))


    // manda l'utente in home
    // yield put(push(HOME))

  } catch(e) {
    console.log(e)

    // manda la action failure
    // yield put(login.failure(i18n.t("Username or password invalid")))
    yield put(login.failure("Failed"))
  }
}

function* checkSaga({ payload }) {
  try {
    // const { config } = payload
    const user = yield call([auth0, auth0.check])
    yield put(check.success(user))

    try {
      const favorites = yield call([auth0, auth0.getFavorites], user.accessToken)
      yield put(getFavorites.success(favorites))

    } catch(e) {
      console.log(e)
      // yield put(login.failure(i18n.t("Failed")))
      yield put(getFavorites.failure("Failed"))
    }
  } catch(e) {
    console.log(e)
    // yield put(login.failure(i18n.t("Failed")))
    yield put(check.failure("Failed"))
  }
}

function* logoutSaga({ payload }) {
  try {
    // const { config } = payload
    const user = yield call([auth0, auth0.logout])
    yield put(logout.success(user))
    // yield put(setFavorite.success(null))

  } catch(e) {
    console.log(e)
    // yield put(login.failure(i18n.t("Failed")))
    yield put(logout.failure("Failed"))
  }
}

function* setFavoriteSaga({ payload }) {
  try {
    const { idUser, area, idElement, isFavorite } = payload
    const newIsFavorite = yield call([auth0, auth0.setFavorite], idUser, area, idElement, isFavorite)
    yield put(setFavorite.success({"status": newIsFavorite, "area": area, "id": idElement}))

  } catch(e) {
    console.log(e)
    // yield put(login.failure(i18n.t("Failed")))
    yield put(setFavorite.failure("Failed"))
  }
}


export default function* root() {
  yield all([
      yield takeLatest([login.TRIGGER], loginSaga),
      yield takeLatest([check.TRIGGER], checkSaga),
      yield takeLatest([logout.TRIGGER], logoutSaga),
      yield takeLatest([setFavorite.TRIGGER], setFavoriteSaga),
  ])
}
