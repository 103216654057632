import React from 'react';
import styled from 'styled-components';
// import { useTheme } from './../../themes';

const Wrapper  = styled.a`
  color: #6F6F6F;
  font-weight: 300;
  font-size: 11px;
`;

const WebsiteLink = () => {
  // const theme = useTheme()
  return (
    <Wrapper href="#">
      Test.com
    </Wrapper>
  )
}

export default React.memo(WebsiteLink)
