import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Icon from './../../../../App/Icon.js';
import { withTheme } from "./../../../../../themes";

import FilterTag from "./filterTag";

import './filterTree.css';

class FilterTree extends Component {
   constructor(props){
      super(props);

      this.areaService = props.service;
      this.field = props.field;
      this.depthCallback = props.depthCallback;
      this.facetCallback = props.facetCallback;
      this.state = {
         depth: props.depth,
         name: props.name,
         area: props.area,
         translations: props.translations,
         currentFilters: props.currentFilters,
         facets: [],
         filterSelection: null,
         currentPath: "",
         facetSearch: ""
      };

      this.getFacets(props.depth, props.currentFilters, "", "");

   }

   componentWillReceiveProps(nextProps) {
      if(nextProps.depth !== this.state.depth) {
         this.setState({
            "facetSearch": ""
         });
         let currentPath = this.state.currentPath;
         if(nextProps.depth < this.state.depth){

            let arrPath = this.state.currentPath.split(" > ");
            arrPath.splice(-1, this.state.depth - nextProps.depth);

            currentPath = arrPath.join(" > ");
         }

         this.getFacets(nextProps.depth, this.state.currentFilters, currentPath, "");
      }
      // this.updateTags();
   }

   getFacets(depth, filters, currentPath, facetSearch){
      if(this.field !== "" && depth > 0){
         this.areaService.getFacets(this.field+".lvl"+(depth - 1), filters, currentPath, facetSearch).then(ret  => {
            if(ret.length > 0){
               this.setState({
                  facets: ret,
                  depth: depth,
                  currentPath: currentPath
               }, () => {
                  this.depthCallback(depth, this.state.name);
               });
            }else{
               // console.log("select");
            }
         }).catch(err => console.log('There was an error:' + err));
      }
   }

   toggleFacet(key, val){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(key)){
         if(currentFilters[key].indexOf(val)>-1){
            currentFilters[key].splice([currentFilters[key].indexOf(val)], 1);
            if(currentFilters[key].length === 0){
               delete currentFilters[key];
            }
         }else{
            currentFilters[key].push(val);
// console.log(val);
//             ReactGA.event({
//                category: `Search by`,
//                action: key,
//                label: val
//             });
         }
      }else{
         currentFilters[key] = [];
         currentFilters[key].push(val);

         // console.log(val);
         // ReactGA.event({
         //    category: `Search by`,
         //    action: key,
         //    label: val
         // });
      }

      this.setState({
         "currentFilters": currentFilters
      });
      this.facetCallback(currentFilters);




   }

   getTags(){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(this.state.name)){
         let values = JSON.parse(JSON.stringify(currentFilters[this.state.name]));

         for(var i = 0; i < values.length; i++){
            if(values[i].indexOf("|") >= 0){
               values[i] = values[i].substring(values[i].indexOf("|") + 1);
            }
         }

         return <FilterTag
            area={this.state.area}
            field={this.state.name}
            value={values.join(", ")}
            extended={false}
            currentFilters={this.state.currentFilters}
            tagCallback={this.tagCallback.bind(this)}
         />;
      }else{
         return null;
      }
   }

   tagCallback(currentFilters){
      this.props.tagCallback(currentFilters);
   }

   goDeeper(currentPath){
      var depth = this.state.depth;
      let goDeep = true;

      if(currentPath){
         // this.toggleFacet(/*this.field+".lvl"+(depth - 1)*/ this.state.name, currentPath);

         let key = this.state.name;
         let val = currentPath;

         let currentFilters = this.state.currentFilters;
         if(currentFilters.hasOwnProperty(key)){
            if(currentFilters[key].indexOf(val)>-1){
               currentFilters[key].splice([currentFilters[key].indexOf(val)], 1);
               if(currentFilters[key].length === 0){
                  delete currentFilters[key];
               }
               goDeep = false;
            }else{
               currentFilters[key].push(val);

               ReactGA.event({
                  category: `Search by`,
                  action: key,
                  label: val.substring(val.indexOf("|") + 1)
               });
            }
         }else{
            if(typeof val === 'string'){
               currentFilters[key] = [];
               currentFilters[key].push(val);
               ReactGA.event({
                  category: `Search by`,
                  action: key,
                  label: val.substring(val.indexOf("|") + 1)
               });
            }
         }

         this.setState({
            "currentFilters": currentFilters
         });
         this.facetCallback(currentFilters);

      }

      if(goDeep){
         depth = depth + 1;
         this.getFacets(depth, this.state.currentFilters, currentPath, "");
         this.setState({
            "facetSearch": ""
         });

      }
   }

   search(e){
      this.setState({
         "facetSearch": e.target.value
      },(()=>{
         this.getFacets(this.state.depth, this.state.currentFilters, this.state.currentPath, this.state.facetSearch);
      }));


      //analytics
      // if ( this.state.area == "companies") {
      //   area = "exhibitor";
      // } else if (this.state.area == "products") {
      //   area = "wine";
      // } else {
      //   area = "event"
      // }
      //
      // ReactGA.event({
      //    category: `Search`,
      //    action: area,
      //    label: e.target.value
      // });
   }

   render() {
      return (

               <div className={ this.state.depth === 0 || this.wrapped ? "" : "filter-wrapper"}>

                  { this.state.depth === 0 ?
                     <div className="filter-tree item-wrapper">
                        <div className="item-subwrapper" onClick={this.goDeeper.bind(this)}>
                           <div className="item">
                              <div className="text">{this.state.translations["filter-"+this.state.area]["_filter-label-"+this.state.name]}</div>
                              <div className="icon"></div>
                           </div>
                           {this.getTags()}
                        </div>
                     </div>
                  : this.wrapped ?
                     Object.keys(this.state.facets).map(key =>{
                        let facetText;
                        if(this.state.facets[key].value.indexOf(" > ") >= 0){
                           facetText = this.state.facets[key].value.substring(this.state.facets[key].value.indexOf(" > ") + 3);
                        }else{
                           facetText = this.state.facets[key].value;
                        }
                        if(facetText.indexOf("|") >= 0){
                           facetText = facetText.substring(facetText.indexOf("|") + 1);
                        }


                        return  <div className={"filter-tree item-wrapper item-leaf " + (this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ? "active" : "")} key={key} onClick={this.goDeeper.bind(this, this.state.facets[key].value)}>
                           <div className="item">
                              <div className="text">{facetText}</div>
                              {this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ?
                                <div className="checked">
                                  <Icon
                                    name={this.props.theme.icons.checked}
                                    size={{"value": 20, "unit": "px"}}
                                    color={this.props.theme.filters.icon.normal.color}
                                    background={this.props.theme.filters.icon.normal.background}
                                    hoverColor={this.props.theme.filters.icon.hover.color}
                                    hoverBackground={this.props.theme.filters.icon.hover.background}
                                  />
                                </div>
                              : null }
                              <div className="counter">{(this.state.facets[key].count)}</div>
                           </div>
                        </div>;
                     })

                  :
                     <div className="filter-subwrapper">
                        <div className="search-container">
                           <input className="search" type="text" placeholder={this.state.translations["UI"]["Search"]} onChange={this.search.bind(this)} value={this.state.facetSearch} />
                        </div>
                        <div className="items-container">
                           <div className="items-scroller">
                              {
                                 Object.keys(this.state.facets).map(key =>{
                                    let facetText;
                                    if(this.state.facets[key].value.lastIndexOf(" > ") >= 0){
                                       facetText = this.state.facets[key].value.substring(this.state.facets[key].value.lastIndexOf(" > ") + 3);
                                    }else{
                                       facetText = this.state.facets[key].value;
                                    }
                                    if(facetText.indexOf("|") >= 0){
                                       facetText = facetText.substring(facetText.indexOf("|") + 1);
                                    }


                                    return  <div className={"filter-tree item-wrapper item-leaf " + (this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ? "active" : "")} key={key} onClick={this.goDeeper.bind(this, this.state.facets[key].value)}>
                                       <div className="item">
                                          <div className="text">{facetText}</div>
                                          {this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ?
                                            <div className="checked">
                                              <Icon
                                                name={this.props.theme.icons.checked}
                                                size={{"value": 20, "unit": "px"}}
                                                color={this.props.theme.filters.icon.normal.color}
                                                background={this.props.theme.filters.icon.normal.background}
                                                hoverColor={this.props.theme.filters.icon.hover.color}
                                                hoverBackground={this.props.theme.filters.icon.hover.background}
                                              />
                                            </div>
                                          : null }
                                          <div className="counter">{(this.state.facets[key].count)}</div>
                                       </div>
                                    </div>;
                                 })
                              }
                           </div>
                        </div>
                     </div>
                  }


               </div>

      );
   }
}

export default withTheme(FilterTree);
