import {SAM} from "./tenants/SAM/config"
import {FIC} from "./tenants/FIC/config"
import {OVR} from "./tenants/OVR/config"
import {ASF} from "./tenants/ASF/config"

export const DOMAINS = {
   "localhost": OVR,

   "recat-sam-test.veronafiere.ozwol.tech": SAM,
   "catalogo.samoter.it": SAM,

   "recat-fic-test.veronafiere.ozwol.tech": FIC,
   "catalogo.fieracavalli.it": FIC,

   "recat-ovr-test.veronafiere.ozwol.tech": OVR,
   "catalogo.oilnonoil.it": OVR,

   "recat-asf-test.veronafiere.ozwol.tech": ASF,
   "catalogo.asphaltica.it": ASF,
};
