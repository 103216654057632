import React, { useCallback } from 'react';
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useTheme } from './../../themes';

import { getStatus } from './../../store/selectors/settings'
import { setStatus } from '../../store/actions/settings'

import Icon from "./../App/Icon";


const Wrapper  = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  cursor: pointer;
`;

// #list > .content > .header .toggle-filters.active{
//    background-color: #000000;
// }
// #list > .content > .header .toggle-filters.active .icon{
//    background-color: #ffffff;
// }

const ToggleFilters = ({isActive, iconColor, iconBackground, iconActiveColor, iconActiveBackground}) => {
  const theme = useTheme()

  const status = useSelector(getStatus)
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(setStatus({
      isFilterOpen: !status.isFilterOpen
    }), [dispatch])

  }, [dispatch, status.isFilterOpen]);

  return (
    <Wrapper onClick={onClick}>
      <Icon
        name={theme.icons.openFilters}
        size={{value: 36, unit: "px"}}
        color={isActive ? iconActiveColor : iconColor}
        background={isActive ? iconActiveBackground : iconBackground}
        wrapped={true}
      />
    </Wrapper>
  )
}

ToggleFilters.propTypes = {
  isActive: PropTypes.bool,
  iconColor: PropTypes.string.isRequired,
  iconBackground: PropTypes.string.isRequired,
  iconActiveColor: PropTypes.string.isRequired,
  iconActiveBackground: PropTypes.string.isRequired
}

export default React.memo(ToggleFilters)
