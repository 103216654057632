import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Icon from './../../../../App/Icon.js';
import { withTheme } from "./../../../../../themes";

import FilterTag from "./filterTag";

import './filterButton.css';

class FilterButton extends Component {
   constructor(props){
      super(props);

      this.areaService = props.service;
      this.field = props.field;
      this.facetCallback = props.facetCallback;
      this.state = {
         name: props.name,
         area: props.area,
         translations: props.translations,
         currentFilters: props.currentFilters,
         facet: {},
         filterSelection: null
      };

      this.getFacet(props.currentFilters);
   }


   componentWillReceiveProps(nextProps) {
      // console.log(nextProps);
      // console.log(this.state.currentFilters);
      // if(nextProps.currentFilters !== this.state.currentFilters) {
      //    console.log("entro");
         this.setState({
            "currentFilters": nextProps.currentFilters
         });
         this.getFacet(nextProps.currentFilters);
      // }
   }

   getFacet(filters){
      if(this.field !== ""){
         this.areaService.getFacets(this.field, filters, "", "").then(ret  => {
            this.setState({
               "facet": (ret[0] && ret[0].value === "true" ? ret[0] : ret[1])
            });
         }).catch(err => console.log('There was an error:' + err));
      }
   }

   toggleFacet(key, val){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(key)){
         if(currentFilters[key].indexOf(val)>-1){
            currentFilters[key].splice([currentFilters[key].indexOf(val)], 1);
            if(currentFilters[key].length === 0){
               delete currentFilters[key];
            }
         }else{
            currentFilters[key].push(val);

            ReactGA.event({
               category: `Search by`,
               action: key,
               label: val
            });
         }
      }else{
         currentFilters[key] = [];
         currentFilters[key].push(val);

         ReactGA.event({
            category: `Search by`,
            action: key,
            label: val
         });
      }

      this.setState({
         "currentFilters": currentFilters
      });
      this.facetCallback(currentFilters);
      this.getFacet(currentFilters);


   }

   getTags(){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(this.state.name)){
         let values = currentFilters[this.state.name];

         return <FilterTag
            area={this.state.area}
            field={this.state.name}
            value={values.join(", ")}
            extended={false}
            currentFilters={this.state.currentFilters}
            tagCallback={this.tagCallback.bind(this)}
         />;
      }else{
         return null;
      }
   }

   tagCallback(currentFilters){
      this.props.tagCallback(currentFilters);
   }

   render() {

      if(this.state.facet){

         return (

            <div
               className={"filter-button item-wrapper item-leaf " + (this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facet.value) ? "active" : "")}
               onClick={this.toggleFacet.bind(this, this.state.name, this.state.facet.value)}
            >
              <div className="item-subwrapper">
                 <div className="item">
                    <div className="text">{this.state.translations["filter-"+this.state.area]["_filter-label-"+this.state.name]}</div>
                    {this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facet.value) ?
                      <div className="checked">
                        <Icon
                          name={this.props.theme.icons.checked}
                          size={{"value": 20, "unit": "px"}}
                          color={this.props.theme.filters.icon.normal.color}
                          background={this.props.theme.filters.icon.normal.background}
                          hoverColor={this.props.theme.filters.icon.hover.color}
                          hoverBackground={this.props.theme.filters.icon.hover.background}
                        />
                      </div>
                    : null }
                    <div className="counter">{(this.state.facet.count)}</div>
                 </div>
              </div>
            </div>

         );
      }else{
         return null;
      }
   }
}

export default withTheme(FilterButton);
