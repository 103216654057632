import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Helmet} from 'react-helmet'
import styled from 'styled-components'
import { useTheme } from './../../themes'
import CookieBot from "react-cookiebot";

import { init, setLayout } from './../../store/actions/settings'
import { getConfig, getLayout } from './../../store/selectors/settings'
import { BREAKPOINT_XS, BREAKPOINT_SM, BREAKPOINT_MD, LAYOUT_XS, LAYOUT_SM, LAYOUT_MD, LAYOUT_LG } from './../../settings'

import Desktop from './../../components/Layout/Desktop'
import Mobile from './../../components/Layout/Mobile'

const Screen  = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${props => props.background ? "background: "+props.background+";" : ""}
  background-size: cover;
  background-position: center;

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
`;

function Catalogue() {
  const theme = useTheme()

  const config = useSelector(getConfig)
  const layout = useSelector(getLayout)
  const dispatch = useDispatch()

  dispatch(init(), [dispatch])
  if(!layout){
    dispatch(setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG))), [dispatch]);
  }

  React.useEffect(() => {
    function handleResize() {
      let newLayout = (window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
      if(layout !== newLayout)
        dispatch(setLayout(newLayout), [dispatch])
    }
    window.addEventListener('resize', handleResize)
    return _ => window.removeEventListener('resize', handleResize)
  })

  if(config){
    return (
      <>
        <Helmet>
           <title>{config.name}</title>
           <link rel="shortcut icon" href={"/themes/"+config.tenant+"/favicon.ico"} />
        </Helmet>

        {config.cookiebot ?
          <CookieBot domainGroupId={config.cookiebot} />
        : null }

        <div className={(layout === LAYOUT_XS || layout === LAYOUT_SM ? "isMobile" : "isDesktop") + " tenant-" + config.tenant + " skin-" + config.skin}>
          <Screen
            background={theme.background.global}
            textFont={theme.typography.text.textFont}
            textSize={theme.typography.text.textSize}
            textWeight={theme.typography.text.textWeight}
            textLineHeight={theme.typography.text.textLineHeight}
            textLetterSpacing={theme.typography.text.textLetterSpacing}
            textTransform={theme.typography.text.textTransform}
            textColor={theme.typography.text.textColor}
          >
            {layout === LAYOUT_XS || layout === LAYOUT_SM ?
              <Mobile />
            :
              <Desktop />
            }
          </Screen>
        </div>
      </>
    )
  }else{
    return (<></>);
  }
}

export default React.memo(Catalogue)
