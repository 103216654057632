import React, { Component } from 'react';
import {compose} from 'redux';
// import {connect} from 'react-redux';
import styled from 'styled-components';
import { withTheme } from "./../../../../../themes";
// import ReactGA from 'react-ga';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { SEPARATOR_MULTIFILTER } from './../../../../../settings'

import CloseFilters from "./../../../../Filters/CloseFilters"

import FilterList from "./filterList";
import FilterTree from "./filterTree";
import FilterButton from "./filterButton";
import FilterTag from "./filterTag";
import FilterRange from "./filterRange";
import FilterCalendar from "./filterCalendar";

import ConfirmFilters from "./../../../../Filters/ConfirmFilters"

import './filter.css';



const Wrapper  = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.background};
`;


class Filter extends Component {
   _isMounted = false;
   constructor(props){
      super(props);

      this.areaService = null;

      if(sessionStorage.getItem("filter") === null) {
         sessionStorage.setItem("filter", JSON.stringify({}));
      }

      this.areaService = props.areaService;

      this.state = {
         config: props.config,
         translations: props.translations,
         language: props.language,
         internalDepth: 0,
         area: props.area,
         filterStructure: {},
         currentSelections: JSON.parse(sessionStorage.getItem("filter")),
         currentGroup: null,
         elements: null,
         currentElement: null,
         facets: null,
         results: props.results,
         nCurrent: props.nCurrent,
         nTotal: props.nTotal,
         tags: []
      };
   }

   componentDidMount(){
      this._isMounted = true;
      this.areaService.initialize(this.state.config, this.state.language).then(ret  => {
         if(ret === true && this._isMounted) {
            this.areaService.getList(0,"","").then(ret  => {
               this.setState({
                  filterStructure: this.areaService.getFilterStructure(),
                  nTotal: ret.nCurrent
               }, () => {
                 this.updateTags();
               });
            }).catch(err => console.log('There was an error:' + err));

         }
      }).catch(err => console.log('There was an error:' + err));
   }

   componentDidUpdate(){
      disableBodyScroll(document.querySelector("#filter .items-container"));
   }

   componentWillUnmount() {
      clearAllBodyScrollLocks();
   }


   componentWillReceiveProps(nextProps) {
      if(nextProps.area !== this.state.area) {
         this.areaService = nextProps.areaService;
         sessionStorage.setItem("filter", JSON.stringify({}));
         this.setState({
            area: nextProps.area,
            results: nextProps.results,
            filterStructure: this.areaService.getFilterStructure(),
            currentSelections: JSON.parse(sessionStorage.getItem("filter")),
            internalDepth: 0,
            currentGroup: null,
            currentFilter: null,
            nCurrent: nextProps.nCurrent,
            nTotal: nextProps.nTotal
         }, () => {
           this.updateTags();
         });
      }else if(nextProps.nCurrent !== this.state.nCurrent) {
         this.areaService = nextProps.areaService;
         this.setState({
            area: nextProps.area,
            results: nextProps.results,
            filterStructure: this.areaService.getFilterStructure(),
            nCurrent: nextProps.nCurrent,
            nTotal: nextProps.nTotal
         }, () => {
           this.updateTags();
         });
      }
   }

   getDefaultElementKey(groupKey){
      var ret = null;
      Object.keys(this.state.filterStructure[groupKey]).forEach(elementKey => {
         if(this.state.filterStructure[groupKey][elementKey]["default"]){
            ret = elementKey;
            return;
         }
      })
      return ret;
   }

   goToGroup(groupKey){
      if(groupKey != null){
         let elementKey = this.getDefaultElementKey(groupKey);
         // let currentFacet = this.state.filterStructure[groupKey][elementKey]["field"];
         this.setState({
            "currentGroup": groupKey,
            "currentElement": elementKey
         });
         // this.areaService.getFacets(currentFacet).then(ret  => {
         //    this.setState({
         //       "currentGroup": groupKey,
         //       "currentElement": elementKey,
         //       "facets": ret
         //    });
         //
         // }).catch(err => console.log('There was an error:' + err));

      }else{
         this.setState({
            "currentGroup": null,
            "currentElement": null,
            "facets": null
         });
      }
   }

   goBack(){
      if(this.state.internalDepth === 1 && Object.keys(this.state.filterStructure[this.state.currentGroup]).length === 1){
         this.setState({
            "internalDepth": 0,
            "currentFilter": null,
            "currentGroup": null
         });
      }else{
         if(this.state.internalDepth > 0){
            var depth = this.state.internalDepth - 1;
            var currentFilter = this.state.currentFilter;
            if(depth === 0){
               currentFilter = null;
            }
            this.setState({
               "internalDepth": depth,
               "currentFilter": currentFilter
            });
         }else if(this.state.currentFilter != null){
            this.setState({
               "currentFilter": null
            });
         }else{
            this.setState({
               "currentGroup": null
            });
         }
      }
   }

   depthCallback(internalDepth, currentFilter){
      this.setState({
         "internalDepth": internalDepth,
         "currentFilter": currentFilter
      });
   }

   facetCallback(currentFilters){

      this.setState({
         "currentSelections": currentFilters
      }, () => {
        this.updateTags();
        sessionStorage.setItem("filter", JSON.stringify(currentFilters));
        this.props.setFiltersCallback();
      });


   }

   clearFilters(){
      sessionStorage.setItem("filter", "{}");

      this.props.clearFiltersCallback();

      this.setState({
         "currentFilter": null,
         "currentGroup": null,
         "currentElement": null,
         "facets": null,
         "currentSelections": {},
         "internalDepth": 0,
      },(()=>{
         // this.updateTags();


      }));

   }

   closeFilters(){
      this.props.closeFiltersCallback();
   }

   updateTags(groupKey){

      // let currentFilters = this.state.currentSelections;
      let tags = [];
      let groups = Object.keys(this.state.filterStructure);
      let keys = Object.keys(this.state.currentSelections);
      for(let iGroup = 0; iGroup < groups.length; iGroup++){
         let groupTags = [];
         let groupKey = groups[iGroup];
         for(let i = 0; i < keys.length; i++){
            let key = keys[i];
            if(Object.keys(this.state.filterStructure[groupKey]).includes(key)){
               let values = JSON.parse(JSON.stringify(this.state.currentSelections[key]));

               for(var j = 0; j < values.length; j++){
                  if(key === "Date"){
                     let date = new Date(values[j] * 1000);
                     values[j] = ('0' + date.getDate()).slice(-2) + "/" + ('0' + (date.getMonth()+1)).slice(-2) + "/" + date.getFullYear();
                  }
                  if(values[j].indexOf(SEPARATOR_MULTIFILTER) >= 0){
                     values[j] = values[j].substring(values[j].indexOf(SEPARATOR_MULTIFILTER) + 1);
                  }
                   values[j] = values[j].substring(values[j].indexOf("|") + 1);
               }

               groupTags[key] = values.join(", ");
            }
         }
         tags[groupKey] = groupTags;
      }

      this.setState({
         "tags": tags
      });
   }

   tagCallback(currentFilters){
      sessionStorage.setItem("filter", JSON.stringify(currentFilters));
      this.props.setFiltersCallback();
      // this.updateTags();
   }

   // getTags(groupKey){
   //    // console.log(groupKey);
   //    let currentFilters = this.state.currentSelections;
   //    let htmlTags;
   //    // console.log(this.state.currentSelections);
   //    let keys = Object.keys(this.state.currentSelections);
   //    for(let i = 0; i < keys.length; i++){
   //       let key = keys[i];
   //       // console.log(this.state.filterStructure);
   //       if(Object.keys(this.state.filterStructure[groupKey]).includes(key)){
   //          let values = this.state.currentSelections[key];
   //          htmlTags += <FilterTag
   //             area={this.state.area}
   //             field={key}
   //             value={values.join(", ")}
   //             extended="true"
   //          />;
   //       }
   //
   //    console.log(htmlTags);
   //    return (htmlTags);
   // }

   render() {
      return (
         <Wrapper id="filter"
          background={this.props.theme.filters.background}
          color={this.props.theme.filters.color}
         >
            <div className="panel">
               <div className="header">
                  <div className="main">
                     <div className="left">
                        {(this.state.currentGroup != null ?
                           <div className="back" onClick={this.goBack.bind(this, null)}></div>
                        :
                           ""
                        )}
                     </div>
                     <div className="center">
                        {this.state.translations["UI"]["_label-"+this.state.area] + (this.state.currentGroup != null ? " > " + this.state.translations["filter-"+this.state.area]["_group-" + this.state.currentGroup] : "") }
                     </div>
                     <div className="right">
                        {(Object.keys(this.state.currentSelections).length > 0 ?
                           <div className="clear-filters" onClick={this.clearFilters.bind(this)}></div>
                        :
                          <CloseFilters/>
                        )}

                     </div>
                  </div>
               </div>
               <div className="menu">

               </div>
               <div className="content">
                  {(this.state.currentGroup === null ?
                     <div className="filter-wrapper">
                        <div className="filter-subwrapper">
                           <div className="items-container">
                              <div className="items-scroller">
                                 {Object.keys(this.state.filterStructure).map(key =>(


                                       <div className="item-wrapper" key={key}>
                                          <div className="item-subwrapper">
                                             <div className="item" onClick={this.goToGroup.bind(this, key)} key={key}>
                                                <div className="text">{this.state.translations["filter-"+this.state.area]["_group-" + key]}</div>
                                                <div className="icon"></div>
                                             </div>
                                             {
                                                (this.state.tags[key] ?
                                                   Object.keys(this.state.tags[key]).map(tagKey =>(
                                                      <FilterTag
                                                         key={tagKey}
                                                         area={this.state.area}
                                                         translations={this.state.translations}
                                                         field={tagKey}
                                                         value={this.state.tags[key][tagKey]}
                                                         extended={true}
                                                         currentFilters={this.state.currentSelections}
                                                         tagCallback={this.tagCallback.bind(this)}
                                                      />
                                                   ))
                                                : "")
                                             }
                                          </div>
                                       </div>

                                 ))}
                              </div>
                           </div>
                        </div>
                     </div>
                  : this.state.internalDepth === 0 ?
                     <div className="filter-wrapper">
                        <div className="filter-subwrapper">
                           <div className="items-container">
                              <div className="items-scroller">
                                 {Object.keys(this.state.filterStructure[this.state.currentGroup]).map(filterKey =>(
                                    <div key={filterKey}>
                                       { this.state.filterStructure[this.state.currentGroup][filterKey]["type"] === "list" ?
                                          <FilterList
                                             wrapped={true}
                                             area={this.state.area}
                                             translations={this.state.translations}
                                             depth={(Object.keys(this.state.filterStructure[this.state.currentGroup]).length > 1 ? 0 : 1)}
                                             depthCallback={this.depthCallback.bind(this)}
                                             facetCallback={this.facetCallback.bind(this)}
                                             name={filterKey}
                                             field={this.state.filterStructure[this.state.currentGroup][filterKey]["field"]}
                                             currentFilters={this.state.currentSelections}
                                             service={this.areaService}
                                             tagCallback={this.tagCallback.bind(this)}
                                          ></FilterList>
                                       : this.state.filterStructure[this.state.currentGroup][filterKey]["type"] === "tree" ?
                                          <FilterTree
                                             wrapped={true}
                                             area={this.state.area}
                                             translations={this.state.translations}
                                             depth={(Object.keys(this.state.filterStructure[this.state.currentGroup]).length > 1 ? 0 : 1)}
                                             depthCallback={this.depthCallback.bind(this)}
                                             facetCallback={this.facetCallback.bind(this)}
                                             name={filterKey}
                                             field={this.state.filterStructure[this.state.currentGroup][filterKey]["field"]}
                                             currentFilters={this.state.currentSelections}
                                             service={this.areaService}
                                             tagCallback={this.tagCallback.bind(this)}
                                          ></FilterTree>
                                       : this.state.filterStructure[this.state.currentGroup][filterKey]["type"] === "range" ?
                                          <FilterRange
                                             wrapped={true}
                                             area={this.state.area}
                                             translations={this.state.translations}
                                             depth={(Object.keys(this.state.filterStructure[this.state.currentGroup]).length > 1 ? 0 : 1)}
                                             depthCallback={this.depthCallback.bind(this)}
                                             facetCallback={this.facetCallback.bind(this)}
                                             name={filterKey}
                                             field={this.state.filterStructure[this.state.currentGroup][filterKey]["field"]}
                                             currentFilters={this.state.currentSelections}
                                             service={this.areaService}
                                             tagCallback={this.tagCallback.bind(this)}
                                          ></FilterRange>
                                       : this.state.filterStructure[this.state.currentGroup][filterKey]["type"] === "button" ?
                                          <FilterButton
                                             wrapped={true}
                                             area={this.state.area}
                                             translations={this.state.translations}
                                             depth={(Object.keys(this.state.filterStructure[this.state.currentGroup]).length > 1 ? 0 : 1)}
                                             facetCallback={this.facetCallback.bind(this)}
                                             name={filterKey}
                                             field={this.state.filterStructure[this.state.currentGroup][filterKey]["field"]}
                                             currentFilters={this.state.currentSelections}
                                             service={this.areaService}
                                             tagCallback={this.tagCallback.bind(this)}

                                          ></FilterButton>
                                       : this.state.filterStructure[this.state.currentGroup][filterKey]["type"] === "calendar" ?
                                          <FilterCalendar
                                             wrapped={true}
                                             area={this.state.area}
                                             translations={this.state.translations}
                                             depth={(Object.keys(this.state.filterStructure[this.state.currentGroup]).length > 1 ? 0 : 1)}
                                             facetCallback={this.facetCallback.bind(this)}
                                             name={filterKey}
                                             field={this.state.filterStructure[this.state.currentGroup][filterKey]["field"]}
                                             currentFilters={this.state.currentSelections}
                                             service={this.areaService}
                                             tagCallback={this.tagCallback.bind(this)}

                                          ></FilterCalendar>
                                       :
                                          ""
                                       }
                                    </div>

                                 ))}
                              </div>
                           </div>
                        </div>
                     </div>

                  :
                     ( this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["type"] === "list" ?
                        <FilterList
                           wrapped={false}
                           area={this.state.area}
                           translations={this.state.translations}
                           depth={this.state.internalDepth}
                           depthCallback={this.depthCallback.bind(this)}
                           facetCallback={this.facetCallback.bind(this)}
                           name={this.state.currentFilter}
                           field={this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["field"]}
                           currentFilters={this.state.currentSelections}
                           service={this.areaService}
                           tagCallback={this.tagCallback.bind(this)}
                        ></FilterList>
                     : this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["type"] === "tree" ?
                        <FilterTree
                           wrapped={false}
                           area={this.state.area}
                           translations={this.state.translations}
                           depth={this.state.internalDepth}
                           depthCallback={this.depthCallback.bind(this)}
                           facetCallback={this.facetCallback.bind(this)}
                           name={this.state.currentFilter}
                           field={this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["field"]}
                           currentFilters={this.state.currentSelections}
                           service={this.areaService}
                           tagCallback={this.tagCallback.bind(this)}
                        ></FilterTree>
                     : this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["type"] === "range" ?
                        <FilterRange
                           wrapped={false}
                           area={this.state.area}
                           translations={this.state.translations}
                           depth={this.state.internalDepth}
                           depthCallback={this.depthCallback.bind(this)}
                           facetCallback={this.facetCallback.bind(this)}
                           name={this.state.currentFilter}
                           field={this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["field"]}
                           currentFilters={this.state.currentSelections}
                           service={this.areaService}
                           tagCallback={this.tagCallback.bind(this)}
                        ></FilterRange>
                      : this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["type"] === "button" ?
                         <FilterButton
                            wrapped={true}
                            area={this.state.area}
                            translations={this.state.translations}
                            depth={(Object.keys(this.state.filterStructure[this.state.currentGroup]).length > 1 ? 0 : 1)}
                            facetCallback={this.facetCallback.bind(this)}
                            name={this.state.currentFilter}
                            field={this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["field"]}
                            currentFilters={this.state.currentSelections}
                            service={this.areaService}
                            tagCallback={this.tagCallback.bind(this)}

                         ></FilterButton>
                      : this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["type"] === "calendar" ?
                        <FilterCalendar
                           wrapped={false}
                           area={this.state.area}
                           translations={this.state.translations}
                           depth={this.state.internalDepth}
                           depthCallback={this.depthCallback.bind(this)}
                           facetCallback={this.facetCallback.bind(this)}
                           name={this.state.currentFilter}
                           field={this.state.filterStructure[this.state.currentGroup][this.state.currentFilter]["field"]}
                           currentFilters={this.state.currentSelections}
                           service={this.areaService}
                           tagCallback={this.tagCallback.bind(this)}
                        ></FilterCalendar>
                     :
                        ""
                     )
                  )}



               </div>
               <div className="footer">
                 <ConfirmFilters current={this.state.nCurrent} total={this.state.nTotal} text={this.state.translations["UI"]["Show results"]} />
               </div>
            </div>
         </Wrapper>
      );
   }
}


export default compose(
  withTheme
)(Filter);
