import { takeLatest, put, all, call } from 'redux-saga/effects'
import configuration from '../../data/tenants/configuration'
import { init } from '../actions/settings'

function* settingsSaga({ payload }) {
  try {
    // const { config } = payload
    const config = yield call([configuration, configuration.init])
    yield put(init.success(config))
  } catch(e) {
    console.log(e)
    yield put(init.failure("Failed"))
  }
}



export default function* root() {
  yield all([
    yield takeLatest([init.TRIGGER], settingsSaga),
  ])
}
