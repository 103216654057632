// import axios from "axios";
// import AuthService from "./../auth/authService";
import i18n from 'i18next';
import he from 'he';
import algoliasearch from 'algoliasearch'
import CompaniesServiceForProducts from "./../../../old/entities/companies/companiesService";
import { SEPARATOR_ORDERVALUE } from './../../../../settings'
import {DOMAINS} from "./../../../../domains"

var config;
var language;
var client;
var index;
var filterStructure = {};
var isInitialized = false;


export default class ProductsService{
   initialize(c, l){
      return new Promise((resolve, reject) => {
         config = c;
         language = l;
         client = algoliasearch(DOMAINS[window.location.hostname].algolia.id, DOMAINS[window.location.hostname].algolia.key);
         if(config.algolia_index_products){
            isInitialized = true;
            index = client.initIndex(DOMAINS[window.location.hostname].algolia.index_products);
            filterStructure = config.filter_products;
            resolve(true);
         }else{
            resolve(false);
         }
      });
   }

   getFilterStructure(){
      if(isInitialized){
         return filterStructure;
      }else{
         return {};
      }
   }

   composeFilter(filter){
      let filterstring = "";

      for (var item in filter) {
         if(filterstring !== ""){
            filterstring += " AND ";
         }

         for(let i = 0; i < filter[item].length; i++){
            if(this.getType(item) === "range"){
               if(i === 0 ){
                  filterstring += "(";
               }
               if(i > 0 && filterstring !== ""){
                  filterstring += " OR ";
               }

               filterstring += this.getField(item).replace("LANG", i18n.language)+": \""+filter[item][i]+"\"";

               if(i === filter[item].length - 1){
                  filterstring += ")";
               }
            }else{
               if(i > 0 && filterstring !== ""){
                  filterstring += " AND ";
               }
               let level = null;
               if((filter[item][i].match(/ > /g) || []).length > 0){
                  level = (filter[item][i].match(/ > /g) || []).length;
               }else if(this.getType(item) === "tree"){
                  level = 0;
               }
               filterstring += this.getField(item,level).replace("LANG", i18n.language)+": \""+filter[item][i]+"\"";
            }
         }

      }
      return filterstring;
   }

   getList(page, search, filter){
      if(isInitialized){
         var that = this;
         return new Promise((resolve, reject) => {
            let filterstring = this.composeFilter(filter);

            // console.log("filterstring: "+ filterstring);
            index.search(search, {
                  filters: filterstring,
                  hitsPerPage: "20",
                  page: page
               },
               function searchDone(err, content) {
                  if (err) throw err;
                  let elements = [];
                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);
                     elements.push(element);
                  }
                  let state = null;
                  if(content.page === content.nbPages-1){
                     state = 0; //no more results
                  }else if(content.nbPages === 0){
                     state = -1; //no results
                  }else{
                     state = 1; //loading
                  }
                  resolve({"dataResult": elements, "loadingState": state, "nCurrent": content.nbHits});
               }
            );
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   getListByCompany(id){
      if(isInitialized){
         let that = this;
         return new Promise((resolve, reject) => {
            index.search("", {
                  filters: "CompanyId:"+id,
                  hitsPerPage: 9999,
                  page: 0
               },
               function searchDone(err, content) {
                  if (err) throw err;
                  let elements = [];

                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);
                     elements.push(element);
                  }
                  resolve(elements);
               }
            );
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve([]);
         });
      }
   }


   /*single*/
   getData(val, by = "id"){
      if(isInitialized){
         let that = this;
         return new Promise((resolve, reject) => {
            let field = "";
            switch(by){
               case "id":
                  field = "objectID";
                  break;
               case "slug":
                  field = "objectID";
                  break;
               default:
                  break;
            }

            index.search("", {
                  filters: field+":"+val,
                  hitsPerPage: 1,
                  page: 0
               },
               function searchDone(err, content) {
                  if (err) throw err;

                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);

                     let companiesServ = new CompaniesServiceForProducts();
                     companiesServ.getData(content.hits[i].CompanyId).then(companyData => {
                        element.companyData = companyData;
                        element.others = companyData.products;
                        resolve(element);
                     }).catch(err => console.log('There was an error:' + err));
                  }
               }
            );
         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   /*search*/
   getFacets(field, filters, query, search){
      if(isInitialized){
         let val = field.replace("LANG", i18n.language);
         return new Promise((resolve, reject) => {
            let filterstring = this.composeFilter(filters);
               index.searchForFacetValues(search, {
                     facetName: val,
                     filters: filterstring,
                     maxFacetHits: 100
                  },
                  function searchDone(err, content) {
                     if (err) throw err;
                     let arrFacets = content.facetHits;
                     switch(field){
                        case "DesignationHierarchy.lvl0":
                        case "CategoryHierarchy.lvl0":
                           arrFacets.sort(function(a, b) {
                              if(a.value < b.value){
                                 return -1;
                              }else if(a.value > b.value){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;

                        case "DesignationHierarchy.lvl1":
                        case "CategoryHierarchy.lvl1":

                           for (var i=arrFacets.length-1; i>=0; i--){
                              if(arrFacets[i].value.substring(0, query.length) !== query){
                                 arrFacets.splice(i, 1);
                              }
                           }
                           arrFacets.sort(function(a, b) {
                              if(a.value < b.value){
                                 return -1;
                              }else if(a.value > b.value){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;
                        case "PriceRange.LANG":
                           arrFacets.sort(function(a, b) {
                              let aVal = parseInt(a.value.match(/\d+/)[0], 10);
                              let bVal = parseInt(b.value.match(/\d+/)[0], 10);
                              if(aVal < bVal){
                                 return -1;
                              }else if(aVal > bVal){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;
                        default:
                           arrFacets.sort(function(a, b) {
                              if(a.value < b.value){
                                 return -1;
                              }else if(a.value > b.value){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;
                     }
                     resolve(arrFacets);
                  }
               );
            // }


         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   /*filter*/
   getField(key, level = null){
      var ret = null;
      Object.keys(filterStructure).forEach(filter => {
         if(filterStructure[filter].hasOwnProperty(key)){
            ret = filterStructure[filter][key]["field"];
            return;
         }
      })

      if(level != null){
         ret = ret + ".lvl"+level;
      }

      return ret;
   }
   getType(key){
      var ret = null;
      Object.keys(filterStructure).forEach(filter => {
         if(filterStructure[filter].hasOwnProperty(key)){
            ret = filterStructure[filter][key]["type"];
            return;
         }
      })

      return ret;
   }

   formatData(hit){
      let element = {}
      element.highlighted = {};

      /*slug*/
      element.slug = hit.objectID;

      /*type*/
      element.type = (hit.SheetType ? hit.SheetType : "default"); //"wine"

      /*name & pagetitle*/
      if(hit.Name !== undefined &&
         hit.Name[language] !== undefined &&
         hit.Name[language] !== null
      ){
         element.name = he.decode(hit.Name[language]);
         element.pagetitle = element.name;
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.Name !== undefined &&
         hit._highlightResult.Name[language] !== undefined &&
         hit._highlightResult.Name[language].value !== undefined &&
         hit._highlightResult.Name[language].value !== null
      ){
         element.highlighted.name = he.decode(hit._highlightResult.Name[language].value);
      }else {
         element.highlighted.name = element.name;
      }

if(false){
      let vintage = null;
      if(hit.Vintage !== undefined &&
         hit.Vintage !== null
      ){
         vintage = he.decode(hit.Vintage);
         element.name += " " + vintage;
         element.pagetitle += " " + vintage;
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.Vintage !== undefined &&
         hit._highlightResult.Vintage.value !== undefined &&
         hit._highlightResult.Vintage.value !== null
      ){
         element.highlighted.name += " " + he.decode(hit._highlightResult.Vintage.value);
      }else {
         if(vintage){
            element.highlighted.name += " " + vintage;
         }
      }

      /*brand*/
      if(hit.Brand !== undefined &&
         hit.Brand !== null
      ){
         element.brand = he.decode(hit.Brand);
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.Brand !== undefined &&
         hit._highlightResult.Brand.value !== undefined &&
         hit._highlightResult.Brand.value !== null
      ){
         element.highlighted.brand = he.decode(hit._highlightResult.Brand.value);
      }else {
         element.highlighted.brand = element.brand;
      }



      /*category*/
      if(hit.Category !== undefined &&
         hit.Category[i18n.language] !== undefined &&
         hit.Category[i18n.language].lvl0 !== undefined &&
         hit.Category[i18n.language].lvl0.length > 0
      ){
         let categorylvl0 = he.decode(hit.Category[i18n.language].lvl0[0]);
         element.category = categorylvl0.substring(categorylvl0.indexOf(SEPARATOR_ORDERVALUE) + 1);
         if(hit.Category[i18n.language].lvl1 !== undefined &&
            hit.Category[i18n.language].lvl1.length > 0
         ){
            let categorylvl1 = he.decode(hit.Category[i18n.language].lvl1[0]);
            categorylvl1 = categorylvl1.replace(categorylvl0 + " > ", "");
            element.category += " - " + categorylvl1;

         }
      }

      if(hit.GlassIcon !== undefined &&
         hit.GlassIcon !== null
      ){
         element.glassIcon = hit.UrlCDN + "/icons/" + hit.GlassIcon;
      }else{
         element.glassIcon = process.env.PUBLIC_URL + "/assets/icons/glasses/void.png";
      }



      /*designation*/
      if(hit.Designation !== undefined &&
         hit.Designation[i18n.language] !== undefined &&
         hit.Designation[i18n.language].lvl0 !== undefined &&
         hit.Designation[i18n.language].lvl0.length > 0
      ){
         let designationlvl0 = he.decode(hit.Designation[i18n.language].lvl0[0]);
         element.designation = designationlvl0.substring(designationlvl0.indexOf(SEPARATOR_ORDERVALUE) + 1);
         if(hit.Designation[i18n.language].lvl1 !== undefined &&
            hit.Designation[i18n.language].lvl1.length > 0
         ){
            let designationlvl1 = he.decode(hit.Designation[i18n.language].lvl1[0]);
            designationlvl1 = designationlvl1.replace(designationlvl0 + " > ", "");
            element.designation += " - " + designationlvl1;
         }
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.Designation !== undefined &&
         hit._highlightResult.Designation[i18n.language] !== undefined &&
         hit._highlightResult.Designation[i18n.language].lvl0 !== undefined &&
         hit._highlightResult.Designation[i18n.language].lvl0.length > 0
      ){
         let designationlvl0 = he.decode(hit._highlightResult.Designation[i18n.language].lvl0[0].value);
         element.highlighted.designation = designationlvl0.substring(designationlvl0.indexOf(SEPARATOR_ORDERVALUE) + 1);
         if(hit._highlightResult.Designation[i18n.language].lvl1 !== undefined &&
            hit._highlightResult.Designation[i18n.language].lvl1.length > 0
         ){
            let designationlvl1 = he.decode(hit._highlightResult.Designation[i18n.language].lvl1[0].value);
            designationlvl1 = designationlvl1.replace(designationlvl0 + " > ", "");
            element.highlighted.designation += " - " + designationlvl1;
         }
      }else {
         element.highlighted.designation = element.designation;
      }



      /*year*/
      if(hit.Year !== undefined &&
         hit.Year !== null
      ){
         element.year = hit.Year;
      }

      /*gradation*/
      if(hit.AlcoholicGradation !== undefined &&
         hit.AlcoholicGradation !== null
      ){
         element.gradation = hit.AlcoholicGradation;
      }

      /*indications*/
      if(hit.IsBiologic !== undefined &&
         hit.IsBiologic !== null &&
         hit.IsBiologic === true
      ){
         element.isBiologic = true;
      }else{
         element.isBiologic = false;
      }
      if(hit.IsBiodynamic !== undefined &&
         hit.IsBiodynamic !== null &&
         hit.IsBiodynamic === true
      ){
         element.isBiodynamic = true;
      }else{
         element.isBiodynamic = false;
      }
      if(hit.IsKosher !== undefined &&
         hit.IsKosher !== null &&
         hit.IsKosher === true
      ){
         element.isKosher = true;
      }else{
         element.isKosher = false;
      }

      /*color*/
      if(hit.Color !== undefined &&
         hit.Color !== null &&
         hit.Color.Name !== undefined &&
         hit.Color.Name !== null &&
         hit.Color.Name[i18n.language] !== undefined &&
         hit.Color.Name[i18n.language] !== null
      ){
         element.color = he.decode(hit.Color.Name[i18n.language]);
         element.colorImage = hit.UrlCDN + "/icons/" + hit.Color.Icon;
      }

      /*Tastes*/
      if(hit.FlavourAndTaste !== undefined &&
         hit.FlavourAndTaste !== null &&
         hit.FlavourAndTaste.length > 0
      ){
         element.tastes = [];
         hit.FlavourAndTaste.forEach(function(taste) {
            if(taste.Name !== undefined &&
               taste.Name !== null &&
               taste.Name[i18n.language] !== undefined &&
               taste.Name[i18n.language] !== null){
               let objTaste = {};
               objTaste.name = he.decode(taste.Name[i18n.language]);
               objTaste.icon = hit.UrlCDN + "/icons/" + taste.Icon;
               element.tastes.push(objTaste);
            }
         });
      }

      /*Matchings*/
      if(hit.BestWith !== undefined &&
         hit.BestWith !== null &&
         hit.BestWith.length > 0
      ){
         element.matchings = [];
         hit.BestWith.forEach(function(matching) {
            if(matching.Name !== undefined &&
               matching.Name !== null &&
               matching.Name[i18n.language] !== undefined &&
               matching.Name[i18n.language] !== null){
               let objMatching = {};
               objMatching.name = he.decode(matching.Name[i18n.language]);
               objMatching.icon = hit.UrlCDN + "/icons/" + matching.Icon;
               element.matchings.push(objMatching);
            }
         });
      }

      /*Vineyards*/
      if(hit.WineTrees !== undefined &&
         hit.WineTrees !== null &&
         hit.WineTrees.length > 0
      ){
         element.vineyards = [];
         let opacity = 255;
         let count = hit.WineTrees.length;
         hit.WineTrees.forEach(function(vineyard) {
            if(vineyard.Name !== undefined &&
               vineyard.Name !== null){
               let objVineyard = {};
               objVineyard.name = he.decode(vineyard.Name);
               objVineyard.percent = vineyard.Percentual;
               element.vineyards.push(objVineyard);
            }
         });
         element.vineyards.sort(function(a, b) {
            if(a.percent < b.percent){
               return 1;
            }else if(a.percent > b.percent){
               return -1;
            }else{
               return 0;
            }
         });
         element.vineyards.forEach(function(vineyard, i) {
            vineyard.percent = vineyard.percent + "%";
            opacity = Math.round(255 - (255 / (count + 1) * i));
            vineyard.color = "#63285b" + opacity.toString(16);
         });
      }

}
      /*image*/
      if(hit.ImageId !== undefined &&
         hit.ImageId !== null
      ){
         element.imageThumb = hit.UrlCDN + "/product_thumb/" +  hit.ImageId;
         element.imageCover = hit.UrlCDN + "/product/" +  hit.ImageId;
      }else{
         element.imageThumb = process.env.PUBLIC_URL + "/assets/placeholders/wine-thumb.png";
         element.imageCover = process.env.PUBLIC_URL + "/assets/placeholders/wine-thumb.png";
      }


      return element;
   }

}
