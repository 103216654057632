import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getLayout } from './../../store/selectors/settings'
import { LAYOUT_XS, LAYOUT_SM } from './../../settings'

import MobileTopbar from "./../../components/Layout/MobileTopbar"
import DesktopTopbar from "./../../components/Layout/DesktopTopbar"

// import Favorites from "./../../components/Home/Favorites"

import { DOMAINS } from "./../../domains"

import { SamHomepage } from "./../../tenants/exports";
import { FicHomepage } from "./../../tenants/exports";
import { OvrHomepage } from "./../../tenants/exports";
import { AsfHomepage } from "./../../tenants/exports";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
const TopbarWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
const HomeWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
`;

function Home() {
  const layout = useSelector(getLayout)

  return (
    <Wrapper>
      <TopbarWrapper>
        {layout === LAYOUT_XS || layout === LAYOUT_SM ?
          <MobileTopbar />
        :
          <DesktopTopbar />
        }
      </TopbarWrapper>
      <HomeWrapper>
        {(() => {
          switch (DOMAINS[window.location.hostname].tenant) {
            case "SAM":
              return <SamHomepage />;
            case "FIC":
              return <FicHomepage />;
            case "OVR":
              return <OvrHomepage />;
            case "ASF":
              return <AsfHomepage />;
            default:
              return <></>;
          }
        })()}
      </HomeWrapper>
    </Wrapper>
  );
}

export default React.memo(Home)
