import React from 'react'
import { useSelector } from 'react-redux'
// import styled from 'styled-components'
import { useParams } from 'react-router-dom';
// import { useTheme } from './../../themes';
import { getLayout } from './../../store/selectors/settings'

import { DOMAINS } from "./../../domains"
import { LAYOUT_XS, LAYOUT_SM } from './../../settings'
import { LAYOUT_DESKTOP, LAYOUT_MOBILE } from './../../settings'


import OldPage from './../../components/old/application/page/page'

function SamCompanySheet() {
  const layout = useSelector(getLayout)


  let { lang, area, slug } = useParams()

  return (
    <div>
      <OldPage layout={layout === LAYOUT_XS || layout === LAYOUT_SM ? LAYOUT_MOBILE : LAYOUT_DESKTOP} config={DOMAINS[window.location.hostname]} language={lang} area={area} slug={slug}/>
    </div>
  )
}

export default React.memo(SamCompanySheet)
