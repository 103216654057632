import React, { Component } from 'react';
import { i18n } from 'react-i18next';
import he from 'he';

// import WineryThumb from "./../../../../old/entities/companies/sub/winery/wineryThumb";

import SheetFullCover from "./../../../../../old/application/sheet/sheetFullCover";

//class
import './wineSheet.css'


export default class WineSheet extends Component {
   constructor(props) {
      super(props)

      this.state = {
         config: props.config,
         translations: props.translations,
         language: props.language,
         element: props.data
      };
   }

   componentWillReceiveProps(nextProps) {
      if(nextProps.data !== this.state.element){
         this.setState({element: nextProps.data});
      }
   }



   render() {
      if(this.state.element != null){
         return (
            <i18n ns="translations">
               {(t, { i18n }) => (
                  <div id="wine-sheet">
                     <SheetFullCover
                        coverImage={this.state.element.imageCover}
                        withBlur={false}
                        logoImage={this.state.element.logo}
                        title1={this.state.element.name}
                        title2={this.state.element.companyData.name}
                        title3={this.state.element.designation}
                        title4={this.state.element.brand}
                     />


                     <div className="cover">
                        <img src={ this.state.element.imageCover } alt="" />
                     </div>
                     <div className="content">
                        <div className="company">
                           <h2>{this.state.element.companyData.name}</h2>
                        </div>
                        <div className="intro">
                           <h3>{ this.state.element.brand }</h3>
                           <h1>{ this.state.element.name }</h1>
                           {this.state.element.designation ?
                              <p>{ this.state.element.designation }</p>
                           :
                              ""
                           }
                           {/* <ul>
                              <li>biologico</li>
                              <li>biodinamico</li>
                              <li>kosher</li>
                           </ul> */}

                        </div>

                        <div className="iconic">
                           <div className="title">
                              {t("Product data")}
                           </div>
                           <div className="content">
                              {this.state.element.category ?
                                 <div className="element">
                                    <div className="icon"><img src={ this.state.element.glassIcon } alt={t("iconic.Category")} /></div>
                                    <div className="name">{t("wine.icons.Category")}</div>
                                    <div className="value">{ this.state.element.category }</div>
                                 </div>
                              : "" }
                              {this.state.element.year ?
                                 <div className="element">
                                    <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/vintage.svg"} alt={t("wine.icons.Vintage")} /></div>
                                    <div className="name">{t("wine.icons.Vintage")}</div>
                                    <div className="value">{ this.state.element.year }</div>
                                 </div>
                              : "" }
                              {this.state.element.gradation ?
                                 <div className="element">
                                    <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/gradation.svg"} alt={t("wine.icons.Gradation")} /></div>
                                    <div className="name">{t("wine.icons.Gradation")}</div>
                                    <div className="value">{ this.state.element.gradation }</div>
                                 </div>
                              : "" }
                              {this.state.element.isBiologic ?
                                 <div className="element">
                                    <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/biologic.svg"} alt={t("products.filter.Biologic")} /></div>
                                    <div className="name">{t("products.filter.Biologic")}</div>
                                 </div>
                              : "" }
                              {this.state.element.isBiodynamic ?
                                 <div className="element">
                                    <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/biodynamic.svg"} alt={t("products.filter.Biodynamic")} /></div>
                                    <div className="name">{t("products.filter.Biodynamic")}</div>
                                 </div>
                              : "" }
                              {this.state.element.isKosher ?
                                 <div className="element">
                                    <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/kosher.svg"} alt={t("products.filter.Kosher")} /></div>
                                    <div className="name">{t("products.filter.Kosher")}</div>
                                 </div>
                              : "" }
                           </div>
                        </div>

                        { this.state.element.color ?
                           <div className="iconic">
                              <div className="title">
                                 {t("Color")}
                              </div>
                              <div className="content">
                                 <div className="element">
                                    <div className="icon icon-border"><img src={ this.state.element.colorImage } alt={ this.state.element.color } /></div>
                                    <div className="name">{ this.state.element.color }</div>
                                 </div>
                              </div>
                           </div>
                        : "" }

                        { this.state.element.tastes ?
                           <div className="iconic">
                              <div className="title">
                                 {t("Flavour and tastes")}
                              </div>
                              <div className="content">
                                 {this.state.element.tastes.map((taste, index) =>(
                                    <div key={ index } className="element">
                                       <div className="icon"><img src={ taste.icon } alt={ taste.name } /></div>
                                       <div className="name">{ taste.name }</div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        : "" }

                        { this.state.element.matchings ?
                           <div className="iconic">
                              <div className="title">
                                 {t("Best with")}
                              </div>
                              <div className="content">
                                 {this.state.element.matchings.map((taste, index) =>(
                                    <div key={ index } className="element">
                                       <div className="icon"><img src={ taste.icon } alt={ taste.name } /></div>
                                       <div className="name">{ taste.name }</div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        : "" }

                        { this.state.element.vineyards ?
                           <div className="iconic">
                              <div className="title">
                                 {t("Vineyars")}
                              </div>
                              <div className="content">
                                 {this.state.element.vineyards.map((vineyard, index) =>(
                                    <div key={ index } className="element">
                                       <div className="icon"><div className="icon-color" style={{backgroundColor: vineyard.color }}></div></div>
                                       <div className="name">{ vineyard.name }</div>
                                       <div className="value">{ vineyard.percent }</div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        : "" }

                        {this.state.element.Awards ?
                           <div className="iconic">
                              <div className="title">
                                 {t("Awards")}
                              </div>
                              <div className="content">
                                 {this.state.element.Awards.map((item, index) =>(
                                    <div className="element" key={index}>
                                       <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/award.svg"} alt={t("Awards")} /></div>
                                       <div className="name">{he.decode(item.Name)}</div>
                                    </div>
                                 ))}
                              </div>
                           </div>
                        : "" }

                        {this.state.element.PriceRange && this.state.element.PriceRange[i18n.language] !== "" ?
                           <div className="iconic">
                              <div className="title">
                                 {t("Price range in wine shop")}
                              </div>
                              <div className="content">
                                 <div className="element">
                                    <div className="icon"><img src={process.env.PUBLIC_URL + "/assets/icons/iconicdata/pricerange.svg"} alt={t("Price range in wine shop")} /></div>
                                    <div className="name">{he.decode(this.state.element.PriceRange[i18n.language])}</div>
                                 </div>
                              </div>
                           </div>
                        : "" }

                        <div className="">
                           <div className="title">
                              {t("More company info")}
                           </div>
                           <div className="content">
                              {/*<WineryThumb
                                 data={this.state.element.companyData}
                                 config={this.state.config}
                                 translations={this.state.translations}
                                 language={this.state.language}
                              />*/}
                           </div>
                        </div>
                     </div>
                  </div>

              )}
           </i18n>
           );
        }else{
        return (<div className=""></div>);
    }
  }
}
