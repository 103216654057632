import React from 'react';
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from './../../themes';

import Icon from "./../App/Icon";


const Wrapper  = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const ListButton = ({
  size,
  color,
  background
}) => {
  const theme = useTheme()
  let { lang, area } = useParams()

  return (
    <Link to={"/"+lang+"/"+(area ? area : "companies")}>
      <Wrapper>
        <Icon
          name={theme.icons.list}
          size={size}
          color={color}
          background={background}
        />
      </Wrapper>
    </Link>
  )
}

ListButton.propTypes = {
  size: PropTypes.object,
  color: PropTypes.string,
  background: PropTypes.string
}

export default React.memo(ListButton)
