import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './productThumb.sass';



export default class ProductThumb extends Component {
   constructor(props){
      super(props);

      this.state = {
         element: props.data,
         config: props.config,
         translations: props.translations,
         language: props.language
      };

   }

   componentWillReceiveProps(nextProps) {
      if(nextProps.data !== this.state.data){
         this.setState({
            element: nextProps.data
         });
      }
   }


   render() {
      return (
         <div className="product-thumb thumb">
            <div className="cover">
               <img src={ this.state.element.imageThumb } alt="" />
            </div>
            <div className="content">
               <h1>{ this.state.element.name }</h1>
               { this.state.config.enable_products_area ?
                  <Link to={ "/" + this.state.language + "/products/" + this.state.element.slug } className="btn btn-default btn-100">{this.state.translations["UI"]["See details"]}</Link>
               : "" }
            </div>
         </div>
      );
   }
}
