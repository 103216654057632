import React, { useCallback } from 'react';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useTheme } from './../../themes';

import { setStatus } from '../../store/actions/settings'

import Button from "./../App/Button"

const Text  = styled.div`
  font-weight: 700;
  font-size: 18px;
`;
const Counters  = styled.div`
  font-size: 12px;
`;

const CloseFilters = ({
  current, //temp
  total, //temp
  text //temp
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const onClick = useCallback(() => {
    dispatch(setStatus({
      isFilterOpen: false
    }), [dispatch])

  }, [dispatch]);

  return (
    <Button onClick={onClick} variant={theme.filters.buttonVariant}>
      <Text>{text}</Text>
      <Counters>{current + " / " + total}</Counters>
    </Button>
  )
}

CloseFilters.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  text: PropTypes.string
}

export default React.memo(CloseFilters)
