import React, { Component } from 'react';
import { Link } from 'react-router-dom'

//analytics
// import ReactGA from 'react-ga';

import './areaTabs.css';


export default class AreaTabs extends Component {
   constructor(props){
      super(props);
      this.state = {
         config: props.config,
         translations: props.translations,
         language: props.language,
         area: props.area
      };
   }

   componentDidUpdate(prevProps, prevState){
      if(this.props.area !== this.state.area){
         this.setState({area: this.props.area});
         //   ReactGA.event({
         //      category: 'Switch area',
         //      action: "Bar",
         //      label: this.props.area
         //   });
      }
   }

   render() {
      if(this.state.translations){
         return (
            <div id="areatabs">
               <Link to={"/"+this.state.language+"/companies/"} className={"area " + (this.state.area === "companies" ? "active" : "")}>
                  <div className="icon">
                     <img src={process.env.PUBLIC_URL + "/themes/" + this.state.config.tenant+"/assets/entities/company.svg"} alt="" />
                  </div>
                  <div className="label">{this.state.translations["UI"]["_label-companies"]}</div>
               </Link>
               {this.state.config.enable_products_area ?
                  <Link to={"/"+this.state.language+"/products/"} className={"area " + (this.state.area === "products" ? "active" : "")}>
                     <div className="icon">
                        <img src={process.env.PUBLIC_URL + "/themes/" + this.state.config.tenant+"/assets/entities/product.svg"} alt="" />
                     </div>
                     <div className="label">{this.state.translations["UI"]["_label-products"]}</div>
                  </Link>
               : ""}
               {this.state.config.enable_projects_area ?
                  <Link to={"/"+this.state.language+"/projects/"} className={"area " + (this.state.area === "projects" ? "active" : "")}>
                     <div className="icon">
                        <img src={process.env.PUBLIC_URL + "/themes/" + this.state.config.tenant+"/assets/entities/project.svg"} alt="" />
                     </div>
                     <div className="label">{this.state.translations["UI"]["_label-projects"]}</div>
                  </Link>
               : "" }
               {this.state.config.enable_events_area ?
                  <Link to={"/"+this.state.language+"/events/"} className={"area " + (this.state.area === "events" ? "active" : "")}>
                     <div className="icon">
                        <img src={process.env.PUBLIC_URL + "/themes/" + this.state.config.tenant+"/assets/entities/event.svg"} alt="" />
                     </div>
                     <div className="label">{this.state.translations["UI"]["_label-events"]}</div>
                  </Link>
               : "" }
            </div>
         );
      }else{
         return null;
      }
   }
}
