// TODO: Gestione height


import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useTheme } from './../../themes';

const Wrapper  = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: content-box;
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border-bottom: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor };
  color: ${props => props.isActive ? props.textColorActive : props.textColor};
  background: ${props => props.isActive ? props.backgroundActive : props.background};

  ${props => !props.isActive ? `
    &:hover{
      color: ${props => props.textColorHover};
      background: ${props => props.backgroundHover};
    }
  ` : ""};
`;

const Left = styled.div`
  flex-basis: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
  margin-right: 10px;
  height: 30px;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  color: ${props => props.color};
`;
const Right = styled.div`
  flex-basis: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
  height: 30px;
`;

const MenuItem = ({
    children,
    isActive,
    leftIcon,
    rightIcon,
    onClick,
    variant = "default"
  }) => {
  const theme = useTheme()
  return (
    <Wrapper
      isActive={isActive}
      padding={theme.menuitem.padding}
      height={theme.menuitem.height}
      borderColor={theme.menuitem.borderColor}
      borderStyle={theme.menuitem.borderStyle}
      borderSize={theme.menuitem.borderSize}
      background={theme.menuitem.variant[variant].normal.background}
      backgroundHover={theme.menuitem.variant[variant].hover.background}
      backgroundActive={theme.menuitem.variant[variant].active.background}
      textColor={theme.menuitem.variant[variant].normal.textColor}
      textColorHover={theme.menuitem.variant[variant].hover.textColor}
      textColorActive={theme.menuitem.variant[variant].active.textColor}
      onClick={onClick}
    >
      { leftIcon ?
        <Left>{React.cloneElement(leftIcon, {
          size: {value: 30, unit: "px"}
        })}</Left>
        : ""
      }
      <Center>
        {children}
      </Center>
      { rightIcon ?
        <Right>{React.cloneElement(rightIcon, {
          size: {value: 30, unit: "px"}
        })}</Right>
        : ""
      }
    </Wrapper>
  )
}

MenuItem.propTypes = {
  leftIcon: PropTypes.element,
  isActive: PropTypes.bool,
  rightIcon: PropTypes.element,
  onClick: PropTypes.func,
  variant: PropTypes.string,
}

export default React.memo(MenuItem)
