import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Icon from './../../../../App/Icon.js';
import { withTheme } from "./../../../../../themes";

import FilterTag from "./filterTag";

import './filterList.css';

class FilterList extends Component {
   constructor(props){
      super(props);

      this.areaService = props.service;
      this.field = props.field;
      this.wrapped = props.wrapped;
      this.depthCallback = props.depthCallback;
      this.facetCallback = props.facetCallback;
      this.state = {
         depth: props.depth,
         name: props.name,
         area: props.area,
         translations: props.translations,
         currentFilters: props.currentFilters,
         facets: [],
         filterSelection: null
      };
      this.getFacets(props.currentFilters);
   }

   getFacets(filters){
      if(this.field !== ""){
         this.areaService.getFacets(this.field, filters, "", "").then(ret  => {
            this.setState({
               "facets": ret
            });
         }).catch(err => console.log('There was an error:' + err));
      }
   }

   toggleFacet(key, val){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(key)){
         if(currentFilters[key].indexOf(val)>-1){
            currentFilters[key].splice([currentFilters[key].indexOf(val)], 1);
            if(currentFilters[key].length === 0){
               delete currentFilters[key];
            }
         }else{
            currentFilters[key].push(val);

            ReactGA.event({
               category: `Search by`,
               action: key,
               label: val
            });
         }
      }else{
         currentFilters[key] = [];
         currentFilters[key].push(val);

         ReactGA.event({
            category: `Search by`,
            action: key,
            label: val
         });
      }

      this.setState({
         "currentFilters": currentFilters
      });
      this.facetCallback(currentFilters);
      this.getFacets(currentFilters);


   }

   getTags(){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(this.state.name)){
         let values = currentFilters[this.state.name];

         return <FilterTag
            area={this.state.area}
            field={this.state.name}
            value={values.join(", ")}
            extended={false}
            currentFilters={this.state.currentFilters}
            tagCallback={this.tagCallback.bind(this)}
         />;
      }else{
         return null;
      }
   }

   tagCallback(currentFilters){
      this.props.tagCallback(currentFilters);
   }


   goDeeper(){
      var depth = this.state.depth;
      depth = depth + 1;
      this.setState({
         "depth": depth
      });
      this.depthCallback(depth, this.state.name);
   }

   render() {
      return (
       <div className={ this.state.depth === 0 || this.wrapped ? "" : "filter-wrapper"}>
          { this.state.depth === 0 ?
             <div className="filter-list item-wrapper">
                <div className="item-subwrapper" onClick={this.goDeeper.bind(this)}>
                   <div className="item">
                      <div className="text">{this.state.translations["filter-"+this.state.area]["_filter-label-"+this.state.name]}</div>
                      <div className="icon"></div>
                   </div>
                   {this.getTags()}
                </div>
             </div>
          : this.wrapped ?

             Object.keys(this.state.facets).map(key =>(
                <div className={"filter-list item-wrapper item-leaf " + (this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ? "active" : "")} key={key} onClick={this.toggleFacet.bind(this, this.state.name, this.state.facets[key].value)}>
                   <div className="item">
                      <div className="text">{this.state.facets[key].value}</div>
                      {this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ?
                        <div className="checked">
                          <Icon
                            name={this.props.theme.icons.checked}
                            size={{"value": 20, "unit": "px"}}
                            color={this.props.theme.filters.icon.normal.color}
                            background={this.props.theme.filters.icon.normal.background}
                            hoverColor={this.props.theme.filters.icon.hover.color}
                            hoverBackground={this.props.theme.filters.icon.hover.background}
                          />
                        </div>
                      : null }
                      <div className="counter">{(this.state.facets[key].count)}</div>
                   </div>
                </div>
             ))

          :
             <div className="filter-subwrapper">
                <div className="items-container">
                   <div className="items-scroller">
                      {Object.keys(this.state.facets).map(key =>(
                         <div className={"filter-list item-wrapper item-leaf " + (this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ? "active" : "")} key={key} onClick={this.toggleFacet.bind(this, this.state.name, this.state.facets[key].value)}>
                            <div className="item">
                               <div className="text">{this.state.facets[key].value}</div>
                               {this.state.currentFilters.hasOwnProperty(this.state.name) && this.state.currentFilters[this.state.name].includes(this.state.facets[key].value) ?
                                 <div className="checked">
                                   <Icon
                                     name={this.props.theme.icons.checked}
                                     size={{"value": 20, "unit": "px"}}
                                     color={this.props.theme.filters.icon.normal.color}
                                     background={this.props.theme.filters.icon.normal.background}
                                     hoverColor={this.props.theme.filters.icon.hover.color}
                                     hoverBackground={this.props.theme.filters.icon.hover.background}
                                   />
                                 </div>
                               : null }
                               <div className="counter">{(this.state.facets[key].count)}</div>
                            </div>
                         </div>
                      ))}
                   </div>
                </div>
             </div>
          }
       </div>
      );
   }
}

export default withTheme(FilterList);
