import React, { Component } from 'react';
import './flowershelf.css';

export default class Flowershelf extends Component {
   constructor(props){
      super(props);
      this.nodeContainer = React.createRef();
      this.state = {
         elements: props.elements,
         gutter: (props.gutter ? props.gutter : 0),
			rowHeight: (props.rowHeight ? props.rowHeight : null),
			fullRows: (props.fullRows ? props.fullRows : null),
			orderedLoading: (props.orderedLoading ? props.orderedLoading : null),

         containerWidth: null,
         isLoading: true
      };

   }

   componentDidMount(){
      this.setState({
         containerWidth: this.nodeContainer.current.offsetWidth
      }, () => this.updateGrid());

      // window.addEventListener('resize', () => this.handleResize());
   }
   componentWillUnmount() {
      // window.removeEventListener('resize', () => this.handleResize());
   }

   componentDidUpdate(prevProps, prevState){
      // if(this.props.elements !== this.state.elements ||
      //    this.props.gutter !== this.state.gutter ||
      //    this.props.rowHeight !== this.state.rowHeight ||
      //    this.props.fullRows !== this.state.fullRows ||
      //    this.props.orderedLoading !== this.state.orderedLoading){
      //       this.setState({
      //          elements: this.props.elements,
      //          gutter: (this.props.gutter ? this.props.gutter : 0),
      // 			rowHeight: (this.props.rowHeight ? this.props.rowHeight : null),
      // 			fullRows: (this.props.fullRows ? this.props.fullRows : null),
      // 			orderedLoading: (this.props.orderedLoading ? this.props.orderedLoading : null),
      //
      //          isLoading: true
      //       }, () => this.updateGrid());
      // }

   }

   handleResize(){
      // this.setState({
      //    containerWidth: this.nodeContainer.current.offsetWidth
      // });
   }

   checkContainerWidth(usedWidth){
			// var _this = this;
			// if(this._divContainer.getBoundingClientRect().width < usedWidth){
			// 	setTimeout(function(){
			// 		_this._render(Math.floor(_this._divContainer.getBoundingClientRect().width));
			// 	},1);
			// 	return true;
			// }else{
				return false;
			// }
		}


   updateGrid(){
      if(this.state.containerWidth){
         let jsonElements = this.state.elements;

         for(let iRow = 0; iRow < jsonElements.length; iRow++){
   				let arrElem = jsonElements[iRow];
   				let rowWidthAtSameHeight = 0;

   				if (this.state.rowHeight === null) {

   					for(let iElem = 0; iElem < arrElem.length; iElem++){
                     let jsonElement = arrElem[iElem];

   						rowWidthAtSameHeight += ((jsonElement.width) * 1000 / (jsonElement.height));
   					}


                  // console.log("---------");
                  // console.log("this.state.containerWidth: " + this.state.containerWidth);
                  // console.log("rowWidthAtSameHeight: " + rowWidthAtSameHeight);
                  // console.log("rowNewHeight: " + (this.state.containerWidth - (this.state.gutter * (arrElem.length - 1))) * 1000 / rowWidthAtSameHeight);
                  // console.log("---");

   					let rowNewHeight = (this.state.containerWidth - (this.state.gutter * (arrElem.length - 1))) * 1000 / rowWidthAtSameHeight;
   					for(let iElem = 0; iElem < arrElem.length; iElem++){
                     let jsonElement = arrElem[iElem];

   						if (iElem !== 0 - 1) {
                        jsonElement.isFirst = true;
   							// if(this.checkContainerWidth(this.state.containerWidth)){
   							// 	return;
   							// }
   						}else{
                        jsonElement.isFirst = false;
                     }
   						if (iElem !== arrElem.length - 1) {
                        jsonElement.isLast = true;
   						}else{
                        jsonElement.isLast = false;
                     }

							jsonElement.newHeight = Math.floor(rowNewHeight);
                     // console.log("A: " + arrElem[iElem].width);
                     // console.log("B: " + Math.floor(rowNewHeight));
                     // console.log("C: " + arrElem[iElem].height);
                     //
                     // console.log(Math.floor((arrElem[iElem].width * rowNewHeight / arrElem[iElem].height)));
							jsonElement.newWidth = Math.floor((arrElem[iElem].width * rowNewHeight / arrElem[iElem].height));
   						if (iElem !== arrElem.length - 1) {
                        jsonElement.newMarginRight = this.state.gutter;
   						}else{
                        jsonElement.newMarginRight = 0;
   						}
   						if (iRow !== jsonElements.length - 1) {
                        jsonElement.newMarginBottom = this.state.gutter;
   						}

                     jsonElements[iRow][iElem] = jsonElement;
   					}
   				}else{

   					for(let iElem = 0; iElem < arrElem.length; iElem++){
   						rowWidthAtSameHeight += ((arrElem[iElem].width) * this.state.rowHeight / (arrElem[iElem].height));
   					}


   					let subrowCount = Math.round(rowWidthAtSameHeight / this.state.containerWidth);
   					let subrowLength = 0;
   					let subrowBreaks = [];
   					let subrowNewLength;

   					if (!this.state.fullRows) {
   						subrowNewLength = this.state.containerWidth;
   					}else{
   						subrowNewLength = rowWidthAtSameHeight / subrowCount;
   					}
   					let subrowBreakpoint = subrowNewLength;
   					let currentInRow = 0;
   					for(let iElem = 0; iElem < arrElem.length; iElem++){

   						let precSubrowLength = subrowLength;
   						subrowLength += ((arrElem[iElem].width) * this.state.rowHeight / (arrElem[iElem].height)) + this.state.gutter;

   						let subgutteredSubrowLength = subrowLength - this.state.gutter;
   						currentInRow++;
   						if (subgutteredSubrowLength > subrowBreakpoint) {
   							if ((subgutteredSubrowLength - subrowBreakpoint > subrowBreakpoint - precSubrowLength || !this.state.fullRows) && currentInRow > 1) {
   								subrowBreaks.push(iElem-1); //prima
   								currentInRow--;
   								iElem--;
   							}else{
   								subrowBreaks.push(iElem); //dopo
   							}

   							if (!this.state.fullRows) {
   								subrowLength = 0;
   							}else{
   								subrowBreakpoint += subrowNewLength;
   							}
   							currentInRow = 0;
   						}else if (iElem === arrElem.length - 1){
   							subrowBreaks.push(arrElem.length-1);
   						}

   					}


   					let startPoint = 0;
   					for(let k = 0; k < subrowBreaks.length; k++){
   						let endPoint = subrowBreaks[k];

   						let rowWidthAtSameHeight = 0;
   						for(var iElem = startPoint; iElem <= endPoint; iElem++){
   							rowWidthAtSameHeight += ((arrElem[iElem].width) * 1000 / (arrElem[iElem].height));
   						}

   						let rowNewHeight;


   						if (!this.state.fullRows && k === subrowBreaks.length - 1) {
   							if (startPoint === endPoint) {
   								let elementWidth = ((arrElem[startPoint].width) * this.state.rowHeight / (arrElem[startPoint].height));
   								if (elementWidth >= this.state.containerWidth) {
   									rowNewHeight = (this.state.containerWidth) * 1000 / rowWidthAtSameHeight;
   								}else{
   									rowNewHeight = this.state.rowHeight;
   								}
   							}else{
   								rowNewHeight = this.state.rowHeight;
   							}
   						}else{
   							rowNewHeight = (this.state.containerWidth - (this.state.gutter * (endPoint - startPoint))) * 1000 / rowWidthAtSameHeight;
   						}


   						let currentRowWidth = 0;
   						for(let iElem = startPoint; iElem <= endPoint; iElem++){
   							let jsonElement = arrElem[iElem];

   							let newWidth = Math.floor((arrElem[iElem].width * rowNewHeight / arrElem[iElem].height));
   							currentRowWidth += newWidth;

   							if (iElem === startPoint) {
                           jsonElement.isFirst = true;
   								// if(this.checkContainerWidth(this.state.containerWidth)){
   								// 	return;
   								// }
   							}else{
                           jsonElement.isFirst = false;
                        }
   							if (iElem === endPoint) {
                           jsonElement.isLast = true;
   								if (currentRowWidth !== this.state.containerWidth && iElem !== subrowBreaks[subrowBreaks.length-1]) {
   									newWidth += this.state.containerWidth - currentRowWidth;
   								}
   							}else{
                           jsonElement.isLast = false;
                        }

   							jsonElement.newHeight = Math.floor(rowNewHeight);
   							jsonElement.newWidth = newWidth;

   							if (iElem !== endPoint) {
   								jsonElement.newMarginRight = this.state.gutter;
   								currentRowWidth += this.state.gutter;
   							}else{
   								jsonElement.newMarginRight = 0;
   							}
   							if (iElem <= subrowBreaks[subrowBreaks.length-2] || iRow < jsonElements.length-1) {
   								jsonElement.newMarginBottom = this.state.gutter;
   							}
                        jsonElements[iRow][iElem] = jsonElement;
   						}
   						startPoint = endPoint+1;

   					}
   				}

   			}


         this.setState({
            elements: jsonElements,
            isLoading: false
         });
      }
   }

   render() {
      // console.log(this.state.elements);

      return (
         <div className={"flowershelf " + (this.state.isLoading ? "loading" : "loaded") } ref={this.nodeContainer}>
            {this.state.elements.map((row, rowIndex) =>(
               <div key={rowIndex} className="flowershelf-row">
                  {row.map((element, elementIndex) =>(
                     <div key={elementIndex} className={"flowershelf-element " + (element.isLoading ? "loading" : "loaded")} style={{
                        width: element.newWidth,
                        height: element.newHeight,
                        marginRight: element.newMarginRight,
                        marginBottom: element.newMarginBottom
                     }}>
                        {element.content}
                     </div>
                  ))}
               </div>
            ))}
         </div>
      );
   }
}
