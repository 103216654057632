import React from 'react'
// import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
// import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from './../../themes'

import MenuItem from "./../App/MenuItem"

const LanguageSelector = () => {
  const theme = useTheme()
  let { lang } = useParams()

  return (
    <>
      <a href="/it"><MenuItem
        isActive={lang === "it"}
        variant={theme.morepanel.menuitemVariantItem}
        >Italiano</MenuItem></a>
      <a href="/en"><MenuItem
        isActive={lang === "en"}
        variant={theme.morepanel.menuitemVariantItem}
        >English</MenuItem></a>
    </>
  )
}

export default React.memo(LanguageSelector)
