import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { getLayout } from './../../store/selectors/settings'
// import { getConfig, getLayout } from './../../store/selectors/settings'
import { LAYOUT_XS, LAYOUT_SM } from './../../settings'
import { DOMAINS } from "./../../domains"
// import { LAYOUT_XS, LAYOUT_SM, LAYOUT_DESKTOP, LAYOUT_MOBILE } from './../../settings'

import DesktopTopbar from "./../../components/Layout/DesktopTopbar"
import MobileTopbar from "./../../components/Layout/MobileTopbar"

import { SamCompanySheet } from "./../../tenants/exports";
import { FicCompanySheet } from "./../../tenants/exports";
import { OvrCompanySheet } from "./../../tenants/exports";
import { AsfCompanySheet } from "./../../tenants/exports";

/*temp*/
// import OldPage from './../old/application/page/page'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
const TopbarWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
const PageWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
`;

function Page() {
  // const config = useSelector(getConfig)
  const layout = useSelector(getLayout)


  let { area } = useParams()

  return (
    <Wrapper>
      <TopbarWrapper>
        {layout === LAYOUT_XS || layout === LAYOUT_SM ?
          <MobileTopbar />
        :
          <DesktopTopbar />
        }
      </TopbarWrapper>
      <PageWrapper>
        {(() => {
          switch (DOMAINS[window.location.hostname].tenant) {
            case "SAM":
              switch (area) {
                case "companies":
                  return <SamCompanySheet />;
                default:
                  return <></>;
              }

            case "FIC":
              switch (area) {
                case "companies":
                  return <FicCompanySheet />;
                default:
                  return <></>;
              }

            case "OVR":
              switch (area) {
                case "companies":
                  return <OvrCompanySheet />;
                default:
                  return <></>;
              }

            case "ASF":
              switch (area) {
                case "companies":
                  return <AsfCompanySheet />;
                default:
                  return <></>;
              }
            default:
              return <></>;
          }
        })()}
      </PageWrapper>
    </Wrapper>
  );
}

export default React.memo(Page)
