import React from 'react';
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { login  } from '../../store/actions/auth'

import Button from "./../App/Button"


const LoginButton = ({variant, solid, children}) => {
  const dispatch = useDispatch()

  return (
    <Button variant={variant} solid={solid} onClick={() => dispatch(login(), [dispatch])}>
      {children}
    </Button>
  )
}

LoginButton.propTypes = {
  variant: PropTypes.string,
  solid: PropTypes.bool,
}

export default React.memo(LoginButton)
