import {DOMAINS} from "./../../domains"

const BackendClient = (auth) => {

  const init = () => {
    return DOMAINS[window.location.hostname]
  }

  return { 
    init
  }
}

const Backend = () => {
  const client = BackendClient()
  const init = client.init

  return {
    init
  }
}

const configuration = Backend()
export default configuration
