import React from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useTheme } from './../../themes';

import Logo from "./../../components/App/Logo";
import ListButton from "./../../components/List/ListButton";
import HomeButton from "./../../components/Home/HomeButton";
import MoreButton from "./../../components/More/MoreButton";

const Wrapper = styled.div`
  width: 100%;
  height: ${ props => props.height.value+props.height.unit };
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  ${props => props.background ? "background: "+props.background+";" : ""}
`;

const Left = styled.div`
  flex-basis: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
`;
const Right = styled.div`
  flex-basis: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

function Header() {
  const theme = useTheme()


  let { lang, area, slug } = useParams()
  console.log(theme.topbar.background);
  return (
    <Wrapper background={theme.topbar.mobile.background} height={theme.topbar.mobile.height}>
      <Left>
        { slug !== undefined || area === undefined ?
          <ListButton
            size={{value: 40, unit: "px"}}
            color={theme.topbar.mobile.icon.normal.color}
            background={theme.topbar.mobile.icon.normal.background}
          />
        :
          <>
            { area !== undefined ?
              <HomeButton
                size={{value: 40, unit: "px"}}
                color={theme.topbar.mobile.icon.normal.color}
                background={theme.topbar.mobile.icon.normal.background}
              />
            :
              ""
            }
          </>
        }
      </Left>
      <Center>
        <Link to={"/"+lang}>
          <Logo
            variant={theme.topbar.mobile.logo.variant}
            width={theme.topbar.mobile.logo.width}
            height={theme.topbar.mobile.logo.height} />
        </Link>
      </Center>
      <Right>
        <MoreButton
          size={{value: 40, unit: "px"}}
          color={theme.topbar.mobile.icon.normal.color}
          background={theme.topbar.mobile.icon.normal.background}
        />
      </Right>
    </Wrapper>
  );
}

export default React.memo(Header)
