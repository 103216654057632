import React, { Component } from 'react';

import './sheetFullCover.sass';

export default class SheetFullCover extends Component {
   constructor(props){
      super(props);
      this.state = {
         coverImage: props.coverImage,
         withBlur: props.withBlur,
         logoImage: props.logoImage,
         title1: props.title1,
         title2: props.title2,
         title3: props.title3,
         title4: props.title4,
      };
   }


   render() {
      return (
         <section id="sheetFullCover">
            { this.state.coverImage ?
               <div className="cover">
                  { this.state.withBlur ?
                     <div className="blur">
                        <div className="blurred-image" style={{"backgroundImage": "url('"+this.state.coverImage+"')"}}></div>
                     </div>
                  : "" }
                  <div className="bounded image">
                     <img className="normal-image" src={this.state.coverImage} alt={this.state.title1 + " cover"} itemProp="image" />
                  </div>

               </div>
            : "" }
            <div className="title bounded">
               { this.state.title2 ?
                  <h2>{this.state.title2}</h2>
               : "" }
               { this.state.title4 ?
                  <h4>{this.state.title4}</h4>
               : "" }
               { this.state.title1 ?
                  <h1 itemProp="name">{this.state.title1}</h1>
               : "" }
               { this.state.title3 ?
                  <h3>{this.state.title3}</h3>
               : "" }
               { this.state.logoImage ?
                  <div className="logo">
                     <img alt={this.state.title1 + " logo"} src={this.state.logoImage} itemProp="logo" />
                  </div>
               : "" }
            </div>
         </section>
      );
   }
}
