// import axios from "axios";
// import AuthService from "./../auth/authService";
import i18n from 'i18next';
import he from 'he';
import algoliasearch from 'algoliasearch';
import ProductsServiceForCompanies from "./../../../old/entities/products/productsService";
import EventsServiceForCompanies from "./../../../old/entities/events/eventsService";
import { SEPARATOR_ORDERVALUE } from './../../../../settings'
import {DOMAINS} from "./../../../../domains"



var client;
var index;
var filterStructure = {};
var isInitialized = false;




export default class CompaniesService{
   constructor(){
      this.config = null;
      this.language = null;
   }

   initialize(c, l){
      this.config = c;
      this.language = l;
      return new Promise((resolve, reject) => {
         client = algoliasearch(DOMAINS[window.location.hostname].algolia.id, DOMAINS[window.location.hostname].algolia.key);
         if(DOMAINS[window.location.hostname].algolia.index_companies){
            isInitialized = true;
            index = client.initIndex(DOMAINS[window.location.hostname].algolia.index_companies + l.toUpperCase());
            filterStructure = c.filter_companies;
            resolve(true);
         }
      });
   }


   getFilterStructure(){
      if(isInitialized){
         return filterStructure;
      }else{
         return {};
      }
   }

   composeFilter(filter){
      let filterstring = "";

      for (var item in filter) {
         if(filterstring !== ""){
            filterstring += " AND ";
         }

         for(let i = 0; i < filter[item].length; i++){
            if(this.getType(item) === "range"){
               if(i === 0 ){
                  filterstring += "(";
               }
               if(i > 0 && filterstring !== ""){
                  filterstring += " OR ";
               }

               filterstring += this.getField(item).replace("LANG", i18n.language)+": \""+filter[item][i]+"\"";

               if(i === filter[item].length - 1){
                  filterstring += ")";
               }
            }else{
               if(i > 0 && filterstring !== ""){
                  filterstring += " AND ";
               }

               let level = null;
               if((filter[item][i].match(/ > /g) || []).length > 0){
                  level = (filter[item][i].match(/ > /g) || []).length;
               }else if(this.getType(item) === "tree"){
                  level = 0;
               }
               filterstring += this.getField(item,level).replace("LANG", i18n.language)+": \""+filter[item][i]+"\"";
            }
         }
      }
      // console.log(filterstring);
      return filterstring;
   }

   getList(page, search, filter){
      if(isInitialized){
         var that = this;
         return new Promise((resolve, reject) => {

            let filterstring = this.composeFilter(filter);

            index.search(search, {
                  filters: filterstring,
                  hitsPerPage: "20",
                  page: page
               }).then(function(content){
                 let elements = [];
                 for(let i=0; i<content.hits.length; i++){
                    let element = that.formatData(content.hits[i]);
                    elements.push(element);
                 }
                 let state = null;
                 if(content.page === content.nbPages-1){
                    state = 0; //no more results
                 }else if(content.nbPages === 0){
                    state = -1; //no results
                 }else{
                    state = 1; //loading
                 }
                 resolve({"dataResult": elements, "loadingState": state, "nCurrent": content.nbHits});

              });

         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }


   /*single*/
   getData(val, by = "id"){
      if(isInitialized){
         let that = this;
         return new Promise((resolve, reject) => {

            let field = "";
            switch(by){
               case "id":
                  field = "objectID";
                  break;
               case "slug":
                  field = "ExternalCode";
                  break;
               default:
                  break;

            }
            // console.log(field+":"+val);
            index.search("", {
                  filters: field+":"+val,
                  hitsPerPage: 1,
                  page: 0
               }).then(function(content){
   // console.log(content);
                  for(let i=0; i<content.hits.length; i++){
                     let element = that.formatData(content.hits[i]);
                     let productsServ = new ProductsServiceForCompanies();
                     productsServ.initialize(that.config, that.language).then(ret  => {
                        productsServ.getListByCompany(content.hits[i].objectID).then(products => {
                           element.products = products;

                           let eventsServ = new EventsServiceForCompanies();
                           eventsServ.initialize(that.config, that.language).then(ret  => {
                              eventsServ.getListByCompany(content.hits[i].objectID).then(events => {
                                 element.events = events;

                                 resolve(element);
                              }).catch(err => console.log('There was an error:' + err));
                           }).catch(err => console.log('There was an error:' + err));
                        }).catch(err => console.log('There was an error:' + err));
                     }).catch(err => console.log('There was an error:' + err));
                  }
               });

         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   /*search*/
   getFacets(field, filters, query, search){
      if(isInitialized){
         if(query !== ""){
            query = query + " > ";
         }
         let val = field.replace("LANG", i18n.language);
         return new Promise((resolve, reject) => {

            let filterstring = this.composeFilter(filters);
               index.searchForFacetValues(val, query + (search ? " " + search : ""), {
                     filters: filterstring,
                     maxFacetHits: 100
                  }).then(function(content){
                     // if (err) throw err;
                     let arrFacets = content.facetHits;
                     switch(field){
                        case "CategoriesHierarchy.lvl0":
                           arrFacets.sort(function(a, b) {
                              if(a.value < b.value){
                                 return -1;
                              }else if(a.value > b.value){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;

                        case "CategoriesHierarchy.lvl1":
                           for (let i=arrFacets.length-1; i>=0; i--){
                              if(arrFacets[i].value.substring(0, query.length) !== query){
                                 arrFacets.splice(i, 1);
                              }
                           }
                           arrFacets.sort(function(a, b) {
                              if(a.value < b.value){
                                 return -1;
                              }else if(a.value > b.value){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;

                        case "Info.Bottles":
                           arrFacets.sort(function(a, b) {
                              let aVal = parseInt(a.value.match(/\d+/)[0], 10);
                              let bVal = parseInt(b.value.match(/\d+/)[0], 10);
                              if(aVal < bVal){
                                 return -1;
                              }else if(aVal > bVal){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;

                        case "CatIds":
                           for(let i = 0; i < arrFacets.length; i++){
                              if(arrFacets[i].value === "DOCG" || arrFacets[i].value === "DOC" || arrFacets[i].value === "IGT"){
                                 arrFacets.splice(i,1);
                                 i--;
                              }
                           }

                           arrFacets.sort(function(a, b) {
                              let order = ["BIO","EDI","DYN","PRI","SPA","ASS","KOS","DIS","TAB","FOR","SPI" ];
                              if(order.indexOf(a.value) < order.indexOf(b.value)){
                                 return -1;
                              }else if(order.indexOf(a.value) > order.indexOf(b.value)){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;

                        default:
                           arrFacets.sort(function(a, b) {
                              if(a.value < b.value){
                                 return -1;
                              }else if(a.value > b.value){
                                 return 1;
                              }else{
                                 return 0;
                              }
                           });
                           break;
                     }
                     resolve(arrFacets);
                  });

            // }


         });
      }else{
         return new Promise((resolve, reject) => {
            resolve(null);
         });
      }
   }

   /*filter*/
   getField(key, level = null){
      var ret = null;
      Object.keys(filterStructure).forEach(filter => {
         if(filterStructure[filter].hasOwnProperty(key)){
            ret = filterStructure[filter][key]["field"];
            return;
         }
      })

      if(level != null){
         ret = ret + ".lvl"+level;
      }

      return ret;
   }
   getType(key){
      var ret = null;
      Object.keys(filterStructure).forEach(filter => {
         if(filterStructure[filter].hasOwnProperty(key)){
            ret = filterStructure[filter][key]["type"];
            return;
         }
      })

      return ret;
   }

   formatData(hit){
      var that = this;
      let element = {}
      element.highlighted = {};

      /*id*/
      element.id = hit.objectID;

      /*slug*/
      element.slug = hit.ExternalCode;

      /*tenant*/
      element.tenant = hit.TenantId;

      /*type*/
      element.type = "company";
      element.geographicMap = null;

      /*name & pagetitle*/
      element.name = "";
      if(hit.Name !== undefined &&
         hit.Name !== null
      ){
         element.name = he.decode(hit.Name);
         element.pagetitle = element.name;
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.Name !== undefined &&
         hit._highlightResult.Name.value !== undefined &&
         hit._highlightResult.Name.value !== null
      ){
         element.highlighted.name = he.decode(hit._highlightResult.Name.value);
      }else {
         element.highlighted.name = element.name;
      }

      /*country*/
      element.country = "";
      if(hit.AnagraphicInfo.Address !== undefined &&
         hit.AnagraphicInfo.Address.Country !== undefined &&
         hit.AnagraphicInfo.Address.Country !== null
      ){
         element.country = he.decode(hit.AnagraphicInfo.Address.Country);
         if(hit.AnagraphicInfo.Address.CountryId !== undefined &&
            hit.AnagraphicInfo.Address.CountryId !== null
         ){
            element.geographicMap = process.env.PUBLIC_URL + "/assets/maps/countries/" + hit.AnagraphicInfo.Address.CountryId + ".svg";
         }
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.AnagraphicInfo !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Country !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Country.value !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Country.value !== null
      ){
         element.highlighted.country = he.decode(hit._highlightResult.AnagraphicInfo.Address.Country.value);
      }else {
         element.highlighted.country = element.country;
      }

      /*region*/
      element.region = "";
      if(hit.AnagraphicInfo.Address !== undefined &&
         hit.AnagraphicInfo.Address.Region !== undefined &&
         hit.AnagraphicInfo.Address.Region !== null
      ){
         element.region = he.decode(hit.AnagraphicInfo.Address.Region);
         if(hit.AnagraphicInfo.Address.RegionId !== undefined &&
            hit.AnagraphicInfo.Address.RegionId !== null
         ){
            element.geographicMap = process.env.PUBLIC_URL + "/assets/maps/regions/" + hit.AnagraphicInfo.Address.RegionId + ".svg";
         }
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.AnagraphicInfo !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Region !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Region.value !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Region.value !== null
      ){
         element.highlighted.region = he.decode(hit._highlightResult.AnagraphicInfo.Address.Region.value);
      }else {
         element.highlighted.region = element.region;
      }

      /*province*/
      element.province = "";
      if(hit.AnagraphicInfo.Address !== undefined &&
         hit.AnagraphicInfo.Address.Province !== undefined &&
         hit.AnagraphicInfo.Address.Province !== null
      ){
         element.province = he.decode(hit.AnagraphicInfo.Address.Province);
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.AnagraphicInfo !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Province !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Province.value !== undefined &&
         hit._highlightResult.AnagraphicInfo.Address.Province.value !== null
      ){
         element.highlighted.province = he.decode(hit._highlightResult.AnagraphicInfo.Address.Province.value);
      }else {
         element.highlighted.province = element.province;
      }

      /*city*/
      if(hit.AnagraphicInfo.Address !== undefined &&
         hit.AnagraphicInfo.Address.City !== undefined &&
         hit.AnagraphicInfo.Address.City !== null
      ){
         element.city = he.decode(hit.AnagraphicInfo.Address.City);
      }

      /*address*/
      if(hit.AnagraphicInfo.Address !== undefined &&
         hit.AnagraphicInfo.Address.Address !== undefined &&
         hit.AnagraphicInfo.Address.Address !== null
      ){
         element.address = he.decode(hit.AnagraphicInfo.Address.Address);
      }

      /*zip*/
      if(hit.AnagraphicInfo.Address !== undefined &&

         hit.AnagraphicInfo.Address.ZipCode !== undefined &&
         hit.AnagraphicInfo.Address.ZipCode !== null
      ){
         element.zip = he.decode(hit.AnagraphicInfo.Address.ZipCode);
      }

      /*activity*/
      if(hit.Activity !== undefined &&
         hit.Activity !== null
      ){
         element.activity = he.decode(hit.Activity);
      }

      /*brands*/
      if(hit.Brands !== undefined &&
         hit.Brands !== null
      ){
         element.brands = he.decode(hit.Brands);
      }

      /*emails*/
      if(hit.AnagraphicInfo.Emails !== undefined &&
         hit.AnagraphicInfo.Emails !== null
      ){
         element.emails = hit.AnagraphicInfo.Emails;
      }else{
         element.emails = [];
      }

      /*websites*/
      if(hit.AnagraphicInfo.WebSites !== undefined &&
         hit.AnagraphicInfo.WebSites !== null
      ){
         element.websites = hit.AnagraphicInfo.WebSites;
      }else{
         element.websites = [];
      }

      /*phones*/
      if(hit.AnagraphicInfo.PhoneNumbers !== undefined &&
         hit.AnagraphicInfo.PhoneNumbers !== null
      ){
         element.phones = hit.AnagraphicInfo.PhoneNumbers;
      }else{
         element.phones = [];
      }

      /*faxes*/
      if(hit.AnagraphicInfo.FaxNumbers !== undefined &&
         hit.AnagraphicInfo.FaxNumbers !== null
      ){
         element.faxes = hit.AnagraphicInfo.FaxNumbers;
      }else{
         element.faxes = [];
      }

      /*socials*/
      element.social = {};
      if(hit.Social !== undefined &&
         hit.Social.Twitter !== undefined &&
         hit.Social.Twitter !== null
      ){
         element.social.twitter = hit.Social.Twitter;
      }
      if(hit.Social !== undefined &&
         hit.Social.Facebook !== undefined &&
         hit.Social.Facebook !== null
      ){
         element.social.facebook = hit.Social.Facebook;
      }
      if(hit.Social !== undefined &&
         hit.Social.Linkedin !== undefined &&
         hit.Social.Linkedin !== null
      ){
         element.social.linkedin = hit.Social.Linkedin;
      }
      if(hit.Social !== undefined &&
         hit.Social.Googleplus !== undefined &&
         hit.Social.Googleplus !== null
      ){
         element.social.googleplus = hit.Social.Googleplus;
      }
      if(hit.Social !== undefined &&
         hit.Social.Instagram !== undefined &&
         hit.Social.Instagram !== null
      ){
         element.social.instagram = hit.Social.Instagram;
      }


      /*booths*/
      element.halls = [];
      element.booths = [];
      element.hallsLong = [];
      element.hallsShort = [];
      element.hallsImages = [];
      element.highlighted.halls = [];

      if(hit.BackofficeAnagraphicInfo.Booths !== undefined &&
         hit.BackofficeAnagraphicInfo.Booths.length > 0
      ){
         hit.BackofficeAnagraphicInfo.Booths.forEach(function(hall, i) {
            let hallShort = he.decode(hall.Hall);
            let hallLong = he.decode(hall.Hall + " - Stand " + hall.Stand);
            if(element.halls.indexOf(hall.Hall) < 0){
              element.hallsImages.push("/assets/maps/halls/" + hit.TenantId + "/" + hall.id + ".png");
              element.halls.push(he.decode(hall.Hall));
            }
            // hall.halls.forEach(function(booth, i) {
              // if(hall.Booth){
              //    if(i === 0){
              //       hallShort += " - Stand ";
              //       hallLong += " - Stand ";
              //    }
              //    element.booths.push(booth.Booth);
              //    if(i < that.config.max_stand_short){
              //       if(i !== 0){
              //          hallShort += ", ";
              //       }
              //       hallShort += booth.Booth;
              //    }
              //    if(i < that.config.max_stand_long){
              //       if(i !== 0){
              //          hallLong += ", ";
              //       }
              //       hallLong += booth.Booth;
              //    }
              //  }

               // element.hallsShort.push(he.decode(hall.Hall + " - " + i18n.t('Stand') + " " + hall.Booth));
               // element.hallsLong.push(he.decode(hall.Hall + " - " + i18n.t('Stand') + " " + hall.Booth));
            // });
            // if(hall.Booths.length > that.config.max_stand_short){
            //    hallShort += ", ...";
            // }
            // if(hall.Booths.length > that.config.max_stand_long){
            //    hallLong += ", ...";
            // }
            element.hallsShort.push(hallShort);
            element.hallsLong.push(hallLong);
         });
      }

      if(hit._highlightResult !== undefined &&
         hit._highlightResult.BackofficeAnagraphicInfo !== undefined &&
         hit._highlightResult.BackofficeAnagraphicInfo.Booths !== undefined &&
         hit._highlightResult.BackofficeAnagraphicInfo.Booths.length > 0
      ){
         hit._highlightResult.BackofficeAnagraphicInfo.Booths.forEach(function(booth) {
            element.highlighted.booths.push(he.decode(booth.Hall.value + (booth.Booth.value ? " - Stand " + booth.Booth.value : "")));
         });
      }else{
         element.highlighted.booths = element.booths;
      }


      /*cover*/
      if(hit.Gallery !== undefined &&
         hit.Gallery.CoverId !== undefined &&
         hit.Gallery.CoverId !== null
      ){
         element.cover = hit.UrlCDN + "/cover/" +  hit.Gallery.CoverId;
         element.thumb = hit.UrlCDN + "/company_thumb/" +  hit.Gallery.CoverId;
      }

      /*logo*/
      if(hit.Gallery !== undefined &&
         hit.Gallery.LogoId !== undefined &&
         hit.Gallery.LogoId !== null
      ){
         element.logo = hit.UrlCDN + "/logo/" +  hit.Gallery.LogoId;
      }

      /*gallery*/
      element.gallery = [];
      if(hit.Gallery !== undefined &&
         hit.Gallery.Assets !== undefined &&
         hit.Gallery.Assets.length > 0
      ){
         hit.Gallery.Assets.forEach(media => {
            let obj = {};
            obj.image = hit.UrlCDN + "/gallery_thumb/" +  media.Image;

            if(media.Embed !== undefined &&
               media.Embed !== null &&
               media.Embed !== false
            ){
				   let embed = media.Embed;
               if(embed.indexOf("?v=") !== -1){
   		         embed = embed.substr(embed.indexOf("?v=") + 3);
   		      }else if(embed.indexOf(".be/") !== -1){
                  embed = embed.substr(embed.indexOf(".be/") + 4);
   		      }else if(embed.indexOf("/embed/") !== -1){
                  embed = embed.substr(embed.indexOf("/embed/") + 7);
   		      }else{
                  embed = embed.substr(embed.indexOf("/v/") + 3);
   		      }
               obj.embed = "https://www.youtube.com/embed/" + embed +"?modestbranding=1&rel=0";
            }


            obj.description = media.Description;
            element.gallery.push(obj);
         })
      }

      /*info*/
      element.info = {};

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.FoundationYear !== undefined &&
         hit.AnagraphicActivity.FoundationYear !== null
      ){
         element.info.foundation = hit.AnagraphicActivity.FoundationYear;
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Typology !== undefined &&
         hit.AnagraphicActivity.Typology !== null &&
         hit.AnagraphicActivity.Typology.Name !== undefined &&
         hit.AnagraphicActivity.Typology.Name !== null
      ){
         element.info.typology = he.decode(hit.AnagraphicActivity.Typology.Name);
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.AnnualRevenue !== undefined &&
         hit.AnagraphicActivity.AnnualRevenue !== null
      ){
         element.info.revenue = he.decode(hit.AnagraphicActivity.AnnualRevenue);
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.NumBottlesPerYear !== undefined &&
         hit.AnagraphicActivity.NumBottlesPerYear !== null &&
         hit.AnagraphicActivity.NumBottlesPerYear.Name !== undefined &&
         hit.AnagraphicActivity.NumBottlesPerYear.Name !== null
      ){
         element.info.bottles = he.decode(hit.AnagraphicActivity.NumBottlesPerYear.Name);
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Labels !== undefined &&
         hit.AnagraphicActivity.Labels !== null
      ){
         element.info.labels = hit.AnagraphicActivity.Labels;
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Hectares !== undefined &&
         hit.AnagraphicActivity.Hectares !== null
      ){
         element.info.hectares = hit.AnagraphicActivity.Hectares;
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.BrandsNumber !== undefined &&
         hit.AnagraphicActivity.BrandsNumber !== null
      ){
         element.info.brandsNumber = hit.AnagraphicActivity.BrandsNumber;
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Brands !== undefined &&
         hit.AnagraphicActivity.Brands !== null &&
         hit.AnagraphicActivity.Brands.length > 0
      ){
         element.info.brands = [];
         hit.AnagraphicActivity.Brands.forEach(brand => {
            element.info.brands.push(he.decode(brand));
         })
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.MainOperations !== undefined &&
         hit.AnagraphicActivity.MainOperations !== null &&
         hit.AnagraphicActivity.MainOperations.Name !== undefined &&
         hit.AnagraphicActivity.MainOperations.Name !== null
      ){
         element.info.mainoperations = he.decode(hit.AnagraphicActivity.MainOperations.Name);
      }
      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.ChannelsItaly !== undefined &&
         hit.AnagraphicActivity.ChannelsItaly.length > 0
      ){
         element.info.channelsItaly = [];
         hit.AnagraphicActivity.ChannelsItaly.forEach(channel => {
            let obj = {};
            obj.name = he.decode(channel.Name);
            obj.icon = hit.UrlCDN + "/icons/" + channel.Icon;
            element.info.channelsItaly.push(obj);
         })
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Exports === true
      ){
         element.info.export = {};

         if(hit.AnagraphicActivity !== undefined &&
            hit.AnagraphicActivity !== null &&
            hit.AnagraphicActivity.ExportPercentage !== undefined &&
            hit.AnagraphicActivity.ExportPercentage !== null
         ){
            element.info.exportPercentage = hit.AnagraphicActivity.ExportPercentage + "%";
         }

         if(hit.AnagraphicActivity !== undefined &&
            hit.AnagraphicActivity !== null &&
            hit.AnagraphicActivity.ExportsTo !== undefined &&
            hit.AnagraphicActivity.ExportsTo.length > 0
         ){
            element.info.exportTo = [];
            hit.AnagraphicActivity.ExportsTo.forEach(channel => {
               let obj = {};
               obj.name = he.decode(channel.Country);
               obj.icon = process.env.PUBLIC_URL + "/assets/flags/" + channel.CountryId.toLowerCase() + ".png";
               obj.channels = [];
               element.info.exportTo.push(obj);
            })
         }

         if(hit.AnagraphicActivity !== undefined &&
            hit.AnagraphicActivity !== null &&
            hit.AnagraphicActivity.DesiredExportsTo !== undefined &&
            hit.AnagraphicActivity.DesiredExportsTo.length > 0
         ){
            element.info.wantToExportTo = [];
            hit.AnagraphicActivity.DesiredExportsTo.forEach(channel => {
               let obj = {};
               obj.name = he.decode(channel.Country);
               obj.icon = process.env.PUBLIC_URL + "/assets/flags/" + channel.CountryId.toLowerCase() + ".png";
               element.info.wantToExportTo.push(obj);
            })
         }
      }
      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Languages !== undefined &&
         hit.AnagraphicActivity.Languages.length > 0
      ){
         element.info.language = [];
         hit.AnagraphicActivity.Languages.forEach(language => {
            element.info.language.push(he.decode(language.Name));
         })
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.OpenForTastings !== undefined &&
         hit.AnagraphicActivity.OpenForTastings !== null &&
         hit.AnagraphicActivity.OpenForTastings.Name !== undefined &&
         hit.AnagraphicActivity.OpenForTastings.Name !== null
      ){
         element.info.tastings = he.decode(hit.AnagraphicActivity.OpenForTastings.Name);
      }

      if(hit.AnagraphicActivity !== undefined &&
         hit.AnagraphicActivity !== null &&
         hit.AnagraphicActivity.Hospitality !== undefined &&
         hit.AnagraphicActivity.Hospitality !== null &&
         hit.AnagraphicActivity.Hospitality.Name !== undefined &&
         hit.AnagraphicActivity.Hospitality.Name !== null
      ){
         element.info.hospitality = he.decode(hit.AnagraphicActivity.Hospitality.Name);
      }

      /*designation*/
      element.categories = [];
      if(hit.AnagraphicInfo !== undefined &&
         hit.AnagraphicInfo !== null &&
         hit.AnagraphicInfo.Categories !== undefined &&
         hit.AnagraphicInfo.Categories !== null &&
         hit.AnagraphicInfo.Categories.lvl0 !== undefined &&
         hit.AnagraphicInfo.Categories.lvl0.length > 0
      ){
         hit.AnagraphicInfo.Categories.lvl0.forEach(lvl0 => {
            let obj1 = {};
            obj1.order = lvl0.substring(lvl0.indexOf(0,SEPARATOR_ORDERVALUE));
            obj1.name = he.decode(lvl0.substring(lvl0.indexOf(SEPARATOR_ORDERVALUE) + 1));
            obj1.children = [];
            if(hit.AnagraphicInfo.Categories.lvl1 && hit.AnagraphicInfo.Categories.lvl1.length > 0){
               hit.AnagraphicInfo.Categories.lvl1.forEach(lvl1 => {
                  if(lvl1.indexOf(lvl0) === 0){
                     let obj2 = {};
                     obj2.order = 0;
                     obj2.name = he.decode(lvl1.replace(lvl0 + " > ", ""));

                     obj2.children = [];
                     if(hit.AnagraphicInfo.Categories.lvl2 && hit.AnagraphicInfo.Categories.lvl2.length > 0){
                        hit.AnagraphicInfo.Categories.lvl2.forEach(lvl2 => {
                           if(lvl2.indexOf(lvl1) === 0){
                              let obj3 = {};
                              obj3.order = 0;
                              obj3.name = he.decode(lvl2.replace(lvl1 + " > ", ""));
                              obj2.children.push(obj3);
                           }
                        })
                     }

                     obj1.children.push(obj2);
                  }
               })
            }
            element.categories.push(obj1);
         });
      }
      element.categories.sort(function(a, b) {
         if(a.order < b.order){
            return -1;
         }else if(a.order > b.order){
            return 1;
         }else{
            return 0;
         }
      });

      /*relations*/
      element.relations = {};
      if(hit.AnagraphicInfo !== undefined &&
         hit.AnagraphicInfo !== null &&
         hit.AnagraphicInfo.Relations !== undefined &&
         hit.AnagraphicInfo.Relations !== null
      ){
         if(hit.AnagraphicInfo.Relations.Coexhibs !== undefined &&
            hit.AnagraphicInfo.Relations.Coexhibs !== null &&
            hit.AnagraphicInfo.Relations.Coexhibs.length > 0
         ){
            element.relations.coexhibs = [];
            hit.AnagraphicInfo.Relations.Coexhibs.forEach(company => {
               let objCompany = {};
               objCompany.name = company.N;
               objCompany.slug = company.S;
               element.relations.coexhibs.push(objCompany);
            });
         }
         if(hit.AnagraphicInfo.Relations.Represents !== undefined &&
            hit.AnagraphicInfo.Relations.Represents !== null &&
            hit.AnagraphicInfo.Relations.Represents.length > 0
         ){
            element.relations.represents = [];
            hit.AnagraphicInfo.Relations.Represents.forEach(company => {
               let objCompany = {};
               objCompany.name = company.N;
               objCompany.slug = company.S;
               element.relations.represents.push(objCompany);
            });
         }
         if(hit.AnagraphicInfo.Relations.CoexhibsChild !== undefined &&
            hit.AnagraphicInfo.Relations.CoexhibsChild !== null &&
            hit.AnagraphicInfo.Relations.CoexhibsChild.length > 0
         ){
            element.relations.coexhibsChild = [];
            hit.AnagraphicInfo.Relations.CoexhibsChild.forEach(company => {
               let objCompany = {};
               objCompany.name = company.N;
               objCompany.slug = company.S;
               element.relations.coexhibsChild.push(objCompany);
            });
         }
         if(hit.AnagraphicInfo.Relations.RepresentsChild !== undefined &&
            hit.AnagraphicInfo.Relations.RepresentsChild !== null &&
            hit.AnagraphicInfo.Relations.RepresentsChild.length > 0
         ){
            element.relations.representsChild = [];
            hit.AnagraphicInfo.Relations.RepresentsChild.forEach(company => {
               let objCompany = {};
               objCompany.name = company.N;
               objCompany.slug = company.S;
               element.relations.representsChild.push(objCompany);
            });
         }
      }


      return element;
   }


}
