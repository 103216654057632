import React, { Component } from 'react';
import { i18n } from 'react-i18next';
import { Link } from 'react-router-dom';

import './eventThumb.sass';



export default class EventThumb extends Component {
   constructor(props){
      super(props);

      this.state = {
         element: props.data,
         config: props.config,
         translations: props.translations,
         language: props.language
      };

   }

   componentWillReceiveProps(nextProps) {
      if(nextProps.data !== this.state.data){
         this.setState({data: nextProps.data});
      }
   }

   getEventsDate(dateStart, dateEnd) {

      if(dateEnd === ""){
         dateEnd = dateStart;
      }

      //se le date sono uguali estrae un giorno solo
      //date diverse estrae + giorni
      // var parts;
      // parts = dateStart.split('/');
      // let dataInizio = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
      // parts = dateStart.split('/');
      // let dataFine = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);

      let dataInizio = new Date(dateStart * 1000);
      let dataFine = new Date(dateEnd * 1000);


      // se le date sono uguali ritorno la stringa composta
      if (dataInizio.getFullYear() === dataFine.getFullYear()) {
         if (dataInizio.getMonth() === dataFine.getMonth()) {
            if (dataInizio.getDate() === dataFine.getDate()) {
               return (
                  <i18n ns="translations">
                     {
                        (t, { i18n }) => (
                           <div>{dataInizio.getDate() + " " + t('fullmonths.'+dataInizio.getMonth()) + " " + dataInizio.getFullYear()}</div>
                        )
                     }
                  </i18n>
               );
            }else{
               return (
                  <i18n ns="translations">
                     {
                        (t, { i18n }) => (
                           <div>{dataInizio.getDate() + " / " + dataFine.getDate() + " " + t('fullmonths.'+dataFine.getMonth()) + " " + dataFine.getFullYear()}</div>
                        )
                     }
                  </i18n>
               );
            }
         }else{
            return (
               <i18n ns="translations">
                  {
                     (t, { i18n }) => (
                        <div>{dataInizio.getDate() + " " + t('fullmonths.'+dataInizio.getMonth()) + " / " + dataFine.getDate() + " " + t('fullmonths.'+dataFine.getMonth()) + " " + dataFine.getFullYear()}</div>
                     )
                  }
               </i18n>
            );
         }
      }else{
         return (
            <i18n ns="translations">
               {
                  (t, { i18n }) => (
                     <div>{dataInizio.getDate() + " " + t('fullmonths.'+dataInizio.getMonth()) + " " + dataInizio.getFullYear() + " / " + dataFine.getDate() + " " + t('fullmonths.'+dataFine.getMonth()) + " " + dataFine.getFullYear()}</div>
                  )
               }
            </i18n>
         );
      }

   }


   render() {
      return (
         <div className="event-thumb thumb">
            { this.state.config.enable_events_area ?
               <Link to={ "/" + this.state.language + "/events/" + this.state.element.slug } className="">
                  <div className="cover">
                     { this.state.element.imageThumb ?
                        <img src={ this.state.element.imageThumb } alt="" />
                     :
                        <img src="https://via.placeholder.com/200" alt="" />
                     }
                  </div>
                  <div className="content">
                     <div className="title">{ this.state.element.name }</div>
                     <div className="date">{ this.state.element.dates[0].string }</div>
                  </div>
               </Link>
            :
               <div>
                  <div className="cover">
                     { this.state.element.imageThumb ?
                        <img src={ this.state.element.imageThumb } alt="" />
                     :
                        <img src="https://via.placeholder.com/200" alt="" />
                     }
                  </div>
                  <div className="content">
                     <div className="title">{ this.state.element.name }</div>
                     <div className="date">{ this.state.element.dates[0].string }</div>
                  </div>
               </div>
            }
         </div>
         // <i18n ns="translations">
         //    {(t, { i18n }) => (
         //       <div className="event-thumb">
         //          <div className="cover">
         //             {/* <img src={this.state.data.UrlCDN + "slug-mancante/" +  "da890c35-f434-5e7c-b0ef-323d5830b225"} alt="Cover" /> */}
         //          </div>
         //          <div className="icons">
         //             <ul>
         //                <li className="icon typology"><span className={"icon"} style={{backgroundImage: 'url(' + this.state.data.typologyIcon + ')'}}></span></li>
         //                <li className="icon access"><span className={"icon"} style={{backgroundImage: 'url(' + this.state.data.accessIcon + ')'}}></span></li>
         //             </ul>
         //          </div>
         //
         //          <div className="content">
         //             <div className="info">
         //                <h1>{this.state.data.dates[0].string}</h1>
         //                {/*<h2>{this.state.data.TimeStart} - {this.state.data.TimeEnd}</h2>*/}
         //                <h3>
         //                   {
         //                      (this.state.data.country !== undefined
         //                      ?
         //                      this.state.data.address + " " + this.state.data.city + " " + (this.state.data.province ? "("+this.state.data.province+")" : "")
         //                      :
         //                      "Vinitaly")
         //                   }
         //                </h3>
         //             </div>
         //             <h1>{this.state.data.name}</h1>
         //             {/*{this.state.data.Booths ?
         //                <div className="positions">
         //                   {this.state.data.Booths.map((item, index) =>(
         //                      <p key={index}>
         //                         <span className="hall">{item.Hall}</span>
         //                         {item.Booth ?
         //                            <span className="stand">{t('Stand') + " " + item.Booth}</span>
         //                         :
         //                            ""
         //                         }
         //                      </p>
         //                   ))}
         //                </div>
         //             :
         //                ""
         //             }
         //             {this.state.data.CoverCDN ?
         //                <div className="logo"><img src={this.state.data.UrlCDN + "logo/" +  this.state.data.LogoCDN} alt={this.state.data.Name} /></div>
         //             :
         //                ""
         //             } */}
         //
         //             <Link to={"/events/"+this.state.data.slug} className="btn btn-default btn-100">{t('See details')}</Link>
         //          </div>
         //       </div>
         //    )}
         // </i18n>
      );
   }
}
