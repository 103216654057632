import auth0 from 'auth0-js'
import axios from "axios";
import AWS from 'aws-sdk'
import {DOMAINS} from "./../../domains"
// import store from './../../store/store'

const BackendClient = (webAuth) => {

  const login = () => {
    webAuth.authorize();
  }

  const check = () => {

    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, function (err, checkResult) {
      if(!checkResult){
        return webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
           if (err) {
              return console.log(err);
           }
           if(authResult){
              webAuth.client.userInfo(authResult.accessToken, function(err, user) {
                let ret = {}
                ret.id = user["sub"]
                ret.firstname = user["https://veronafiere.it/claims/given_name"]
                ret.lastname = user["https://veronafiere.it/claims/family_name"]
                ret.avatar = user["picture"]
                ret.accessToken = authResult.accessToken
                ret.idToken = authResult.idToken
                resolve(ret)
              });
           }else{
             resolve(null)
           }
        });
      }else{
        return webAuth.client.userInfo(checkResult.accessToken, function(err, user) {
          let ret = {}
          ret.id = user["sub"]
          ret.firstname = user["https://veronafiere.it/claims/given_name"]
          ret.lastname = user["https://veronafiere.it/claims/family_name"]
          ret.avatar = user["picture"]
          ret.accessToken = checkResult.accessToken
          ret.idToken = checkResult.idToken
          resolve(ret)
        });
      }
     });

    });
  }

  const logout = () => {

    webAuth.logout({
       clientID: DOMAINS[window.location.hostname].auth0.clientID,
       returnTo: window.location.origin
    });
  }

  const setFavorite = (idUser, area, idElement, isFavorite) => {

    return new Promise((resolve, reject) => {
      AWS.config.update({
        region: DOMAINS[window.location.hostname].favorites.region,
        credentials: {
          accessKeyId: DOMAINS[window.location.hostname].favorites.accessKeyId,
          secretAccessKey: DOMAINS[window.location.hostname].favorites.secretAccessKey,
        },
      });
      const sqs = new AWS.SQS({apiVersion: '2012-11-05'});

      switch(area){
        case "companies":
          area = "exhibitor";
          break;
        case "products":
          area = "product";
          break;
        case "events":
          area = "event";
          break;
        case "projects":
          area = "project";
          break;
        default:
          break;
      }

      const params = {
        MessageBody: JSON.stringify({
          itemType: area,
          objectID: idElement,
          userID: idUser
        }),
        MessageAttributes: {
          "process_id": {
            'DataType': "String",
            'StringValue': "6"
          },
          "project_id": {
            'DataType': "String",
            'StringValue': "5B1NHV1QAS"
          },
          "exhibit_id": {
            'DataType': "String",
            'StringValue': "fieragricola"
          },
          "action": {
            'DataType': "String",
            'StringValue': (isFavorite ? "set" : "delete")
          }
        },
        QueueUrl: DOMAINS[window.location.hostname].favorites.queueUrl
      };
      sqs.sendMessage(params, (err, data) => {
        if(err){
          console.log("Error", err);
          reject(err);
        }else{
          // console.log("Successfully added message", data.MessageId);
          resolve(isFavorite);
        }
      });
    });
  }

  const getFavorites = (accessToken) => {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
      axios.get(DOMAINS[window.location.hostname].favorites.apiUrl, {}).then(function(result){
        if(result.status === 200){
          let ret = {};
          ret.companies = Array.from(result.data.content, x => x.objectID)
          resolve(ret);
        }else{
          resolve(null);
        }
      }).catch(function (error) {
        console.log(error);
        resolve(null);
      });
    });
  }



  return {
    login,
    check,
    logout,
    setFavorite,
    getFavorites,
  }
}

const Backend = () => {
  if(DOMAINS[window.location.hostname].enable_login){
    const webAuth = new auth0.WebAuth({
      domain: DOMAINS[window.location.hostname].auth0.domain,
      clientID: DOMAINS[window.location.hostname].auth0.clientID,
      redirectUri: window.location.origin+"/authorization",
      responseType: DOMAINS[window.location.hostname].auth0.responseType,
      scope: DOMAINS[window.location.hostname].auth0.scope,
      audience: DOMAINS[window.location.hostname].auth0.audience,
      issuer: DOMAINS[window.location.hostname].auth0.issuer,
      leeway: 5*60
    });

    const client = BackendClient(webAuth)

    const login = client.login
    const check = client.check
    const logout = client.logout
    const setFavorite = client.setFavorite
    const getFavorites = client.getFavorites

    return {
      login,
      check,
      logout,
      setFavorite,
      getFavorites,
    }
  }else{
    return null;
  }
}
//
const auth = Backend()


export default auth
