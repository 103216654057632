import React from 'react'
import { useSelector } from 'react-redux'
// import styled from 'styled-components'
import { useParams } from 'react-router-dom';

import { getConfig, getLayout, getStatus } from './../../store/selectors/settings'
import { LAYOUT_DESKTOP } from './../../settings'

/*temp*/
import OldFilters from './../old/application/list/filter/filter'

// const Wrapper = styled.div`
//   height: 100%;
//   width: 100%;
// `;

function FiltersPanel({
  translations,
  areaService,
  setFiltersCallback,
  clearFiltersCallback,
  closeFiltersCallback,
  results,
  nCurrent,
  nTotal
}) {
  const config = useSelector(getConfig)
  const layout = useSelector(getLayout)
  const status = useSelector(getStatus)


  let { lang, area } = useParams()
  return (
    <>
      {( status.isFilterOpen || layout === LAYOUT_DESKTOP ?
         <OldFilters
            config={config}
            area={area}
            language={lang}
            translations={translations}
            areaService={areaService}
            setFiltersCallback={setFiltersCallback}
            clearFiltersCallback={clearFiltersCallback}
            closeFiltersCallback={closeFiltersCallback}
            results={results}
            nCurrent={nCurrent}
            nTotal={nTotal}
         />
      : "")}
    </>



  );
}

export default React.memo(FiltersPanel)
