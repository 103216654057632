import React, { Component } from 'react'

import SheetFullCover from "./../../../../../old/application/sheet/sheetFullCover";
import SheetContacts from "./../../../../../old/application/sheet/sheetContacts";
// import SheetInfoTable from "./../../../../../old/application/sheet/sheetInfoTable";
import SheetRelations from "./../../../../../old/application/sheet/sheetRelations";
import SheetTradeSectors from "./../../../../../old/application/sheet/sheetTradeSectors";
import SheetProductsList from "./../../../../../old/application/sheet/sheetProductsList";
import SheetEventsList from "./../../../../../old/application/sheet/sheetEventsList";
import SheetGallery from "./../../../../../old/application/sheet/sheetGallery";
//class
import './companySheet.css'
import './../../../../../old/application/sheet/sheet.sass'


export default class VeronafiereClassicSheet extends Component {
   constructor(props) {
      super(props);



      this.state = {
         config: props.config,
         translations: props.translations,
         layout: props.layout,
         language: props.language,
         element: props.data,
         contactTab: 0,
         countriesTab: "",
         hallTab: "",
         isAuthenticated: props.isAuthenticated,
         user: props.user
      };




   }


   componentWillReceiveProps(nextProps) {
         // console.log(nextProps);
      if(nextProps.isAuthenticated !== this.state.isAuthenticated){
         this.setState({
            isAuthenticated: nextProps.isAuthenticated,
            user: nextProps.user
         });
      }
   }


   changeTab(field, index){
      if(field === "contacts"){
         this.setState({contactTab: index});
      }
   }

   //funzione per aprire il pannello
   openPanel(e) {
     e.preventDefault();
     const panel = e.currentTarget;
     const hiddenpanel = panel.nextElementSibling;
     hiddenpanel.classList.toggle("visible");
   }

   openTabs(tab) {
     if (tab === "country" && this.state.countriesTab === "") {
        this.setState({countriesTab: "visible"})
      } else if(tab==="country" && this.state.countriesTab === "visible") {
        this.setState({countriesTab: ""})
     }

     if (tab === "hall" && this.state.hallTab === "") {
      this.setState({hallTab: "visible"})
   } else if(tab==="hall" && this.state.hallTab === "visible") {
       this.setState({hallTab: ""})
     }

   }

   closeContactform(){
      this.props.closeContactform();
   }

   render() {
      if(this.state.element){
         return (
            <div id="veronafiere-classic-sheet" className="sheet">

               <div>
                  <SheetFullCover
                     coverImage={this.state.element.cover}
                     withBlur={true}
                     logoImage={this.state.element.logo}
                     title1={this.state.element.name}
                  />

                  <SheetContacts
                     title={this.state.translations["sheet-company"]["Contacts"]}
                     address={[this.state.element.address, this.state.element.city + " " + this.state.element.zip + " " + this.state.element.province, this.state.element.country]}
                     geographicRegion={this.state.element.region}
                     geographicMap={this.state.element.geographicMap}
                     halls={this.state.element.hallsLong}
                     hallsMaps={this.state.element.hallsImages}
                     activity={this.state.element.activity}
                     emails={this.state.element.emails}
                     websites={this.state.element.websites}
                     phones={this.state.element.phones}
                     faxes={this.state.element.faxes}
                     social={this.state.element.social}
                     layout={this.state.layout}
                     config={this.state.config}
                     translations={this.state.translations}
                     tenant={this.state.element.tenant}
                  />



                  {/*<div className="direct">
                     <span className="btn btn-default btn-100" onClick={this.props.openContactform.bind(this)}>{this.state.translations["sheet-company"]["Contact directly"]}</span>
                  </div>*/}


                  { Object.keys(this.state.element.relations).length > 0 ?
                     <SheetRelations
                        relations={this.state.element.relations}
                        translations={this.state.translations}
                        language={this.state.language}
                     />
                  : "" }

                  { Object.keys(this.state.element.categories).length > 0 ?
                     <SheetTradeSectors
                        title={this.state.translations["sheet-company"]["Trade Sectors"]}
                        tradesectors={this.state.element.categories}
                        translations={this.state.translations}
                        language={this.state.language}
                     />

                  : "" }

                  { this.state.config.enable_products && Object.keys(this.state.element.products).length > 0 ?
                     <SheetProductsList
                        title={this.state.translations["sheet-company"]["Products"]}
                        products={this.state.element.products}
                        config={this.state.config}
                        translations={this.state.translations}
                        language={this.state.language}
                        layout={this.state.layout}
                     />

                  : "" }

                  { Object.keys(this.state.element.gallery).length > 0 ?
                     <SheetGallery
                        title={this.state.translations["sheet-company"]["Gallery"]}
                        images={this.state.element.gallery}
                        translations={this.state.translations}
                        language={this.state.language}
                        layout={this.state.layout}
                     />

                  : "" }

                  { this.state.config.enable_events && Object.keys(this.state.element.events).length > 0 ?
                     <SheetEventsList
                        title={this.state.translations["sheet-company"]["Our events"]}
                        events={this.state.element.events}
                        config={this.state.config}
                        translations={this.state.translations}
                        language={this.state.language}
                        layout={this.state.layout}
                     />

                  : "" }
               </div>

            </div>
        );
     }else{
        return (<div className=""></div>);
     }
  }
}
