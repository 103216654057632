import React, { Component, Fragment } from 'react';

import './sheetTradeSectors.sass';

export default class SheetTradeSectors extends Component {
   constructor(props){
      super(props);

      this.state = {
         title: props.title,
         tradesectors: props.tradesectors,
         translations: props.translations,
         language: props.language,
         activeTab: 0
      };
   }

   changeTab(evt, index){
      this.setState({
         activeTab: index
      });
   }

   render() {
      return (
         <section id="sheetTradeSectors">
            <div className="section-title">
               <div className="bounded">
                  {this.state.title}
               </div>
            </div>
            <div className="section-content">
               <div className="bounded">
                 {this.state.tradesectors.map((lvl0, index0) =>(
                   <React.Fragment key={index0}>
                     <div className="sector">
                       <div>{lvl0.name}</div>
                        {lvl0.children.map((lvl1, index1) =>(
                         <React.Fragment key={index0+"-"+index1}>
                           <span><b>{lvl1.name}</b></span>
                           {lvl1.children.map((lvl2, index2) =>(
                              <span key={index0+"-"+index1+"-"+index2} style={{
                                  display: "inline",
                                  fontWeight: 400,
                                  lineHeight: "8px",
                                  marginBottom: "40px"
                                }}>{lvl2.name + (index2 !== lvl1.children.length-1 ? ", " : ".")}</span>
                           ))}
                           <br/>
                           <br/>
                         </React.Fragment>
                        ))}
                     </div>
                   </React.Fragment>
                 ))}
                  {/*<div className="menu">
                     {this.state.tradesectors.map((lvl0, index0) =>(
                        <div key={index0} className={(this.state.activeTab === index0 ? "item active" : "item")} onClick={evt =>this.changeTab(evt, index0)}>{lvl0.name}</div>
                     ))}
                  </div>
                  {this.state.tradesectors.map((lvl0, index0) =>{
                     return (this.state.activeTab === index0 ?
                        <div key={index0} className="sector">
                           {lvl0.children.map((lvl1, index1) =>(
                            <React.Fragment key={index0+"-"+index1}>
                              <span>{lvl1.name}</span>
                              {lvl1.children.map((lvl2, index2) =>(
                                 <span key={index0+"-"+index1+"-"+index2} style={{
                                     display: "inline",
                                     fontWeight: 400,
                                     lineHeight: "8px",
                                     marginBottom: "40px"
                                   }}>{lvl2.name + (index2 !== lvl1.children.length-1 ? ", " : ".")}</span>
                              ))}
                              <br/>
                            </React.Fragment>
                           ))}
                        </div>
                     : "" );
                  })}*/}
               </div>
            </div>
         </section>
      );
   }
}
