import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useTheme } from './../../themes';

const Wrapper  = styled.div`
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border: ${props => props.borderSize + "px " + props.borderStyle + " " + props.variant.normal.borderColor};
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  background-color: ${props => props.variant.normal.background};
  color: ${props => props.variant.normal.textColor};
  cursor: pointer;

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};

  text-align: center;

  &:hover{
    border-color: ${props => props.variant.hover.borderColor};
    background-color: ${props => props.variant.hover.background};
    color: ${props => props.variant.hover.textColor};
  }
`;

const Button = ({variant = "default", children, onClick}) => {
  const theme = useTheme()
  return (
    <Wrapper
      onClick={onClick}
      padding={theme.button.padding}
      borderStyle={theme.button.borderStyle}
      borderSize={theme.button.borderSize}
      borderRadius={theme.button.borderRadius.value}
      variant={theme.button.variant[variant]}
      textFont={theme.button.textFont}
      textSize={theme.button.textSize}
      textWeight={theme.button.textWeight}
      textLineHeight={theme.button.textLineHeight}
      textLetterSpacing={theme.button.textLetterSpacing}
      textTransform={theme.button.textTransform}
    >
      {children}
    </Wrapper>
  )
}

Button.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func
}

export default React.memo(Button)
