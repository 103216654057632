import React, { Component } from 'react';
import { i18n } from 'react-i18next';
import ReactGA from 'react-ga';
import $ from 'jquery';

import FilterTag from "./filterTag";
import Calendar from 'react-calendar';

import './filterCalendar.css';

export default class FilterCalendar extends Component {
   constructor(props){
      super(props);

      this.areaService = props.service;
      this.field = props.field;
      this.depthCallback = props.depthCallback;
      this.facetCallback = props.facetCallback;
      this.state = {
         depth: props.depth,
         name: props.name,
         area: props.area,
         currentFilters: props.currentFilters,
         facets: [],
         filterSelection: null,
         selectedCalendar: null
      };

      this.getFacets(props.currentFilters);
      this.updateCalendarCount();

   }

   getFacets(filters){
      if(this.field !== ""){
         // console.log("getFacets field: " + this.field);
         this.areaService.getFacets(this.field, filters, "").then(ret  => {
            this.setState({
               "facets": ret
            });
         }).catch(err => console.log('There was an error:' + err));
      }
   }

   toggleFacet(key, val){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(key)){
         if(currentFilters[key].indexOf(val)>-1){
            currentFilters[key].splice([currentFilters[key].indexOf(val)], 1);
            if(currentFilters[key].length === 0){
               delete currentFilters[key];
            }
         }else{
            currentFilters[key] = []; //per rimuovere eventuali selezioni precedenti
            currentFilters[key].push(val);

            let datatext = new Date(val * 1000);
            datatext = datatext.getDate() + "/" + (parseInt(datatext.getMonth(), 10)+1) + "/" + datatext.getFullYear();
// console.log(datatext);
            ReactGA.event({
               category: `Search by`,
               action: key,
               label: datatext
            });
         }
      }else{
         currentFilters[key] = [];
         currentFilters[key].push(val);

         let datatext = new Date(val * 1000);
         datatext = datatext.getDate() + "/" + (parseInt(datatext.getMonth(), 10)+1) + "/" + datatext.getFullYear();
         // console.log(datatext);
         ReactGA.event({
            category: `Search by`,
            action: key,
            label: datatext
         });
      }

      this.setState({
         "currentFilters": currentFilters
      });
      this.facetCallback(currentFilters);
      // this.getFacets(currentFilters);
      this.updateCalendarCount();


   }

   getTags(){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(this.state.name)){
         let values = currentFilters[this.state.name];
         return <FilterTag
            area={this.state.area}
            field={this.state.name}
            value={values.join(", ")}
            extended={false}
            currentFilters={this.state.currentFilters}
            tagCallback={this.tagCallback.bind(this)}
         />;
      }else{
         return null;
      }
   }

   tagCallback(currentFilters){
      this.props.tagCallback(currentFilters);
   }

   // checkCurrent(key){
   //    let currentFilters = this.state.currentFilters;
   //    if(currentFilters.hasOwnProperty(key)){
   //       if(currentFilters[key].indexOf(val)>-1){
   //          return currentFilters[key].toString();
   //       }else{
   //          return false;
   //       }
   //    }else{
   //       return false;
   //    }
   //
   // }

   goDeeper(){
      var depth = this.state.depth;
      depth = depth + 1;
      this.setState({
         "depth": depth
      });
      this.depthCallback(depth, this.state.name);
   }

   onCalendarSelect(value){
      var date = new Date(value);
      // let filters = JSON.parse(sessionStorage.getItem("filter"));

      let timestamp = date.getTime() / 1000;

      $("[datetime]").parent().removeClass("active").removeClass("react-calendar__tile--active");
      if(this.state.selectedCalendar === timestamp){

         // delete filters["Days"];
         this.setState({
            selectedCalendar: null
         });
         // $(".react-calendar__tile--active").removeClass("react-calendar__tile--active").removeClass("react-calendar__tile--rangeBothEnds").removeClass("react-calendar__tile--rangeStart").removeClass("react-calendar__tile--rangeEnd");
      }else{
         // filters["Days"] = timestamp;
         this.setState({
            selectedCalendar: timestamp
         });
         // var date = new Date(timestamp * 1000);

         let datetime = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2);

         // console.log("active");
         // console.log("[datetime=\""+datetime+"T00:00:00.000\"]");
         $("[datetime=\""+datetime+"T00:00:00.000\"]").parent().addClass("active");
      }
      // sessionStorage.setItem("filter", JSON.stringify(filters));

      this.toggleFacet(this.state.name, timestamp);
      // this.setFilters();


      // console.log(JSON.stringify(filters));
   }

   onCalendarChange(value){
      this.updateCalendarCount();
   }



   updateCalendarCount(){
      this.areaService.getFacets("Days", this.state.currentFilters).then(ret  => {
         // console.log(ret);
         $("[datetime]").parent().find(".count").remove();
         ret.forEach(function(element) {
            var date = new Date(element.value * 1000);
            let count = element.count;

            let datetime = date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2);

            // console.log("[datetime=\""+datetime+"T00:00:00.000\"]");
            $("[datetime=\""+datetime+"T00:00:00.000\"]").parent().append("<div class=\"count\">"+count+"</div>");

         });

      }).catch(err => console.log('There was an error:' + err));

   }

   render() {
      return (
         <i18n ns="translations">
         {
            (t, { i18n }) => (
               <div id="filterCalendar">

                     { this.state.depth === 0 ?
                        <div className="item" onClick={this.goDeeper.bind(this)}>
                           <div className="item-container">
                              <div className="text">{t(this.state.area+".filter."+this.state.name)}</div>
                              <div className="icon"></div>
                           </div>
                           {this.getTags()}
                           {/* { this.state.depth == 0 ?

                           :
                              ""
                           } */}
                        </div>
                     :
                        <div className="items">
                           <Calendar
                              onClickDay={this.onCalendarSelect.bind(this)}
                              onActiveDateChange={this.onCalendarChange.bind(this)}
                              locale={i18n.language}
                              value={this.selectedCalendar}
                           />
                        </div>
                     }

               </div>
            )
         }
         </i18n>
      );
   }
}
