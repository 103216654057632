import React from 'react'
import { BrowserRouter, Route, Switch, Redirect, useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTheme } from './../../themes';

import { getStatus } from './../../store/selectors/settings'

import Authorization from './../../components/User/Authorization'

import MorePanel from './../../components/More/MorePanel'
import Home from './../../components/Home/Home'
import List from "./../../components/List/List"
import Page from "./../../components/Page/Page"

const Mover = styled.div`
  width: calc(100vw + ${ props => props.filterWidth.value+props.filterWidth.unit });
  height: 100%;
  position: absolute;
  top: 0px;
  left: ${ props => props.isOpen ? 0 : -props.filterWidth.value+props.filterWidth.unit };
  transition: all 0.25s ease-in-out;
`;

const ListWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(${ props => props.filterWidth.value+props.listWidth.unit } + ${ props => props.filterWidth.value+props.listWidth.unit });
  height: 100%;
`;

const PageWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: calc(${ props => props.filterWidth.value+props.listWidth.unit } + ${ props => props.filterWidth.value+props.listWidth.unit });
  width: calc(100% - (${ props => props.filterWidth.value+props.listWidth.unit } + ${ props => props.filterWidth.value+props.listWidth.unit }));
  height: 100%;
`;

function Redirector() {
  let { lang } = useParams()

  return (
    <Redirect to={"/" + lang + "/companies"} />
  );
}

function Desktop() {
  const theme = useTheme()
  const status = useSelector(getStatus)
  return (
    <Mover isOpen={status.isFilterOpen} filterWidth={theme.filters.width} listWidth={theme.list.width}>
      <BrowserRouter>
        <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
          <Switch>
            <Route exact path='/authorization' render={(route) => <Authorization/>} />
            <Route exact path='/' children={<Redirect to={"/it/companies"} />} />
            <Route exact path='/:lang' children={<Redirector/>} />
            <Route path='/:lang/:area/:slug?' children={<List />} />
          </Switch>
        </ListWrapper>
        <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
          <Switch>
            <Route exact path='/authorization' render={(route) => <></>} />
            <Route exact path='/:lang/:area' children={<Home />} />
            <Route exact path='/:lang/:area/:slug' children={<Page />} />
          </Switch>
        </PageWrapper>
        <Switch>
          <Route exact path='/authorization' render={(route) => <></>} />
          <Route exact path='/:lang/:area?/:slug?' children={<MorePanel />} />
        </Switch>
      </BrowserRouter>
    </Mover>
  );
}

export default React.memo(Desktop)
