import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import styled from 'styled-components'
import { getLayout } from './../../store/selectors/settings'

import { LAYOUT_XS, LAYOUT_SM } from './../../settings'

import Logo from './../../components/App/Logo'

import beatImage from './assets/followthebeat.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${ props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row" }
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
  background: #ffd300;
  padding: 30px;
`;
const Column = styled.div`
  flex-basis: 50%;
`;

const Text1 = styled.div`
  font-size: 20px;
  margin: 5px 0 40px 0;
  font-weight: 600;
  line-height: 1.1;
  color: #1A1A1A;
`;
const Text2 = styled.div`
  font-size: 46px;
  line-height: 40px;
  font-weight: 400;
  color: #1A1A1A;
`;
const Text2Bold = styled.span`
  font-weight: 900;
  font-size: 46px;
  line-height: 40px;
  color: #1A1A1A;
`;
const Text3 = styled.div`
  font-size: 32px;
  line-height: 40px;
  color: #1A1A1A;
  margin: 0 0 10px;
  font-weight: 400;
`;
const Text4 = styled.div`
  font-size: 20.5px;
  margin-bottom: 40px;
  font-weight: 400;
  font-weight: 600;
  color: #ffffff;
`;


function SamHomepage() {
  const layout = useSelector(getLayout)
  // const theme = useTheme()
  let { lang } = useParams()

  return (
    <Wrapper layout={layout}>
      <Column>
        <Logo
          variant="black"
          width={{value: "100", unit: "%"}}
        />
      {lang === "it" ?
        <>
          <Text1>
            31° SALONE INTERNAZIONALE MACCHINE PER COSTRUZIONI
          </Text1>
          <Text2>
            VERONA <Text2Bold>21.25 OTTOBRE 2020</Text2Bold>
          </Text2>
          <Text3>
            DA MERCOLEDÌ A DOMENICA
          </Text3>
          <Text4>
            IL CUORE DELLE MACCHINE PER COSTRUZIONI BATTE IN ITALIA
          </Text4>
        </>
      :
        <>
          <Text1>
            31<sup>ST</sup> INTERNATIONAL CONSTRUCTION EQUIPMENT TRADE FAIR
          </Text1>
          <Text2>
            VERONA <Text2Bold>21.25 OCTOBER 2020</Text2Bold>
          </Text2>
          <Text3>
            FROM WEDNESDAY TO SUNDAY
          </Text3>
          <Text4>
            THE HEART OF CONSTRUCTION EQUIPMENT PULSES IN ITALY
          </Text4>
        </>

      }
      </Column>
      <Column>
        <img width="100%" src={beatImage} alt="" />
      </Column>
    </Wrapper>
  )
}

export default React.memo(SamHomepage)
