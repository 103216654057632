import React, { Component } from 'react';
import $ from 'jquery';
import { LAYOUT_DESKTOP } from "./../../../../settings";
import TranslationService from "./../../utils/translationService";

import Search from "./../../../List/Search";

import ResultsList from "./resultsList/resultsList";
// import ResultsMap from "./resultsMap/resultsMap";
import FiltersPanel from "./../../../Filters/FiltersPanel";
import AreaTabs from "../desktop/areaTabs/areaTabs";

// import Calendar from 'react-calendar';

import CompaniesService from "./../../../old/entities/companies/companiesService";
import ProductsService from "./../../../old/entities/products/productsService";
import EventsService from "./../../../old/entities/events/eventsService";

//analytics
// import ReactGA from 'react-ga';

import './list.css';

const STATUS_ENDED = 0;
const STATUS_LOADING = 1;

const LAYOUT_LIST = 0;
const LAYOUT_MAP = 1;

export default class List extends Component {
   _isMounted = false;

   constructor(props){
      super(props);

      this.areaService = null;

      this.searchValue = "";
      this.page = 0;
      this.updatingStatus = STATUS_ENDED;

      if(!sessionStorage.getItem("filter")){
        sessionStorage.setItem("filter", "{}");
      }
      let filter = JSON.parse(sessionStorage.getItem("filter"));

      this.state = {
         config: props.config,
         translations: null,
         language: props.language,

         layout: props.layout,
         area: props.area,
         currentSlug: props.slug,

         resultsLayout: (sessionStorage.getItem("indexResultsLayout") && props.area === "events" ? sessionStorage.getItem("indexResultsLayout") :  LAYOUT_LIST),
         results: [],
         nCurrent:0,
         nTotal: 0,
         isLayoutSwitchable: (props.area === "events"),
         isLayoutCalendarable: (props.area === "events"),
         showFilters: false,
         activeFilters: (Object.keys(filter).length > 0),
         showCalendar: false,
         selectedCalendar: null
      };
      this.selectService(props.area);
      this.translationService = new TranslationService(this.state.config);

   }

   componentDidMount(){
      this._isMounted = true;
      this.searchValue = "";
      // sessionStorage.setItem("filter", "{}");

      this.areaService.initialize(this.state.config, this.state.language).then(ret  => {
         if(ret === true && this._isMounted) {
            this.areaService.getList(0, "", "").then(ret  => {
              // console.log("3");
               if(this._isMounted) {
                  this.setState({
                     nTotal: ret.nCurrent
                  });
                  this.getResults();
               }
            }).catch(err => console.log('There was an error:' + err));
         }
      }).catch(err => console.log('There was an error:' + err));

       this.translationService.getAllTranslations().then(ret  => {
          this.setState({
             translations: ret[this.state.language]
          });
       }).catch(err => console.log('There was an error:' + err));
   }

   componentDidUpdate(prevProps, prevState){
      if(this.props.slug !== this.state.currentSlug){
         this.setState({
            currentSlug: this.props.slug
         });
      }
      if(this.props.area !== this.state.area){
         this.resetResults();
         this.selectService(this.props.area);
         this.searchValue = "";
         sessionStorage.setItem("filter", "{}");
         this.setState({
            area: this.props.area,
            isLayoutSwitchable: (this.props.area === "events"),
            isLayoutCalendarable: (this.props.area === "events"),
            activeFilters: false,
            resultsLayout: (sessionStorage.getItem("indexResultsLayout") && this.props.area === "events" ? sessionStorage.getItem("indexResultsLayout") :  LAYOUT_LIST)
         }, ()=>{
            this.areaService.initialize(this.state.config, this.state.language).then(ret  => {
               if(ret === true && this._isMounted) {
                  this.areaService.getList(0, "", "").then(ret  => {
                     if(this._isMounted) {
                        this.setState({
                           nTotal: ret.nCurrent
                        });
                        this.getResults();
                     }
                  }).catch(err => console.log('There was an error:' + err));
               }
            }).catch(err => console.log('There was an error:' + err));
         });
      }
   }

   componentWillUnmount() {
      this._isMounted = false;
   }

   getResults(){
      if(this.areaService !== null && this.updatingStatus === STATUS_ENDED){
         this.updatingStatus = STATUS_LOADING;
         let filter = JSON.parse(sessionStorage.getItem("filter"));

         this.areaService.getList(this.page, this.searchValue, filter).then(ret  => {
            this.page++;
            if(ret.loadingState === 1){
               this.updatingStatus = STATUS_ENDED;
            }else{
               this.updatingStatus = STATUS_ENDED;
            }
            if (this._isMounted) {
               this.setState({
                  results: this.state.results.concat(ret.dataResult),
                  nCurrent: ret.nCurrent
               });

               if(this.state.resultsLayout === LAYOUT_MAP && this.updatingStatus === STATUS_LOADING){
                  this.getResults();
               }
            }

         }).catch(err => console.log('There was an error:' + err));
      }
   }

   loadMore(){
      if(this.updatingStatus === STATUS_ENDED){
         this.getResults();
      }
   }

   search(e){
      this.searchValue = e.target.value;
      this.resetResults();
      // let area = ""
      //
      // //analytics
      // if ( this.state.area == "companies") {
      //   area = "exhibitor";
      // } else if (this.state.area == "products") {
      //   area = "wine";
      // } else {
      //   area = "event"
      // }
      //
      // ReactGA.event({
      //    category: `Search`,
      //    action: area,
      //    label: e.target.value
      // });
   }

   resetResults(){
      $(".react-calendar__tile--active").removeClass("react-calendar__tile--active").removeClass("react-calendar__tile--rangeBothEnds").removeClass("react-calendar__tile--rangeStart").removeClass("react-calendar__tile--rangeEnd");
      this.page = 0;
      this.updatingPage = STATUS_ENDED;
      this.setState({
         // area: area,
         results: [],
         complete: false,
         // loadingState: STATUS_LOADING
      }, ()=>{
         this.getResults();
      });

   }

   selectService(area){
      switch (area) {
         case "companies":
            this.areaService = new CompaniesService();
            break;
         case "products":
            this.areaService = new ProductsService();
            break;
         case "events":
            this.areaService = new EventsService();
            break;
         default:
            break;
      }
   }

   switchLayout(){
      this.resetResults();
      if(this.state.resultsLayout === LAYOUT_LIST){
         this.setState({resultsLayout: LAYOUT_MAP});
         sessionStorage.setItem("indexResultsLayout", LAYOUT_MAP);
      }else{
         this.setState({resultsLayout: LAYOUT_LIST});
         sessionStorage.setItem("indexResultsLayout", LAYOUT_LIST);
      }
   }

   toggleFilters(){
      if(this.state.showFilters){
         this.setState({showFilters: false});
         if(this.state.layout === LAYOUT_DESKTOP){
            this.props.closeFilters();
         }
      }else{
         this.setState({showFilters: true});
         if(this.state.layout === LAYOUT_DESKTOP){
            this.props.openFilters();
         }
         //eventi analytics
         // ReactGA.event({
         //    category: 'Move on',
         //    action:  ''
         // });
      }


      //
      // if(this.state.showFilters){
      //    this.setState({showFilters: false});
      // }else{
      //    this.setState({showFilters: true});
      //    //eventi analytics
      //    // ReactGA.event({
      //    //    category: 'Move on',
      //    //    action:  ''
      //    // });
      // }
   }

   toggleCalendar(){
      if(this.state.showCalendar){
         this.setState({showCalendar: false});
      }else{
         this.setState({showCalendar: true});
      }
   }


   setFiltersCallback(){
      // this.setState({
      //    showFilters: false
      // });
      let filter = JSON.parse(sessionStorage.getItem("filter"));
      if(Object.keys(filter).length > 0){
         this.setState({
            activeFilters: true
         });
         if(this.state.layout === LAYOUT_DESKTOP){
            // this.props.openFilters();
         }
      }else{
         this.setState({
            activeFilters: false
         });
         if(this.state.layout === LAYOUT_DESKTOP){
            // this.props.closeFilters();
         }
      }
      this.resetResults();
   }
   clearFiltersCallback(){
      let filter = JSON.parse(sessionStorage.getItem("filter"));
      if(Object.keys(filter).length > 0){
         this.setState({
            activeFilters: true
         });
         if(this.state.layout === LAYOUT_DESKTOP){
            this.props.openFilters();
         }
      }else{
         this.setState({
            activeFilters: false
         });
         if(this.state.layout === LAYOUT_DESKTOP){
            // this.props.closeFilters();
         }
      }
      this.resetResults();
   }
   closeFiltersCallback(){
      this.setState({
         showFilters: false
      });
      if(this.state.layout === LAYOUT_DESKTOP){
         this.props.closeFilters();
      }
   }
   // //
   // // updateCallback(){
   // //    // this.setState({
   // //    //    showFilters: false
   // //    // });
   // //    let filter = JSON.parse(sessionStorage.getItem("filter"));
   // //    if(Object.keys(filter).length > 0){
   // //       this.setState({
   // //          activeFilters: true
   // //       });
   // //    }else{
   // //       this.setState({
   // //          activeFilters: false
   // //       });
   // //    }
   // //    this.resetResults();
   // // }

   render() {
     // console.log("a");
      if(this.state.translations){
        // console.log("b");
        return (
           <div id="list" className={ this.state.layout === LAYOUT_DESKTOP ? "isDesktop" : "isMobile" }>

               <FiltersPanel
                  translations={this.state.translations}
                  areaService={this.areaService}
                  setFiltersCallback={() => this.setFiltersCallback()}
                  clearFiltersCallback={() => this.clearFiltersCallback()}
                  closeFiltersCallback={() => this.closeFiltersCallback()}
                  results={this.state.results}
                  nCurrent={this.state.nCurrent}
                  nTotal={this.state.nTotal}
               />


              <div className="content">
                 <div className="header">
                    {/*<div className="main">
                       <div className="left">
                          <Link to={"/" + this.state.language + "/" + this.state.area + "/"} className="back"></Link>
                       </div>
                       <div className="center">
                          <Link to={"/"+this.state.language + "/"}>
                             <img className="logo" src={process.env.PUBLIC_URL + "/themes/" + this.state.config.tenant + "/logo.svg"} alt="logo" />
                          </Link>
                          <div className="title">{(this.state.data != null ? this.state.data.pagetitle : "")}</div>
                       </div>
                       <div className="right">
                          {this.state.layout !== LAYOUT_DESKTOP ?
                            <HomeButton lang={this.state.language}/>
                          : "" }
                          {this.state.isLayoutSwitchable ?
                             <div className={"layout-switch " + (this.state.resultsLayout === LAYOUT_MAP ? "active" : "") } onClick={() => this.switchLayout()}>
                                <span className="slider"></span>
                             </div>
                          : "" }
                       </div>
                    </div>*/}

                    {( this.state.layout === LAYOUT_DESKTOP && (this.state.config.enable_products_area || this.state.config.enable_events_area || this.state.config.enable_projects_area) ?
                       <AreaTabs
                          config={this.state.config}
                          translations={this.state.translations}
                          language={this.state.language}
                          area={this.state.area}
                       />
                    : "")}

                    <div className="search">
                       {/*<div className="toggle-filters"><ToggleFilters isActive={this.state.activeFilters}/></div>*/}

                       <Search placeholder={this.state.translations["UI"]["_search-"+this.state.area]} onChange={(e) => this.search(e)} value={this.state.searchValue} isFilterActive={this.state.activeFilters} />
                       {/*<input type="text" placeholder={this.state.translations["UI"]["_search-"+this.state.area]} onChange={(e) => this.search(e)} value={this.state.searchValue} />*/}
                    </div>
                 </div>
                 <div className="content">
                    {(this.state.resultsLayout === LAYOUT_LIST ?
                       <ResultsList
                          config={this.state.config}
                          translations={this.state.translations}
                          language={this.state.language}
                          results={this.state.results}
                          currentSlug={this.state.currentSlug}
                          loadmoreCallback={() => this.loadMore()}
                          updatingStatus={this.updatingStatus}
                       />
                    : "")}
                    {/*(this.state.resultsLayout === LAYOUT_MAP ?
                       <ResultsMap
                          config={this.state.config}
                          translations={this.state.translations}
                          language={this.state.language}
                          results={this.state.results}
                          currentSlug={this.state.currentSlug}
                       />
                    : "")*/}
                 </div>
              </div>
           </div>

        );
     }else{
        return(null);
     }

   }
}
