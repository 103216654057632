import produce from "immer"
import { handleActions } from 'redux-actions';
import { init, setLayout, setStatus } from '../actions/settings'

const INITIAL_REQUEST_STATE = {
  init: { loading: false, error: null}
}

const INITIAL_STATE = {
  config: null,
  layout: null,
  status: {
    isFilterOpen: false,
    isMoreOpen: false
  },
  requests: INITIAL_REQUEST_STATE,
}

const settingsReducer = handleActions({

  [init.TRIGGER]: (state) => produce(state, draft => {
    // draft.requests.init.loading = true
    // draft.requests.init.error = null
  }),

  [init.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.config = payload
    // draft.requests.init.loading = false
  }),

  [init.FAILURE]: (state, { payload }) => produce(state, draft => {
    // draft.requests.init.loading = false
    // draft.requests.init.error = payload
  }),

  [setLayout.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.layout = payload
  }),

  [setStatus.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.status = payload
  }),

}, INITIAL_STATE)

export default settingsReducer
