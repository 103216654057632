import produce from "immer"
import { handleActions } from 'redux-actions';
import { login, check, logout, setFavorite, getFavorites } from '../actions/auth'

const INITIAL_REQUEST_STATE = {
  login: { loading: false, error: null},
  check: { loading: false, error: null},
  logout: { loading: false, error: null},
  setFavorite: { loading: false, error: null},
  getFavorites: { loading: false, error: null},
}

const INITIAL_STATE = {
  user: null,
  favorites: null,
  requests: INITIAL_REQUEST_STATE,
}

const authReducer = handleActions({

  [login.TRIGGER]: (state) => produce(state, draft => {
    draft.requests.login.loading = true
    draft.requests.login.error = null
  }),

  [login.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.user = payload
    draft.requests.login.loading = false
  }),

  [login.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.login.loading = false
    draft.requests.login.error = payload
  }),

  [check.TRIGGER]: (state) => produce(state, draft => {
    // draft.requests.check.loading = true
    // draft.requests.check.error = null
  }),

  [check.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.user = payload
    // draft.requests.check.loading = false
  }),

  [check.FAILURE]: (state, { payload }) => produce(state, draft => {
    // draft.requests.check.loading = false
    // draft.requests.check.error = payload
  }),

  [logout.TRIGGER]: (state) => produce(state, draft => {
    // draft.requests.logout.loading = true
    // draft.requests.logout.error = null
  }),

  [logout.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.user = null
    // draft.requests.logout.loading = false
  }),

  [logout.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.logout.loading = false
    // draft.requests.logout.error = payload
  }),

  [setFavorite.TRIGGER]: (state) => produce(state, draft => {
    draft.requests.setFavorite.loading = true
    draft.requests.setFavorite.error = null
  }),

  [setFavorite.SUCCESS]: (state, { payload }) => produce(state, draft => {
    if(payload.status === true){
      draft.favorites[payload.area].push(payload.id)
    }else{
      draft.favorites[payload.area].splice(draft.favorites[payload.area].indexOf(payload.id), 1);
    }
    draft.requests.setFavorite.loading = false
  }),

  [setFavorite.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.setFavorite.loading = false
    draft.requests.setFavorite.error = payload
  }),

  [getFavorites.TRIGGER]: (state) => produce(state, draft => {
    draft.requests.getFavorites.loading = true
    draft.requests.getFavorites.error = null
  }),

  [getFavorites.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.favorites = payload
    draft.requests.getFavorites.loading = false
  }),

  [getFavorites.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.getFavorites.loading = false
    draft.requests.getFavorites.error = payload
  }),

}, INITIAL_STATE)

export default authReducer
