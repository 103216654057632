import React, { Component } from 'react';
import { i18n } from 'react-i18next';
import ReactGA from 'react-ga';

import FilterTag from "./filterTag";

import './filterRange.css';

export default class FilterRange extends Component {
   constructor(props){
      super(props);

      this.areaService = props.service;
      this.field = props.field;
      this.depthCallback = props.depthCallback;
      this.facetCallback = props.facetCallback;
      this.state = {
         depth: props.depth,
         name: props.name,
         area: props.area,
         currentFilters: props.currentFilters,
         facets: [],
         countedFacets: [],
         filterSelection: null,
         start: null,
         end: null
      };

      this.getAllFacets(props.currentFilters);

   }

   getFacets(filters){
      if(this.field !== ""){
         // console.log("getFacets field: " + this.field);
         this.areaService.getFacets(this.field, filters, "").then(ret  => {
            this.setState({
               "countedFacets": ret
            });
         }).catch(err => console.log('There was an error:' + err));
      }
   }

   getAllFacets(filters){
      if(this.field !== ""){
         // console.log("getFacets field: " + this.field);
         this.areaService.getFacets(this.field, "", "").then(ret  => {
            this.setState({
               "facets": ret
            });

            this.getFacets();
         }).catch(err => console.log('There was an error:' + err));
      }
   }

   toggleFacet(key, val, index){

      var start = this.state.start;
      var end = this.state.end;
      if(start == null && start == null){
         start = index;
         end = index;
      }else if(index < start){
         start = index;
      }else if(index > end){
         end = index;
      }else{
         start = null;
         end = null;
      }

      let currentFilters = this.state.currentFilters;
      //pulisco tutto e inserisco quelli dell'intervallo

      currentFilters[key] = [];
      if(start != null && end != null){
         for(var i = 0; i < this.state.facets.length; i++){
            if(start <= i && i <= end){
               currentFilters[key].push(this.state.facets[i].value);

               ReactGA.event({
                  category: `Search by`,
                  action: key,
                  label: start+1 + " - " + end+1
               });
            }
         }
      }




      // if(currentFilters.hasOwnProperty(key)){
      //    if(currentFilters[key].indexOf(val)>-1){
      //       currentFilters[key].splice([currentFilters[key].indexOf(val)], 1);
      //       if(currentFilters[key].length == 0){
      //          //c'era già, lo tolgo
      //          delete currentFilters[key];
      //       }
      //    }else{
      //       //c'era già, aggiungo una seconda voce
      //       currentFilters[key].push(val);
      //    }
      // }else{
      //    //aggiungo da 0
      //    currentFilters[key] = [];
      //    currentFilters[key].push(val);
      // }




      // console.log(key + " -> " + val + " -> " + index);

      this.setState({
         "currentFilters": currentFilters,
         "start": start,
         "end": end
      });
      this.facetCallback(currentFilters);
      this.getFacets(currentFilters);



   }

   getTags(){
      let currentFilters = this.state.currentFilters;
      if(currentFilters.hasOwnProperty(this.state.name)){
         let values = currentFilters[this.state.name];

         return <FilterTag
            area={this.state.area}
            field={this.state.name}
            value={values.join(", ")}
            extended={false}
            currentFilters={this.state.currentFilters}
            tagCallback={this.tagCallback.bind(this)}
         />;
      }else{
         return null;
      }
   }

   tagCallback(currentFilters){
      this.props.tagCallback(currentFilters);
   }


   goDeeper(){
      var depth = this.state.depth;
      depth = depth + 1;
      this.setState({
         "depth": depth
      });
      this.depthCallback(depth, this.state.name);
   }

   getCount(value){
      for(var i = 0; i < this.state.countedFacets.length; i++){
         if(this.state.countedFacets[i].value === value){
            return this.state.countedFacets[i].count;
         }
      }
      return 0;
   }

   render() {
      return (
         <i18n ns="translations">
         {
            (t, { i18n }) => (
               <div id="filterRange">

                     { this.state.depth === 0 ?
                        <div className="item" onClick={this.goDeeper.bind(this)}>
                           <div className="item-container">
                              <div className="text">{t(this.state.area+".filter."+this.state.name)}</div>
                              <div className="icon"></div>
                           </div>
                           {this.getTags()}
                           {/* { this.state.depth == 0 ?

                           :
                              ""
                           } */}
                        </div>
                     :
                        <div className="layout">
                           <div className="items-scroller">
                              <div className="items">{
                                 Object.keys(this.state.facets).map(key =>(
                                    <div className={"item item-leaf " + (this.state.start !== null && this.state.end !== null && this.state.start <= key && key <= this.state.end ? "active" : "") + " "+ (this.state.start !== null && this.state.start === key ? "active-start" : "") + " "+ (this.state.end !== null && this.state.end === key ? "active-end" : "")} key={key} onClick={this.toggleFacet.bind(this, this.state.name, this.state.facets[key].value, key)}>
                                       <div className="item-container">
                                          <div className="text">{this.state.facets[key].value}</div>
                                          <div className="checked"></div>
                                          <div className="counter">{this.getCount(this.state.facets[key].value)}</div>
                                       </div>
                                    </div>
                                 ))
                              }</div>
                           </div>
                        </div>
                     }

               </div>
            )
         }
         </i18n>
      );
   }
}
