import algoliasearch from 'algoliasearch'
import {DOMAINS} from "./../../../domains"


export default class TranslationService {
   constructor(config) {
      this.client = algoliasearch(DOMAINS[window.location.hostname].algolia.id, DOMAINS[window.location.hostname].algolia.key);
      this.index = this.client.initIndex(DOMAINS[window.location.hostname].algolia.index_interface);
   }

   getAllTranslations() {
      return new Promise((resolve, reject) => {
         this.index.search("", {
               hitsPerPage: "999",
               page: 0
            }).then(function(content){
               // if (err) throw err;
               let translations = {};
               for(let i=0; i < content.hits.length; i++){
                  translations[content.hits[i].objectID] = content.hits[i];
               }
               resolve(translations);
            });

      });
   }
}
