import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect} from 'react-router-dom'

import { getUser } from './../../store/selectors/auth'
import { check  } from '../../store/actions/auth'

const Authorization = () => {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  dispatch(check(), [dispatch]);

  if(user){
    return (
      <Redirect to={"/it/companies"} />
    )
  }else{
    return (
      <></>
    )
  }
}

export default React.memo(Authorization)
