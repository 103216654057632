import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import { useTheme } from './../../themes';

import { getConfig } from './../../store/selectors/settings'

import ToggleFavoriteButton from "./../User/ToggleFavoriteButton"


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border-bottom: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor};
  background-color: ${props => props.isActive ? props.backgroundActive : props.background};

  ${props => !props.isActive ? `
    &:hover{
      background-color: ${props => props.backgroundHover};
    }
  ` : ""};
`;
// const Left = styled.div`
//   flex-basis: 50px;
//   flex-grow: 0;
//   flex-shrink: 0;
//   text-align: center;
// `;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const PositionText = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};

  & em{
    font-style: normal;
    border-bottom: 2px solid ${props => props.textColor};
  }
`;
const TitleText = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
  padding: 3px 0px;

  & em{
    font-style: normal;
    border-bottom: 2px solid ${props => props.textColor};
  }
`;
const PlaceText = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};

  & em{
    font-style: normal;
    border-bottom: 2px solid ${props => props.textColor};
  }
`;



function Result({
    slug,
    position,
    title,
    place,
    isActive,
    isFavorite
  }) {
  const theme = useTheme()
  const config = useSelector(getConfig)
  let { lang, area } = useParams()

  return (
    <Link to={"/"+lang+"/"+area+"/"+slug}>
      <Wrapper
        isactive={isActive ? 1 : 0}
        background={theme.list.result.normal.background}
        backgroundHover={theme.list.result.hover.background}
        backgroundActive={theme.list.result.active.background}
        padding={theme.list.result.padding}
        borderColor={theme.list.result.borderColor}
        borderStyle={theme.list.result.borderStyle}
        borderSize={theme.list.result.borderSize}
      >

        {/*<Left></Left>*/}
        <Center>
          { config.enable_halls && position ?
            <PositionText
              textFont={theme.list.result.position.textFont}
              textSize={theme.list.result.position.textSize}
              textWeight={theme.list.result.position.textWeight}
              textLineHeight={theme.list.result.position.textLineHeight}
              textLetterSpacing={theme.list.result.position.textLetterSpacing}
              textTransform={theme.list.result.position.textTransform}
              textColor={theme.list.result.position.normal.textColor}
              textColorHover={theme.list.result.position.hover.textColorHover}
              textColorActive={theme.list.result.position.active.textColorActive}
            >{ReactHtmlParser(position)}</PositionText>
          : ""}
          <TitleText
            textFont={theme.list.result.title.textFont}
            textSize={theme.list.result.title.textSize}
            textWeight={theme.list.result.title.textWeight}
            textLineHeight={theme.list.result.title.textLineHeight}
            textLetterSpacing={theme.list.result.title.textLetterSpacing}
            textTransform={theme.list.result.title.textTransform}
            textColor={theme.list.result.title.normal.textColor}
            textColorHover={theme.list.result.title.hover.textColorHover}
            textColorActive={theme.list.result.title.active.textColorActive}
          >{ReactHtmlParser(title)}</TitleText>
        { place ?
            <PlaceText
              textFont={theme.list.result.place.textFont}
              textSize={theme.list.result.place.textSize}
              textWeight={theme.list.result.place.textWeight}
              textLineHeight={theme.list.result.place.textLineHeight}
              textLetterSpacing={theme.list.result.place.textLetterSpacing}
              textTransform={theme.list.result.place.textTransform}
              textColor={theme.list.result.place.normal.textColor}
              textColorHover={theme.list.result.place.hover.textColorHover}
              textColorActive={theme.list.result.place.active.textColorActive}
            >{ReactHtmlParser(place)}</PlaceText>
          : ""}
        </Center>
        { config.enable_login && config.enable_favorites ?
          <Right>
            <ToggleFavoriteButton slug={slug} />
          </Right>
        : "" }
      </Wrapper>
    </Link>

  );
}

Result.propTypes = {
  slug: PropTypes.string.isRequired,
  position: PropTypes.string,
  title: PropTypes.string.isRequired,
  place: PropTypes.string,
  isActive: PropTypes.bool,
  isFavorite: PropTypes.bool
}

export default React.memo(Result)
