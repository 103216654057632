import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components';

import LoginButton from "./../User/LoginButton"
import LogoutButton from "./../User/LogoutButton"

import { getUser } from './../../store/selectors/auth'
import {DOMAINS} from "./../../domains"
import { useTheme } from './../../themes';



const Wrapper  = styled.section`
  padding: 10px;
  background: ${props => props.background};
  position: relative;
  color: ${props => props.color};
  display: flex;
  align-items: center;
`;
const Avatar  = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-clip: content-box;

  cursor: pointer;
  border: 2px solid ${props => props.color}66;

  background-image: url(${
    props => props.pic ?
        props.pic :
        "/assets/interface/user-placeholder.jpg"
  });
`;
const TextWrapper  = styled.div`
  flex-grow: 1;
  margin: 0px 10px;
`;
const Name  = styled.div`
  display: block;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
`;
const Email  = styled.div`
  display: block;
  font-size: 11px;
  font-weight: 300;
  line-height: 13px;
`;
const ButtonWrapper = styled.div`
  flex-grow: 0;
`;

const UserBar = () => {
  const theme = useTheme()

  const user = useSelector(getUser)

  if(DOMAINS[window.location.hostname].enable_login){

    return (
      <Wrapper background={theme.morepanel.bar.background} color={theme.morepanel.bar.textColor}>
        {user ?
          <>
            <Avatar pic={user.avatar} color={theme.morepanel.bar.textColor}/>
            <TextWrapper>
              <Name>{user.firstname + " " + user.lastname}</Name>
              <Email>{user.email}prova@prova.it</Email>
            </TextWrapper>
            <ButtonWrapper>
              <LogoutButton variant={theme.morepanel.bar.buttonVariant} solid={false}>Logout</LogoutButton>
            </ButtonWrapper>
          </>
        :
          <>
            <Avatar color={theme.morepanel.bar.textColor}/>
            <TextWrapper></TextWrapper>
            <ButtonWrapper>
              <LoginButton variant={theme.morepanel.bar.buttonVariant}>Login</LoginButton>
            </ButtonWrapper>
          </>
        }
      </Wrapper>
    )
  }else{
    return (
      <></>
    )
  }
}

export default React.memo(UserBar)
