import React from 'react';
import { useTheme } from './../../themes'
import Badge from "./../App/Badge"

const UpdateNotification = () => {
  const theme = useTheme()
  return (
    <Badge variant={theme.badgeVariant}>
      Il catalogo è in continuo aggiornamento
    </Badge>
  )
}

export default React.memo(UpdateNotification)
