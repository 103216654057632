import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'

import AuthReducer from './reducers/AuthReducer'
import SettingsReducer from './reducers/SettingsReducer'
// import {i18nState} from "redux-i18n"

export default (history) => combineReducers({
    // router: connectRouter(history),
    auth: AuthReducer,
    settings: SettingsReducer,
    // i18nState
})
